import { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Typography, Button, CircularProgress, Backdrop, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import TitleSimple from "../components/TitleSimple";
import { ShiftUpdate } from "../models/Shift";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";

const SiteShifts: FunctionComponent = observer(() => {
  const [file, setFile] = useState<File | null>(null);
  const [shifts, setShifts] = useState<any[]>([]);
  const [loaded, setLoaded] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState<string>("Local site time");

  const site = useSite();
  const siteBionicsApplication = useSiteBionicsApplication();
  const account = site.account;
  const breadcrumbs = useSitePageBreadcrumbs("Shifts");

  useEffect(() => {
    account.loadRoleMapAsync();
  }, [account]);

  // Function to handle file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  // Function to parse the CSV file
  const handleSubmit = async () => {
    setLoaded(null);
    setShifts([]);
    setLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        const lines = text.split("\n");

        if (lines.length > 0) {
          // Parse the header row
          const header = lines[0].trim().split(",");
          const columnIndex = {
            firstName: header.indexOf("firstName"),
            lastName: header.indexOf("lastName"),
            employeeId: header.indexOf("employeeId"),
            startTime: header.indexOf("startTime"),
            endTime: header.indexOf("endTime"),
          };

          const parsedShifts: ShiftUpdate[] = [];

          for (let i = 1; i < lines.length; i++) {
            const line = lines[i].trim();
            if (!line) continue;
            const parts = line.split(",");

            parsedShifts.push(new ShiftUpdate(
              parts[columnIndex.firstName]?.trim(),
              parts[columnIndex.lastName]?.trim(),
              parts[columnIndex.employeeId]?.trim(),
              parts[columnIndex.startTime]?.trim(),
              parts[columnIndex.endTime]?.trim()
            ));
          }

          setShifts(parsedShifts);
          const timezoneParam = timezone === "UTC" ? "UTC" : undefined;
          siteBionicsApplication.service.updateShiftsAsync(site, parsedShifts, timezoneParam).then((count: number) => {
            setLoaded(count);
            setLoading(false);
          });
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      {loading &&
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open><CircularProgress color="inherit" /></Backdrop>
      }
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleSimple
        title="Shifts"
        sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}
      />

      <Box component="div" sx={{ paddingX: "10pt", overflow: "auto", maxWidth: "600px" }}>

        {/* File input for CSV upload */}
        <Typography sx={{ marginTop: "20pt" }}>
          Upload a CSV file containing shifts. The first row is the header row defining the column. Each row represents a shift an employee is working.<br />
          <br/>
          The columns are:
          <br />
          <table style={{ border: "1px solid white", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid white", padding: "8px" }}>Column Header</th>
                <th style={{ border: "1px solid white", padding: "8px" }}>Data Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid white", padding: "8px" }}>firstName</td>
                <td style={{ border: "1px solid white", padding: "8px" }}>The first name of the employee</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid white", padding: "8px" }}>lastName</td>
                <td style={{ border: "1px solid white", padding: "8px" }}>The last name of the employee</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid white", padding: "8px" }}>employeeId</td>
                <td style={{ border: "1px solid white", padding: "8px" }}>A unique identifier for the employee</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid white", padding: "8px" }}>startTime</td>
                <td style={{ border: "1px solid white", padding: "8px" }}>The start time of the shift, formatted as YYYY-MM-DDTHH:MM</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid white", padding: "8px" }}>endTime</td>
                <td style={{ border: "1px solid white", padding: "8px" }}>The end time of the shift, formatted as YYYY-MM-DDTHH:MM</td>
              </tr>
            </tbody>
          </table>
        </Typography><br />
        <input type="file" accept=".csv" onChange={handleFileUpload} />

        {/* Timezone dropdown */}
        <FormControl fullWidth sx={{ marginTop: "20pt" }}>
          <InputLabel id="timezone-label">Timezone of Dates</InputLabel>
          <Select
            labelId="timezone-label"
            value={timezone}
            label="Timezone of Dates"
            onChange={(e) => setTimezone(e.target.value)}
          >
            <MenuItem value="Local site time">Local site time</MenuItem>
            <MenuItem value="UTC">UTC</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "10pt" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>

        {/* Display the parsed shifts */}
        {shifts.length > 0 && (
          <Box component="div" sx={{ marginTop: "20pt" }}>
            <Typography variant="h6">Parsed Shifts from the file: {shifts.length}</Typography>
            {shifts.map((shift, index) => (
              <Box component="div" key={index} sx={{ marginBottom: "10pt" }}>
                <Typography>
                  {shift.firstName},{shift.lastName},{shift.employeeId},{shift.startTime},{shift.endTime}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {loaded != null &&
          <Box component="div" sx={{ marginTop: "20pt" }}>
            <Typography variant="h6">Processed on backend: {loaded}</Typography>
          </Box>
        }
      </Box>
    </>
  );
});

export default SiteShifts;
