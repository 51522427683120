
import { makeObservable, observable, action } from "mobx";
import { SiteBionicsApplication } from "./SiteBionicsApplication";

const _maxMRULength = 5;

export default class MRUData {

  @observable accounts: string[];
  @observable sites: { [key: string]: string[] }
  
  constructor(    
    accounts: string[],
    sites: { [key: string]: string[] }
  ) {
    this.accounts = accounts;
    this.sites = sites;

    makeObservable(this);
  }  

  @action
  addSite(accountId: string, siteId: string) {

    if (this.sites[accountId] && this.sites[accountId].length > 0 && this.sites[accountId][0] === siteId) {
        return;
    }

    // Get the array out of the site map
    if (!this.sites[accountId]) {
        this.sites[accountId] = [];
    }
    
    // If the site exists in the array, remove it
    const index = this.sites[accountId].indexOf(siteId);    
    if (index !== -1) {
        this.sites[accountId].splice(index, 1);
    }

    // Add the new item
    this.sites[accountId].unshift(siteId);

    // Delete off the end if list is too big
    if (this.sites[accountId].length > _maxMRULength) {
        this.sites[accountId].pop();
    }    

    SiteBionicsApplication.getInstance().service.upsertMRUUserData(this);
  }

  @action
  removeSite(accountId: string, siteId: string) {
    if (!this.sites[accountId]) return;

    const index = this.sites[accountId].indexOf(siteId);
    if (index === -1) return;

    this.sites[accountId].splice(index, 1);

    SiteBionicsApplication.getInstance().service.upsertMRUUserData(this);
  }

  @action
  addAccount(accountId: string) {

    if (this.accounts && this.accounts.length > 0 && this.accounts[0] === accountId) {
        return;
    }

    // If the accountId exists in the array, remove it
    const index = this.accounts.indexOf(accountId);    
    if (index !== -1) {
        this.accounts.splice(index, 1);
    }

    // Add the new item
    this.accounts.unshift(accountId);

    // Delete off the end if the list is too big
    if (this.accounts.length > _maxMRULength) {
        const accountId = this.accounts[this.accounts.length - 1];
        if (this.sites[accountId]) {
            delete this.sites[accountId];
        }
        this.accounts.pop();
    }    

    SiteBionicsApplication.getInstance().service.upsertMRUUserData(this);
  }

  @action
  removeAccount(accountId: string) {

    let updated: boolean = false;

    if (this.sites && this.sites.hasOwnProperty(accountId)) {
      delete this.sites[accountId];
      updated = true;
    }

    if (this.accounts) {
      const index = this.accounts.indexOf(accountId);
      if (index !== -1) {
        this.accounts.splice(index, 1);
        updated = true;
      }
    }

    if (updated) {    
      SiteBionicsApplication.getInstance().service.upsertMRUUserData(this);
    }
  }
}
