
import { FunctionComponent, useEffect, useState } from "react";
import { useSiteBionicsApplication } from "../../models/SiteBionicsApplication";
import { observer } from "mobx-react";
import ProvisionedHub from "../../models/ProvisionedHub";
import { IconButton, MenuItem, CircularProgress, Backdrop, Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Button, Link } from "@mui/material";
import TitleToolbar from "../../components/TitleToolbar";
import CachedAccountControl from "../CachedAccountControl";
import HubStatusDialog from "../../dialogs/HubStatusDialog";
import CachedSiteControl from "../CachedSiteControl";
import NameDialog from "../../dialogs/NameDialog";
import Confirm from "../../components/Confirm";
import ActionMenu from "../../components/ActionMenu";

const ProvisionedHubs : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  
  const [provisionedHubs, setProvisionedHubs] = useState<ProvisionedHub[] | null>(null);
  const [currentHub, setCurrentHub] = useState<ProvisionedHub | null>(null);

  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [addingNotes, setAddingNotes] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setLoading(true);
    siteBionicsApplication.service.fetchProvisionedHubListAsync().then((hubs) => {
      if (hubs === null) hubs = [];
      setProvisionedHubs(hubs);
      setLoading(false);
    })
  }, [siteBionicsApplication]); 

  const handleAddNotes = (name: string) => {
    setBusy(true);
    siteBionicsApplication.service.updateProvisionedHubNotes(currentHub!.hubId, name).then((status : number) => {
      setBusy(false);
      setAddingNotes(false);
      if (status === 200) {        
        currentHub!.setNotes(name);
      } else {
        siteBionicsApplication.setLastError('Unable to update provisioned hub notes', "error");
      }
    });
  };

  const handleReset = () => {
    setBusy(true);
    siteBionicsApplication.service.resetProvisionedHub(currentHub!.hubId).then((pairingCode: string | null) => {
      setBusy(false);
      setResetting(false);

      if (pairingCode != null) {
        currentHub!.reset(pairingCode);
        
      } else {
        siteBionicsApplication.setLastError('Unable to reset provisioned hub', "error");
      }      
    });
  }

  const handleDelete = () => {
    setBusy(true);

    siteBionicsApplication.service.deleteProvisionedHub(currentHub!.hubId).then((status: number) => {
      setBusy(false);
      setDeleting(false);
      if (status === 200) {              
        setProvisionedHubs(provisionedHubs!.filter(hub => hub !== currentHub));          
        
      } else {
        siteBionicsApplication.setLastError('Unable to delete provisioned hub', 'error');
      }
    });
  }
  const [hubStatusDialogOpen, setHubStatusDialogOpen] = useState(false);
  const [statusData, setStatusData] = useState<any>(null);
  const handleHubStatusClick = () => {
    setBusy(true);
    siteBionicsApplication.service.getHubStatus(currentHub!.pairedAccountId!, currentHub!.pairedSiteId!, currentHub!.hubId!, true).then((statusData) => {

      setStatusData(statusData);
      setHubStatusDialogOpen(true);
      setBusy(false);

    });
  };

  const handleHubStatusClose = () => {
    setHubStatusDialogOpen(false);
    setStatusData(null);
  }

  const telemetryDashboardUrl = (hubId: string) => {
    return `https://dataexplorer.azure.com/dashboards/8ed5be14-888e-4e20-9805-5d143dc768bf?p-_startTime=24hours&p-_endTime=now&p-_hubId=v-${hubId}`;
  }

  return (
    <>
        
        <TitleToolbar title="Provisioned Hubs" sx={{paddingLeft: "10pt", paddingRight: "10pt", marginTop: "10pt"}}>          
        </TitleToolbar>
     
        <Box component="div" sx={{height: "10pt"}}/>

        {loading ? "Loading..." :

        <TableContainer sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Hub ID</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Telemetry Dashboard</TableCell>
                <TableCell align="left">Pairing Code</TableCell>
                <TableCell align="left">Account</TableCell>
                <TableCell align="left">Site</TableCell>
                <TableCell align="left">Primary Thumprint</TableCell>
                <TableCell align="left">Secondary Thumbprint</TableCell>
                <TableCell align="left">Notes</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>              
              {provisionedHubs?.map((h) => (
                <TableRow key={h.hubId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                  
                  <TableCell component="th" scope="row"> {h.hubId} </TableCell>
                  <TableCell>
                    <ActionMenu>
                      <MenuItem onClick={() => {setCurrentHub(h); setAddingNotes(true);}}>Add Notes</MenuItem>
                      <MenuItem disabled={h.pairedSiteId != null} onClick={() => {setCurrentHub(h); setResetting(true);}}>Reset</MenuItem>
                      <MenuItem disabled={h.pairedSiteId != null || h.pairedAccountId != null} onClick={() => {setCurrentHub(h); setDeleting(true);}}>Delete</MenuItem>
                      <MenuItem disabled={h.pairedSiteId === null || h.pairedAccountId === null} onClick={() => { setCurrentHub(h); handleHubStatusClick(); }}>Status</MenuItem>
                    </ActionMenu>                    
                  </TableCell>
                  <TableCell component="th" scope="row"> <Link href={telemetryDashboardUrl(h.hubId)} target="_new">View Telemetry</Link> </TableCell>
                  <TableCell component="th" scope="row"> {h.pairingCode} </TableCell>
                  <TableCell component="th" scope="row"> 
                    {h.pairedAccountId && <CachedAccountControl accountId={h.pairedAccountId!} />}
                  </TableCell>
                  <TableCell component="th" scope="row"> 
                    {(h.pairedAccountId && h.pairedSiteId) && <CachedSiteControl accountId={h.pairedAccountId!} siteId={h.pairedSiteId!} />} 
                  </TableCell>
                  <TableCell component="th" scope="row"> {h.primaryThumbprint} </TableCell>
                  <TableCell component="th" scope="row"> {h.secondaryThumbprint} </TableCell>
                  <TableCell component="th" scope="row"> {h.notes} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

        {addingNotes && 
          <NameDialog
            title={"Hub Notes for " + currentHub!.hubId}
            nameLabel="Notes"
            saveLabel="Save"
            name={currentHub!.notes}
            onClose={() => setAddingNotes(false)}
            onSave={handleAddNotes}
          />
        }            

        {resetting &&
          <Confirm
            confirmationText={"Are you sure you want to reset this hub?"}
            confirmButtonLabel="Reset"
            onClose={() => setResetting(false)}
            onConfirm={handleReset}
          />
        }

        {deleting &&
          <Confirm
            confirmationText={"Are you sure you want to delete this hub?"}
            confirmButtonLabel="Delete"
            onClose={() => setDeleting(false)}
            onConfirm={handleDelete}
          />
        }

        {hubStatusDialogOpen && 
         <HubStatusDialog statusData={statusData} onClose={handleHubStatusClose}/>  
        }              
    </>
  )
})

export default ProvisionedHubs;