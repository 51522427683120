import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

interface ChartFiltersProps {
  displayHours: string;
  setDisplayHours: (value: string) => void;
  granularity: number;
  setGranularity: (value: number) => void;
  hasShiftData: boolean;
}

const ChartFilters: React.FC<ChartFiltersProps> = ({ displayHours, setDisplayHours, granularity, setGranularity, hasShiftData }) => {
  return (
    <Box component="div" style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginTop: 5, marginLeft: 10 }}>
      <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
        <InputLabel id="displayhours-label">Hours</InputLabel>
        <Select
          labelId="displayhours-label"
          value={displayHours}
          onChange={(e) => setDisplayHours(e.target.value)}
          label="Hours"
        >
          <MenuItem value="Open">Open Hours</MenuItem>
          <MenuItem value="Staffed" disabled={!hasShiftData}>Staffed Hours</MenuItem>
          <MenuItem value="All">All Hours</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
        <InputLabel id="granularity-label">Granularity</InputLabel>
        <Select
          labelId="granularity-label"
          value={granularity}
          onChange={(e) => setGranularity(e.target.value as number)}
          label="Granularity"
        >
          <MenuItem value={60}>By Hour</MenuItem>
          <MenuItem value={30}>By 30 Minutes</MenuItem>
          <MenuItem value={15}>By 15 Minutes</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ChartFilters;