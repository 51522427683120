import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"

import { Backdrop, CircularProgress, Box, Button, Checkbox, Link, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { NavLink } from "react-router-dom";
import { useAccount } from "./AccountPage";
import SiteDialog from "../dialogs/SiteDialog";
import Confirm from "../components/Confirm";
import Address from "../models/Address";
import Site from "../models/Site";
import {siteCache} from "../models/Cache";
import TitleToolbar from "../components/TitleToolbar";
import ReactGA from "react-ga4";
import CreateShadowSiteDialog from "../dialogs/CreateShadowSiteDialog";
import { on } from "events";
import Account from "../models/Account";
import ActionMenu from "../components/ActionMenu";

const SitesList : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const account = useAccount();

  const [busy, setBusy] = useState(false);

  const [editSite, setEditSite] = useState<Site | undefined>(undefined);

  const [deleteSite, setDeleteSite] = useState<Site | undefined>(undefined);

  const [shadowSite, setShadowSite] = useState<Site | undefined>(undefined);

  const [adding, setAdding] = useState(false);
  

  const [loading, setLoading] = useState(!account?.sites);

  useEffect(() => {
    if (account.sites === undefined) {
      setLoading(true);
      account.loadSitesAsync().then(() => {    
        setLoading(false);
      });
    }     

  }, [account, account.sites]);

  const handleDelete = () => {
    event("delete", "confirm", deleteSite);

    setBusy(true);

    const accountId = deleteSite!.account.accountId;
    const siteId = deleteSite!.siteId;

    siteBionicsApplication.service.deleteSiteAsync(accountId, siteId).then(() => {

      // Remove from the sites in the account
      let index = deleteSite!.account.sites?.findIndex(obj => siteId === obj.siteId);

      // If the site is in the site list, remove it
      if (index !== -1) {
        account.sites!.splice(index!, 1);
      }

      // Remove it from the MRU list
      if (siteBionicsApplication.mruData) {
        siteBionicsApplication.mruData.removeSite(accountId, siteId);
      }

      if (siteBionicsApplication.currentSite?.siteId === siteId) {
        siteBionicsApplication.setCurrentSite(undefined);
      }
      
      // Remove from the cache
      siteCache.removeItem(siteId);      

      setDeleteSite(undefined);
      setBusy(false);   
    });
  }

  const handleAdd = (name: string, timeZone: string, openHours: number[], dayOfWeekStart: number, address: Address) => {

    setBusy(true);
    siteBionicsApplication.service.createSiteAsync(account, name, timeZone!, openHours, dayOfWeekStart, address).then((newSite: Site) => {
      
      account.sites?.push(newSite);
      
      // Add to the cache
      siteCache.addItem(newSite);

      event("add", "confirm", newSite);

      setAdding(false);
      setBusy(false);
    });
  }

  const handleEdit = (name: string, timeZone: string, openHours: number[], dayOfWeekStart: number, address: Address) => {
    event("edit", "confirm", editSite);

    setBusy(true);
    siteBionicsApplication.service.updateSiteAsync(account.accountId, editSite!.siteId, name, timeZone, openHours, dayOfWeekStart, address).then(() => {
      editSite!.updateData(name, timeZone, openHours, dayOfWeekStart, address);
      setEditSite(undefined);
      setBusy(false);
    });
  }

  const handleCreateShadow = (destAccount: Account, destSiteName: string) => {

    setBusy(true);
    siteBionicsApplication.service.createShadowSiteAsync(shadowSite!, destAccount, destSiteName).then((newSite: Site) => {

      // Add to the cache
      siteCache.addItem(newSite);

      // NOTE: We need to add it to the account if in another account...
      if (account.accountId === destAccount.accountId) {
        account.sites?.push(newSite);
      }

      event("createShadow", "confirm", shadowSite);

      setShadowSite(undefined);
      setBusy(false);
    });
  }

  const event = (operation: 'add' | 'edit' | 'delete' | 'createShadow', action: 'click' | 'cancel' | 'confirm', site : Site | undefined) => {
    if (operation === 'add' && action !== 'confirm')
      ReactGA.event("site_" + operation + "_" + action)
    else
      ReactGA.event("site_" + operation + "_" + action, {
        accountId: site!.account.accountId,
        siteId: site!.siteId
      });
  };

  const breadcrumbs = [
    {name: "Accounts", link: "/accounts"},
    {name: `${account.accountName}`, link: `/accounts/${account.accountId}`}, 
    {name: "Sites", link: ""} ];
  
  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleToolbar title="Sites" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
        <ActionMenu ariaLabel="site operations">
          <MenuItem onClick={() => { setAdding(true); event("add", "click", undefined) }} disabled={!account!.hasAccountCapability("ManageSites")}>Add Site...</MenuItem>
        </ActionMenu>        
      </TitleToolbar> 

      <Box component="div" sx={{height: 20}}/>      
                             
      {loading && <Typography>Loading...</Typography>}

      {account?.sites && account.sites.length === 0 &&
        <Typography>No available sites</Typography>
      }

      {account?.sites && account.sites.length > 0 &&

        <TableContainer component={Paper} sx={{}} >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Site Name</TableCell>
                <TableCell align="left">Site Id</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {account?.sites?.map((s) => {              
                return (
                <TableRow key={s.siteId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                
                  <TableCell><NavLink style={{ color: 'white' }} to={`/accounts/${account!.accountId}/sites/${s.siteId}`}>{s.siteName}</NavLink></TableCell>
                  <TableCell align="left">{s.siteId}</TableCell>
                  <TableCell align="left">
                    <ActionMenu>
                        <MenuItem onClick={() => { setEditSite(s); event("edit", "click", s) }} disabled={!(account!.hasAccountCapability("ManageSites") || (s.hasSiteCapability("UpdateSite")))}>Edit Site...</MenuItem>
                        <MenuItem onClick={() => { setDeleteSite(s); event("delete", "click", s) }} disabled={!(account!.hasAccountCapability("ManageSites"))}>Delete Site...</MenuItem>
                        {siteBionicsApplication.isSystemAdministrator &&
                          <MenuItem onClick={() => { setShadowSite(s); event("createShadow", "click", s) }} disabled={!account!.hasAccountCapability("ManageSites")}>Create Shadow Site...</MenuItem>
                        }
                      </ActionMenu>
                  </TableCell>
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
      }
      
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>
      {adding &&
        <SiteDialog
          title="Add Site"
          saveLabel="Create Site"
          timeZone="America/Los_Angeles"
          openHours={Array(14).fill(0)}
          dayOfWeekStart={0}
          onClose={() => {setAdding(false); event("add", "cancel", undefined)}}
          onSave={handleAdd}
        />
      }
      {editSite &&
        <SiteDialog
          title="Edit Site"
          saveLabel="Save Site"
          name={editSite.siteName}
          address={editSite.address}
          timeZone={editSite.timeZone!}
          openHours={editSite.openHours}
          dayOfWeekStart={editSite.dayOfWeekStart}
          onClose={() => { event("edit", "cancel", editSite); setEditSite(undefined);  }}
          onSave={handleEdit}
        />
      }
      {deleteSite &&
        <Confirm
          title="Delete Site"
          confirmationText={`Are you sure you want to delete ${deleteSite.siteName}?`}
          confirmButtonLabel="Delete"
          onClose={() => { event("delete", "cancel", deleteSite); setDeleteSite(undefined); }}
          onConfirm={handleDelete}
        />
      }
      {shadowSite &&
        <CreateShadowSiteDialog
          site={shadowSite}
          onCancel={() => {setShadowSite(undefined); event("createShadow", "cancel", shadowSite)}}
          onCreate={handleCreateShadow}
        />
      }
    </>
  )
})

export default SitesList;