import { action, computed, observable } from 'mobx';
import Pose from './Pose';
import { Vector2 } from '@babylonjs/core/Maths';

export enum CameraCalibrationModel {
    PINHOLE,
    FISHEYE4,
    BROWN
  }

export default class CameraCalibration {
    @observable calibrationModel: CameraCalibrationModel = CameraCalibrationModel.PINHOLE;
    @observable width: number = 0; // image width in pixels
    @observable height: number = 0; // image height in pixels
    @observable fx: number = 0; // focal length x in pixels
    @observable fy: number = 0; // focal length y in pixels
    @observable cx: number = 0; // principal point x
    @observable cy: number = 0; // principal point y
    @observable diagFovDeg: number = 0; // diagonal field of view in degrees
    @observable horizFovDeg: number = 0; // horizontal field of view in degrees
    @observable vertFovDeg: number = 0; // diagonal field of view in degrees
    @observable distCoef1: number = 0; // distortion coefficients
    @observable distCoef2: number = 0; // distortion coefficients
    @observable distCoef3:  number = 0; // distortion coefficients
    @observable distCoef4:  number = 0; // distortion coefficients
    @observable distCoef5:  number = 0; // distortion coefficients
    @observable pose?: Pose; // camera pose (extrinsics)

    @computed get diagFovRad() : number { return this.diagFovDeg * Math.PI / 180;}
    @computed get horizFovRad() : number { return this.horizFovDeg * Math.PI / 180;}
    @computed get vertFovRad() : number { return this.vertFovDeg * Math.PI / 180;}
    
    constructor( calibrationModel: CameraCalibrationModel = CameraCalibrationModel.PINHOLE,
            width: number = 0, height: number = 0, fx: number = 0, fy: number = 0, cx: number = 0, cy: number = 0,
            distCoef1: number = 0, distCoef2: number = 0, distCoef3: number = 0, distCoef4: number = 0, distCoef5: number = 0,
            pose: Pose | undefined = undefined) {
        
        this.calibrationModel = calibrationModel;
        this.width = width;
        this.height = height;
        this.fx = fx;
        this.fy = fy;
        this.cx = cx;
        this.cy = cy;
        this.distCoef1 = distCoef1;
        this.distCoef2 = distCoef2;
        this.distCoef3 = distCoef3;
        this.distCoef4 = distCoef4;
        this.distCoef5 = distCoef5;
        this.pose = pose;
        this.updateFov();
    }

    static fromJson(json: any): CameraCalibration {
        if (!json) return new CameraCalibration();
        const model = json.cameraCalibrationModel ?? CameraCalibrationModel.PINHOLE;
        const width = json.width;
        const height = json.height;
        const fx = json.fx;
        const fy = json.fy;
        const cx = json.cx;
        const cy = json.cy;
        const pose = new Pose(json.pose);
        let distCoef1 = 0;
        let distCoef2 = 0;
        let distCoef3 = 0;
        let distCoef4 = 0;
        let distCoef5 = 0;

        if (json.distortion) {
            if (json.distortion.length > 1) distCoef1 = json.distortion[0]
            if (json.distortion.length > 2) distCoef2 = json.distortion[1]
            if (json.distortion.length > 3) distCoef3 = json.distortion[2]
            if (json.distortion.length > 4) distCoef4 = json.distortion[3]
            if (json.distortion.length > 5) distCoef5 = json.distortion[4]
        }

        return new CameraCalibration(model, width, height, fx, fy, cx, cy, distCoef1, distCoef2, distCoef3, distCoef4, distCoef5, pose);
    }

    toJson(): any {
        return {
            cameraCalibrationModel: this.calibrationModel,
            width: this.width,
            height: this.height,
            fx: this.fx,
            fy: this.fy,
            cx: this.cx,
            cy: this.cy,
            diagFovDeg: this.diagFovDeg,
            horizFovDeg: this.horizFovDeg,
            vertFovDeg: this.vertFovDeg,
            distortion: [this.distCoef1, this.distCoef2, this.distCoef3, this.distCoef4, this.distCoef5],
            pose: this.pose ? this.pose.toJson() : undefined
        };
    }
    private updateFov() {
        this.diagFovDeg = 2 * Math.atan(Math.sqrt(this.width * this.width + this.height * this.height) / (2 * this.fx)) * 180 / Math.PI;
        this.horizFovDeg = 2 * Math.atan(this.width / (2 * this.fx)) * 180 / Math.PI;
        this.vertFovDeg = 2 * Math.atan(this.height / (2 * this.fy)) * 180 / Math.PI;
    }

    @action
    public setHorizFovDeg(horizFovDeg: number) {
        this.fx = this.width / (2 * Math.tan(horizFovDeg * Math.PI / 360));
        this.fy = this.height / (2 * Math.tan(this.vertFovDeg * Math.PI / 360));
        this.updateFov();
    }

    @action
    public setVertFovDeg(vertFovDeg: number) {
        this.fx = this.width / (2 * Math.tan(this.horizFovDeg * Math.PI / 360));
        this.fy = this.height / (2 * Math.tan(vertFovDeg * Math.PI / 360));
        this.updateFov();
    }

    @action
    public setDiagFovDeg(diagFovDeg: number) {
        this.fx = this.width / (2 * Math.tan(diagFovDeg * Math.PI / 360));
        this.fy = this.height / (2 * Math.tan(diagFovDeg * Math.PI / 360));
        this.updateFov();
    }
    
    public equals(other: CameraCalibration): boolean {
        return this.calibrationModel === other.calibrationModel &&
               this.width === other.width &&
               this.height === other.height &&
               this.fx === other.fx &&
               this.fy === other.fy &&
               this.cx === other.cx &&
               this.cy === other.cy &&
               this.diagFovDeg === other.diagFovDeg &&
               this.horizFovDeg === other.horizFovDeg &&
               this.vertFovDeg === other.vertFovDeg &&
               this.distCoef1 === other.distCoef1 &&
               this.distCoef2 === other.distCoef2 &&
               this.distCoef3 === other.distCoef3 &&
               this.distCoef4 === other.distCoef4 &&
               this.distCoef5 === other.distCoef5 &&
               (this.pose ? this.pose.equals(other.pose) : other.pose === undefined);
    }

}

export class CameraSubView {
    @observable width: number; // image width in pixels
    @observable height: number; // image height in pixels
    @observable diagFovDeg: number = 0; // diagonal field of view in degrees
    @observable horizFovDeg: number = 0; // horizontal field of view in degrees
    @observable vertFovDeg: number = 0; // diagonal field of view in degrees
    @computed get diagFovRad() : number { return this.diagFovDeg * Math.PI / 180;}
    @computed get horizFovRad() : number { return this.horizFovDeg * Math.PI / 180;}
    @computed get vertFovRad() : number { return this.vertFovDeg * Math.PI / 180;}
    
    constructor(json: any) {
        this.width = json.Width;
        this.height = json.Height;
        this.diagFovDeg = json.DiagFovDeg;
        this.horizFovDeg = json.HorizFovDeg;
        this.vertFovDeg = json.VertFovDeg;
    }

    @action
    public setHorizFovDeg(horizFovDeg: number) {
        this.horizFovDeg = horizFovDeg;
        this.vertFovDeg = 2 * Math.atan(this.height / (2 * (this.width / (2 * Math.tan(horizFovDeg * Math.PI / 360))))) * 180 / Math.PI;
        this.diagFovDeg = 2 * Math.atan(Math.sqrt(this.width * this.width + this.height * this.height) / (2 * (this.width / (2 * Math.tan(horizFovDeg * Math.PI / 360))))) * 180 / Math.PI;
    }

    @action
    public setVertFovDeg(vertFovDeg: number) {
        this.vertFovDeg = vertFovDeg;
        this.horizFovDeg = 2 * Math.atan(this.width / (2 * (this.height / (2 * Math.tan(vertFovDeg * Math.PI / 360))))) * 180 / Math.PI;
        this.diagFovDeg = 2 * Math.atan(Math.sqrt(this.width * this.width + this.height * this.height) / (2 * (this.height / (2 * Math.tan(vertFovDeg * Math.PI / 360))))) * 180 / Math.PI;
    }

    @action
    public setDiagFovDeg(diagFovDeg: number) {
        this.diagFovDeg = diagFovDeg;
        this.horizFovDeg = 2 * Math.atan(this.width / (2 * Math.tan(diagFovDeg * Math.PI / 360))) * 180 / Math.PI;
        this.vertFovDeg = 2 * Math.atan(this.height / (2 * Math.tan(diagFovDeg * Math.PI / 360))) * 180 / Math.PI;
    }

    public equals(other: CameraSubView): boolean {
        return this.width === other.width &&
               this.height === other.height &&
               this.diagFovDeg === other.diagFovDeg &&
               this.horizFovDeg === other.horizFovDeg &&
               this.vertFovDeg === other.vertFovDeg;
    }
}