import { FunctionComponent, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import HeaderBar from "../components/HeaderBar";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { Alert, Drawer } from "@mui/material";
import { observer } from "mobx-react";
import ReactGA from 'react-ga4';
import jsSHA from 'js-sha256';


interface ConsoleProps {
    title?: string;
    modal?: boolean;
};

const Console: FunctionComponent<ConsoleProps> = observer(({ title, modal=false }) => {
    const siteBionicsApplication = useSiteBionicsApplication();

    const location = useLocation();
    useEffect(() => {

        if (siteBionicsApplication.me) {
            const userId = siteBionicsApplication.me.id;
            const anonUserId = jsSHA.sha256(userId).toUpperCase();
            ReactGA.gtag('set', { anonUserId });
        }
        ReactGA.send({
            hitType: "pageview",
            page: location
        });
        
    }, [location])
    
    return (
        <Box component="div" display="flex" flexDirection="column" height="100vh">
            <HeaderBar modal={modal}/>
            <Box component="div" flexGrow="1" overflow="auto" display="flex" flexDirection="column" >
                <Outlet/>
            </Box>
            {siteBionicsApplication.lastError !== undefined && siteBionicsApplication.lastErrorSeverity!=undefined &&
                <div>
                    <Drawer open={true} onClose={() => siteBionicsApplication.setLastError(undefined, undefined)} anchor={"bottom"}>
                        <Alert severity={siteBionicsApplication.lastErrorSeverity as any}>{siteBionicsApplication.lastError}</Alert>
                    </Drawer>
                </div>    
            }
            
        </Box>
    );
})

export default Console;