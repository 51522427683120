import React, { useState, useEffect, useRef } from "react";
import * as BABYLON from "@babylonjs/core";
import Hls from "hls.js";
import { Box, Slider, Drawer } from "@mui/material";
import { Vector2, Vector3, Matrix, Quaternion } from "@babylonjs/core";
import { transformFisheyeToVirtualView } from "../util/MathUtil";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useSite } from "../pages/SitePage";
import { ILoadedModel } from "react-babylonjs";


type SphericalVideoViewerProps = {
  src: string;
  initialFisheyeFOV?: number; // in degrees
  initialDistortion?: [number, number, number, number];
  // New: a single highlight point in fisheye UV coordinates.
  highlightFisheyePoints?: Vector2[];
};

const SphericalVideoViewer: React.FC<SphericalVideoViewerProps> = ({
  src,
  initialFisheyeFOV: initialFisheyeFOVDeg,
  initialDistortion,
  highlightFisheyePoints: highlightFisheyePoints, // if provided, this is in fisheye UV space (0–1)
}) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const site = useSite();
  const siteNavigator = site.siteNavigator;
    
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayRef = useRef<HTMLCanvasElement>(null);
  const shaderMaterialRef = useRef<BABYLON.ShaderMaterial | null>(null);
  const modelCameraRef = useRef<BABYLON.FreeCamera | null>(null);
  const modelSceneRef = useRef<BABYLON.Scene | null>(null);

  // Output view state (all angles in radians)
  const [rollRad, setRollRad] = useState(0);
  const [pitchRad, setPitchRad] = useState(0);
  // Virtual (rectilinear) vertical FOV (in radians) for the output view.
  const [virtualFOVRad, setVirtualFOVRad] = useState(1.0);
  // Fisheye FOV: provided in degrees (or default 132°) then converted to radians.
  const defaultFisheyeFOVRadians =
    initialFisheyeFOVDeg !== undefined ? (initialFisheyeFOVDeg * Math.PI) / 180 : (132 * Math.PI) / 180;
  const [fisheyeFOVRad, setFisheyeFOVRad] = useState(defaultFisheyeFOVRadians);
  // Distortion coefficients
  const defaultDistortion: [number, number, number, number] = initialDistortion ?? [0, 0, 0, 0];
  const [distortion, setDistortion] = useState<[number, number, number, number]>(defaultDistortion);
  // Output canvas aspect ratio.
  const [aspect, setAspect] = useState(1);
  // Control for persistent drawer with controls.
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modelUrlRoot, setModelUrlRoot] = useState<any>(null);
  const [modelUrlFilename, setModelUrlFilename] = useState<any>(null);
  const [loadedModel, setLoadedModel] = useState<ILoadedModel | undefined>(undefined);
  const [version, setVersion] = useState(0);


  // Compute output view FOV values (in degrees)
  const verticalVirtualFOVDeg = (virtualFOVRad * 180) / Math.PI;
  const horizontalVirtualFOVDeg = (2 * Math.atan(Math.tan(virtualFOVRad / 2) * aspect) * 180) / Math.PI;
  const diagonalVirtualFOVDeg = (2 * Math.atan(Math.tan(virtualFOVRad / 2) * Math.sqrt(aspect * aspect + 1)) * 180) / Math.PI;
  const fisheyeDeg = (fisheyeFOVRad * 180) / Math.PI;

  // model loading
  useEffect(() => {
    setModelUrlFilename(null);
    setModelUrlRoot(null);
    setVersion(version + 1); // this version helps us work around the fact that loading a new mesh doesn't clear out the old one
    if (siteNavigator.currentScan) {
      const resourceName = `SiteScans/${siteNavigator.currentScan.scanId}/Lidar.glb`;
      siteBionicsApplication.service.fetchSiteResourceSasUri(site.account.accountId, site.siteId, resourceName).then((sasUri) => {
            const root = 'https://sitebionicsstorage.blob.core.windows.net/siteblobs/';
            const filename = sasUri.replace(root, '');
            setModelUrlRoot(root);
            setModelUrlFilename(filename);
          });
    }
  }, [siteNavigator.currentScan]);

  useEffect(() => {
    if (modelSceneRef.current && modelUrlRoot && modelUrlFilename) {
      BABYLON.SceneLoader.ImportMesh(
        "",
        modelUrlRoot,
        modelUrlFilename,
        modelSceneRef.current,
        (meshes) => {},
        null,
        (scene, message, exception) => {
          console.error("Error loading model:", message, exception);
        }
      );
    }
  }, [modelUrlFilename, modelUrlRoot, modelSceneRef.current]);

  // Toggle the drawer with Ctrl+P.
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key.toLowerCase() === "p") {
        e.preventDefault();
        setDrawerOpen((prev) => !prev);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // --- Babylon Scene Setup ---
  useEffect(() => {
    if (!canvasRef.current) return;
    
    const engine = new BABYLON.Engine(canvasRef.current, true);
    
    const modelScene = new BABYLON.Scene(engine);
    modelSceneRef.current = modelScene;
    modelScene.autoClear = false;
    //modelScene.clearColor = new BABYLON.Color4(0, 0, 1, 1);
    modelScene.createDefaultLight(true);

    const modelCamera = new BABYLON.FreeCamera("modelCamera", new BABYLON.Vector3(0, 3, 0), modelScene);
    //modelCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
    modelCamera.position = new BABYLON.Vector3(0, 2, 0);
    modelCameraRef.current = modelCamera;

    // const ground = BABYLON.MeshBuilder.CreateGround("ground", { width: 10, height: 10, subdivisions:100 }, modelScene);
    // const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", modelScene);
    // groundMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
    // ground.material = groundMaterial;
    // groundMaterial.alpha = 0.25;
    // groundMaterial.wireframe = false;
    

    // const box = BABYLON.MeshBuilder.CreateBox("box", { size: 0.328}, modelScene);
    // const boxMaterial = new BABYLON.StandardMaterial("boxMaterial", modelScene);
    // box.position = new BABYLON.Vector3(0, 0.164, -1);
    // boxMaterial.diffuseColor = new BABYLON.Color3(1, 0, 0);
    // boxMaterial.alpha = 0.95;
    // box.material = boxMaterial;

    // const box2 = BABYLON.MeshBuilder.CreateBox("box", { size: 0.328 }, modelScene);
    // box2.position = new BABYLON.Vector3(0, 0.164, 0);
    // const box2Material = new BABYLON.StandardMaterial("boxMaterial", modelScene);
    // box2Material.diffuseColor = new BABYLON.Color3(0, 1, 0); 
    // box2Material.alpha = 0.95;
    // box2.material = box2Material;

    // const box3 = BABYLON.MeshBuilder.CreateBox("box", { size: 0.328 }, modelScene);
    // box3.position = new BABYLON.Vector3(0, 0.164, 1);
    // const box3Material = new BABYLON.StandardMaterial("boxMaterial", modelScene);
    // box3Material.diffuseColor = new BABYLON.Color3(0, 0, 1);
    // box3Material.alpha = 0.95;
    // box3.material = box3Material;


    const setPerspective = () => {
      const canvasRect = engine.getRenderingCanvasClientRect();
      if (!canvasRect) return;
      const newAspect = canvasRect.width / canvasRect.height;
      modelCamera.fov = virtualFOVRad;
      modelCamera.rotationQuaternion = Quaternion.FromEulerAngles((Math.PI/2)-pitchRad, -rollRad, 0);
    };
    setPerspective();
    window.addEventListener("resize", setPerspective);
    
    const scene = new BABYLON.Scene(engine);
    const camera = new BABYLON.FreeCamera("camera", new BABYLON.Vector3(0, 0, -1), scene);
    camera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
    const setOrtho = () => {
      const canvasRect = engine.getRenderingCanvasClientRect();
      if (!canvasRect) return;
      const newAspect = canvasRect.width / canvasRect.height;
      setAspect(newAspect);
      camera.orthoLeft = -newAspect;
      camera.orthoRight = newAspect;
      camera.orthoBottom = -1;
      camera.orthoTop = 1;
      camera.position.z = -1;
      if (shaderMaterialRef.current) {
        shaderMaterialRef.current.setFloat("u_aspect", newAspect);
      }
      if (overlayRef.current) {
        overlayRef.current.width = canvasRect.width;
        overlayRef.current.height = canvasRect.height;
      }
    };
    setOrtho();
    window.addEventListener("resize", setOrtho);
    
    const plane = BABYLON.MeshBuilder.CreatePlane("plane", { size: 2 }, scene);
    const video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.loop = true;
    video.muted = true;
    video.playsInline = true;
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => video.play());
    } else {
      video.src = src;
      video.addEventListener("loadedmetadata", () => video.play());
    }
    const videoTexture = new BABYLON.VideoTexture("videoTexture", video, scene, true, true);
    BABYLON.Effect.ShadersStore["sphericalVertexShader"] = `
      precision highp float;
      attribute vec3 position;
      attribute vec2 uv;
      varying vec2 vUV;
      void main(void) {
          // Flip texture horizontally.
          vUV = vec2(1.0 - uv.x, uv.y);
          gl_Position = vec4(position, 1.0);
      }
    `;
    BABYLON.Effect.ShadersStore["sphericalFragmentShader"] = `
      precision highp float;
      varying vec2 vUV;
      uniform sampler2D textureSampler;
      uniform float u_roll;
      uniform float u_pitch;
      uniform float u_fov;
      uniform float u_aspect;
      uniform float u_fisheyeFOV;
      uniform vec4 u_D;
      const float PI = 3.14159265359;
      void main(void) {
          vec2 screen = (vUV - 0.5) * 2.0;
          screen.x *= u_aspect;
          float f = 1.0 / tan(u_fov * 0.5);
          vec3 ray = normalize(vec3(screen, f));
          float cosPitch = cos(u_pitch);
          float sinPitch = sin(u_pitch);
          mat3 pitchMatrix = mat3(
              1.0, 0.0, 0.0,
              0.0, cosPitch, -sinPitch,
              0.0, sinPitch, cosPitch
          );
          float cosRoll = cos(u_roll);
          float sinRoll = sin(u_roll);
          mat3 rollMatrix = mat3(
              cosRoll, -sinRoll, 0.0,
              sinRoll, cosRoll, 0.0,
              0.0, 0.0, 1.0
          );
          vec3 rotatedRay = rollMatrix * pitchMatrix * ray;
          float theta = acos(clamp(rotatedRay.z, -1.0, 1.0));
          if(theta > (u_fisheyeFOV / 2.0)) {
              gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
              return;
          }
          float phi = atan(rotatedRay.y, rotatedRay.x);
          float r = (theta / (u_fisheyeFOV / 2.0)) * 0.5;
          float r_corr = r * (1.0 + u_D.x * r * r + u_D.y * r * r * r * r + u_D.z * pow(r,6.0) + u_D.w * pow(r,8.0));
          vec2 texCoords = vec2(0.5 + r_corr * cos(phi), 0.5 + r_corr * sin(phi));
          gl_FragColor = texture2D(textureSampler, texCoords);
      }
    `;
    const shaderMaterial = new BABYLON.ShaderMaterial(
      "sphericalShader",
      scene,
      { vertex: "spherical", fragment: "spherical" },
      { attributes: ["position", "uv"], uniforms: ["u_roll", "u_pitch", "u_fov", "u_aspect", "u_fisheyeFOV", "u_D"] }
    );
    shaderMaterial.setTexture("textureSampler", videoTexture);
    shaderMaterial.setFloat("u_roll", rollRad);
    shaderMaterial.setFloat("u_pitch", pitchRad);
    shaderMaterial.setFloat("u_fov", virtualFOVRad);
    const canvasRect = engine.getRenderingCanvasClientRect();
    if (canvasRect) {
      const currentAspect = canvasRect.width / canvasRect.height;
      shaderMaterial.setFloat("u_aspect", currentAspect);
    }
    shaderMaterial.setFloat("u_fisheyeFOV", fisheyeFOVRad);
    shaderMaterial.setVector4("u_D", new BABYLON.Vector4(distortion[0], distortion[1], distortion[2], distortion[3]));
    plane.material = shaderMaterial;
    shaderMaterialRef.current = shaderMaterial;
    let isDragging = false;
    let lastX = 0;
    let lastY = 0;
    const sensitivity = 0.005;
    scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case BABYLON.PointerEventTypes.POINTERDOWN: {
          const event = pointerInfo.event as PointerEvent;
          isDragging = true;
          lastX = event.clientX;
          lastY = event.clientY;
          break;
        }
        case BABYLON.PointerEventTypes.POINTERMOVE: {
          if (!isDragging) return;
          const event = pointerInfo.event as PointerEvent;
          const deltaX = event.clientX - lastX;
          const deltaY = event.clientY - lastY;
          setRollRad((prev) => prev + deltaX * sensitivity);
          setPitchRad((prev) => prev + deltaY * sensitivity);
          lastX = event.clientX;
          lastY = event.clientY;
          break;
        }
        case BABYLON.PointerEventTypes.POINTERUP: {
          isDragging = false;
          break;
        }
        case BABYLON.PointerEventTypes.POINTERWHEEL: {
          const event = pointerInfo.event as WheelEvent;
          const fovSensitivity = 0.001;
          setVirtualFOVRad((prev) => {
            let newFOV = prev - event.deltaY * fovSensitivity;
            newFOV = Math.max(0.3, Math.min(3.0, newFOV));
            return newFOV;
          });
          break;
        }
      }
    });
    
    engine.runRenderLoop(() => {
      scene.render();
      modelScene.render();
    });
    
    return () => {
      window.removeEventListener("resize", setOrtho);
      window.removeEventListener("resize", setPerspective);
      engine.dispose();
    };
  }, [src]);

  useEffect(() => {
    if (shaderMaterialRef.current) {
      shaderMaterialRef.current.setFloat("u_roll", rollRad);
      shaderMaterialRef.current.setFloat("u_pitch", pitchRad);
      shaderMaterialRef.current.setFloat("u_fov", virtualFOVRad);
    }
    if (modelCameraRef.current) {
      modelCameraRef.current.rotationQuaternion = Quaternion.FromEulerAngles((Math.PI/2)-pitchRad, -rollRad, 0);
      modelCameraRef.current.fov = virtualFOVRad;
    }

  }, [rollRad, pitchRad, virtualFOVRad]);

  useEffect(() => {
    if (shaderMaterialRef.current) {
      shaderMaterialRef.current.setFloat("u_fisheyeFOV", fisheyeFOVRad);
    }
  }, [fisheyeFOVRad]);

  useEffect(() => {
    if (shaderMaterialRef.current) {
      shaderMaterialRef.current.setVector4(
        "u_D",
        new BABYLON.Vector4(distortion[0], distortion[1], distortion[2], distortion[3])
      );
    }
  }, [distortion]);

  // --- Overlay: Draw a single highlight point (if provided) ---
  useEffect(() => {
    if (!overlayRef.current || !highlightFisheyePoints) return;
    const ctx = overlayRef.current.getContext("2d");
    if (!ctx) return;
    ctx.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
    
    highlightFisheyePoints.forEach((point) => {
      const virtualUV = transformFisheyeToVirtualView(
        point,
        fisheyeFOVRad,
        distortion,
        rollRad,
        pitchRad,
        virtualFOVRad,
        aspect
      );

      const xNDC = (virtualUV.x * 2) - 1;
      const yNDC = (virtualUV.y * 2) - 1;
      const x = overlayRef.current!.width * 0.5 - xNDC * overlayRef.current!.width * 0.5;
      const y = overlayRef.current!.height * 0.5 + yNDC * overlayRef.current!.height * 0.5;

      ctx.fillStyle = "yellow";
      ctx.beginPath();
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.fill();
    });

    // // Draw crosshairs in the middle of the screen
    // const centerX = overlayRef.current.width / 2;
    // const centerY = overlayRef.current.height / 2;
    // const crosshairLength = 10;
    // const crosshairColor = "red";
    // const crosshairWidth = 2;

    // ctx.strokeStyle = crosshairColor;
    // ctx.lineWidth = crosshairWidth;

    // // Horizontal line
    // ctx.beginPath();
    // ctx.moveTo(centerX - crosshairLength, centerY);
    // ctx.lineTo(centerX + crosshairLength, centerY);
    // ctx.stroke();

    // // Vertical line
    // ctx.beginPath();
    // ctx.moveTo(centerX, centerY - crosshairLength);
    // ctx.lineTo(centerX, centerY + crosshairLength);
    // ctx.stroke();

  }, [highlightFisheyePoints, fisheyeFOVRad, distortion, rollRad, pitchRad, virtualFOVRad, aspect]);

  return (
    <Box component="div" sx={{ position: "relative", width: "100%", height: "100%" }}>
      <canvas ref={canvasRef} style={{ width: "100%", height: "100%", display: "block" }} />
      <canvas
        ref={overlayRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
          width: "100%",
          height: "100%",
        }}
      />
      <Drawer
        anchor="bottom"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { backgroundColor: "rgba(0,0,0,0.4)", boxShadow: "none", pointerEvents: "none" },
        }}
      >
        <Box component="div" sx={{ p: 2, display: "flex", flexDirection: "row", pointerEvents: "auto" }}>
          {/* Left column: Roll, Pitch, Virtual FOV, and Fisheye FOV */}
          <Box component="div" sx={{ flex: 1, pr: 1 }}>
            <Box component="div" sx={{ mb: 2 }}>
              Roll: {(rollRad * 180 / Math.PI).toFixed(1)}°
              <Slider
                value={rollRad}
                min={-Math.PI}
                max={Math.PI}
                step={0.01}
                onChange={(e, val) => setRollRad(val as number)}
                sx={{ color: "white" }}
              />
            </Box>
            <Box component="div" sx={{ mb: 2 }}>
              Pitch: {(pitchRad * 180 / Math.PI).toFixed(1)}°
              <Slider
                value={pitchRad}
                min={-Math.PI / 2}
                max={Math.PI / 2}
                step={0.01}
                onChange={(e, val) => setPitchRad(val as number)}
                sx={{ color: "white" }}
              />
            </Box>
            <Box component="div" sx={{ mb: 2 }}>
              <Box component="div" sx={{ color: "white", mb: 1 }}>
                Virtual FOV (vertical): {verticalVirtualFOVDeg.toFixed(1)}° | Horizontal: {horizontalVirtualFOVDeg.toFixed(1)}° | Diagonal: {diagonalVirtualFOVDeg.toFixed(1)}°
              </Box>
              <Slider
                value={virtualFOVRad * 180 / Math.PI}
                min={0.3 * 180 / Math.PI}
                max={3.0 * 180 / Math.PI}
                step={0.1}
                onChange={(e, val) => setVirtualFOVRad((val as number) * Math.PI / 180)}
                sx={{ color: "white" }}
              />
            </Box>
            <Box component="div" sx={{ mb: 2 }}>
              <Box component="div" sx={{ color: "white", mb: 1 }}>
                Fisheye FOV: {fisheyeDeg.toFixed(1)}° | Horizontal: {fisheyeDeg.toFixed(1)}° | Vertical: {fisheyeDeg.toFixed(1)}°
              </Box>
              <Slider
                value={fisheyeFOVRad * 180 / Math.PI}
                min={90}
                max={180}
                step={0.1}
                onChange={(e, val) => setFisheyeFOVRad((val as number) * Math.PI / 180)}
                sx={{ color: "white" }}
              />
            </Box>
          </Box>
          {/* Right column: Distortion sliders */}
          <Box component="div" sx={{ flex: 1, pl: 1 }}>
            {["D1", "D2", "D3", "D4"].map((label, index) => (
              <Box key={index} component="div" sx={{ mb: 2 }}>
                <Box component="div" sx={{ color: "white", mb: 1 }}>
                  {label}: {distortion[index].toFixed(2)}
                </Box>
                <Slider
                  value={distortion[index]}
                  min={-1}
                  max={1}
                  step={0.01}
                  onChange={(e, val) => {
                    const newDistortion = [...distortion] as [number, number, number, number];
                    newDistortion[index] = val as number;
                    setDistortion(newDistortion);
                  }}
                  sx={{ color: "white" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SphericalVideoViewer;
