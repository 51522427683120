import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { NavLink } from "react-router-dom";
import { Backdrop, CircularProgress, TableContainer, MenuItem, Paper, Button, Toolbar, Typography, Table, TableHead, TableRow, TableCell, TableBody, Box, Checkbox } from "@mui/material";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import AccountDialog from "../dialogs/AccountDialog";
import Account from "../models/Account";
import Confirm from "../components/Confirm";
import Address from "../models/Address";
import TitleToolbar from "../components/TitleToolbar";
import { accountCache } from "../models/Cache";
import ReactGA from "react-ga4";
import ActionMenu from "../components/ActionMenu";


const AccountsList : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  
    
  const breadcrumbs = [
    {name: "Accounts", link: ""}];

  const [loading, setLoading] = useState(!siteBionicsApplication.accountList);
    
  useEffect(() => {
    
    if (siteBionicsApplication.accountList === undefined) {
      setLoading(true);
      siteBionicsApplication.loadAccountsAsync().then(() => {        
        setLoading(false);
      });      
    } 
    
  }, [siteBionicsApplication,   siteBionicsApplication.accountList]);

  const [adding, setAdding] = useState(false);
  
  const [editAccount, setEditAccount] = useState<Account | undefined>(undefined);

  const [deleteAccount, setDeleteAccount] = useState<Account | undefined>(undefined);

  const [busy, setBusy] = useState(false);
    
  const handleAdd = (name: string, address: Address) => {
    setBusy(true);
    siteBionicsApplication.service.createAccountAsync(name, address).then((newAccount: Account) => {

      // Add to the account list
      siteBionicsApplication.accountList!.push(newAccount);

      // Add to the cache
      accountCache.addItem(newAccount);
      
      event("add", "confirm", newAccount.accountId);

      // Done with the dialog
      setAdding(false);
      setBusy(false);
    });    
  };

  const handleDelete = () => {
    
    const accountId = deleteAccount!.accountId;

    event("delete", "confirm", accountId);

    setBusy(true);
    siteBionicsApplication.service.deleteAccountAsync(accountId).then(() => {      

      // We deleted on the server. We need to clean up references in this app.
      
      // Find the account in the account list
      let index = siteBionicsApplication.accountList?.findIndex(obj => accountId === obj.accountId);

      // If the account is in the account list, remove it
      if (index !== -1) {
        siteBionicsApplication.accountList!.splice(index!, 1);
      }

      // Remove it from the MRU list
      if (siteBionicsApplication.mruData) {
        siteBionicsApplication.mruData.removeAccount(accountId);
      }

      if (siteBionicsApplication.currentAccount?.accountId === accountId) {
        siteBionicsApplication.setCurrentAccount(undefined);
      }

      // Remove the account from the cache
      accountCache.removeItem(accountId);
          
      // Close the dialog
      setDeleteAccount(undefined);

      setBusy(false);

    });
    
  } 

  const handleEdit = (accountName: string, billingAddress: Address) => {
    event("edit", "confirm", editAccount!.accountId);

    setBusy(true);
    siteBionicsApplication.service.updateAccount(editAccount!.accountId, accountName, billingAddress).then(() => {
      editAccount!.updateData(accountName, billingAddress);     

      setEditAccount(undefined);
      setBusy(false);
    });    
  }

  const event = (operation: 'add' | 'edit' | 'delete', action: 'click' | 'cancel' | 'confirm', accountId: string | null) => {
    if (operation === 'add' && action !== 'confirm')
      ReactGA.event("account_" + operation + "_" + action);
    else
      ReactGA.event("account_" + operation + "_" + action, {accountId: accountId});
  };
  
  return (
    <>

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleToolbar title="Accounts" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
        <ActionMenu ariaLabel="account operations">
          <MenuItem onClick={() => { setAdding(true); event("add", "click", null) }}>Add Account...</MenuItem>
        </ActionMenu>               
      </TitleToolbar>        

      <Box component="div" sx={{height: "10pt"}}/>

            
      {loading &&
        <Box component="div" display="flex" flexDirection="column" sx={{padding: '20px'}}> 
          <Typography>Loading...</Typography>
        </Box>
      }
      {!loading && siteBionicsApplication.accountList && siteBionicsApplication.accountList?.length === 0 && 
        <Typography>No available accounts</Typography>
      }
      {!loading && siteBionicsApplication.accountList && siteBionicsApplication.accountList?.length > 0 &&
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Account Name</TableCell>
                <TableCell align="left">Account Id</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ overflowY: "auto" }}>
              {siteBionicsApplication.accountList?.map((a) => {
                return (
                  <TableRow key={a.accountId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <NavLink style={{ color: 'white' }} to={`/accounts/${a.accountId}`}>{a.accountName}</NavLink>
                    </TableCell>
                    <TableCell>{a.accountId}</TableCell>
                    <TableCell>
                      <ActionMenu>
                        <MenuItem onClick={() => { setEditAccount(a);  event("edit", "click", a.accountId) }} disabled={!a.hasAccountCapability("UpdateAccount")}>Edit Account...</MenuItem>
                        <MenuItem onClick={() => { setDeleteAccount(a);  event("delete", "click", a.accountId) }} disabled={!a.hasAccountCapability("DeleteAccount")}>Delete Account...</MenuItem>
                      </ActionMenu>
                    </TableCell>
                  </TableRow>
                )
              }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      {adding && 
        <AccountDialog
          title="Add Account"
          saveLabel="Add Account"
          nameLabel="Account Name"
          addressLabel="Billing Address:"
          
          onClose={() => {setAdding(false); event("add", "cancel", null)}}
          onSave={handleAdd}
        />
      }            
      {editAccount && 
        <AccountDialog 
          title="Edit Account" 
          saveLabel="Save Account"
          nameLabel="Account Name"
          addressLabel="Billing Address:"
          name={editAccount.accountName}
          address={editAccount.billingAddress}
          onClose={() => { setEditAccount(undefined);  event("edit", "cancel", editAccount.accountId)}}
          onSave={handleEdit}
        />              
      }
      {deleteAccount &&           
        <Confirm
          title="Delete Account"
          confirmationText={`Are you sure you want to delete ${deleteAccount!.accountName}?`}
          confirmButtonLabel="Delete"
          onClose={() => {setDeleteAccount(undefined); event("delete", "cancel", deleteAccount.accountId)}}
          onConfirm={handleDelete}
        />
      }
    </>
  )
})

export default AccountsList;
