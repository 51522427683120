import { useEffect, useState } from "react";
import { Typography, IconButton, Alert, Box, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { minutesToLabel } from "../util/Time";
import { observer } from "mobx-react";
import Shift from "../models/Shift";
import { toZonedTime } from 'date-fns-tz';
import Site from "../models/Site";
import DownloadIcon from '@mui/icons-material/Download';
import CustomTable, { CellData, RowColumnData, blueColors, calcAverages, exportToCSV, getColorFromValue } from "./CustomTable";
import { ShiftAnalyzer, ShiftInterval } from "../models/ShiftAnalyzer";
import { SpecialDayChecker } from "../util/SpecialDay";
import { formatTime } from "../util/Time";
import useSnapshotViewer from "./useSnapshotViewer";

export function shiftToTableData2(shiftAnalyzer: ShiftAnalyzer, shiftIntervals: ShiftInterval[], granularity: number, displayHours: string, siteDate: Dayjs, site: Site): { values: CellData[][], rowLabels: RowColumnData[], columnHeaders: RowColumnData[], average: number, min: number, max: number, total: number } {

  // Figure out the min/max staffed time and the min/max open time
  const [minStaffedTime, maxStaffedTime] = shiftAnalyzer.getMinMaxStaffedTime(site.timeZone);
  const [minOpenTime, maxCloseTime] = site.getMinMaxOpenHours();

  // Generate the date range for the data we will render (7 days)
  const start = toZonedTime(siteDate.utc().toDate(), site.timeZone);
  const end = toZonedTime(siteDate.add(1, 'week').subtract(1, 'second').utc().toDate(), site.timeZone);
  const days: string[] = [];
  let currentDate = new Date(start);
  const columnHeaders: RowColumnData[] = [];

  const specialDayChecker = new SpecialDayChecker();

  while (currentDate <= end) {
    days.push(currentDate.toDateString());
    const specialDay = specialDayChecker.getSpecialDay(currentDate);
    const columnHeader: RowColumnData = {
      label: dayjs(currentDate).format('ddd M/D/YY'),
      textColor: specialDay ? 'red' : undefined,
      tooltip: specialDay ? specialDay.name : undefined
    };
    columnHeaders.push(columnHeader);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Generate empty table structure
  const rowLabels: RowColumnData[] = [];
  const values: CellData[][] = [];
  for (let i = 0; i < 24 * 60; i += granularity) {
    if (displayHours === "All" ||
      (displayHours === "Open" && (i >= minOpenTime && i < maxCloseTime)) ||
      (displayHours === "Staffed" && (i >= minStaffedTime && i < maxStaffedTime))) {
      const rowLabel: RowColumnData = { label: minutesToLabel(i, false) };
      rowLabels.push(rowLabel);
      const row: CellData[] = days.map(day => ({
        value: 0,
        
        tooltip: `<div style="padding: 5px;"><strong>Date:</strong> ${day}<br/><strong>Employees:</strong><br/></div>`,
        textColor: "black",
        backgroundColor: "grey"
      }));
      values.push(row);
    }
  }

  // Walk the shifts and fill in the data
  for (let interval of shiftIntervals) {

    // Start and end of the shift in UTC
    const shiftStart = new Date(interval.intervalStart);
    const shiftEnd = new Date(interval.intervalEnd);

    // Walk through the increments of the shift till the end
    let timeSlot = shiftStart.getHours() * 60 + shiftStart.getMinutes();
    let currentDay = shiftStart.toDateString();

    const rowIndex = rowLabels.findIndex(rowLabel => rowLabel.label === minutesToLabel(timeSlot, false));
    const colIndex = days.indexOf(currentDay);

    if (rowIndex !== -1 && colIndex !== -1) {
      const cell = values[rowIndex][colIndex];
      cell.data = { date: shiftStart, minutes: timeSlot };
      cell.label = interval.numberWorking.toString();
      cell.value = interval.numberWorking;      
      const emp = interval.peopleWorking.map(entry => {
        const shiftStartLocal = toZonedTime(entry.shift.startTime, site.timeZone);
        const shiftEndLocal = toZonedTime(entry.shift.endTime, site.timeZone);

        const startTimeFormatted = /*entry.startsLate ? `<strong style="color: blue;">${formatTime(shiftStartLocal)}</strong>` : */formatTime(shiftStartLocal);
        const endTimeFormatted = /* entry.endsEarly ? `<strong style="color: blue;">${formatTime(shiftEndLocal)}</strong>` : */formatTime(shiftEndLocal);
        return `${entry.shift.employeeId}: ${entry.shift.firstName} ${entry.shift.lastName} ${startTimeFormatted} to ${endTimeFormatted}`;
      }).join('<br/>');
      cell.tooltip = cell.tooltip!.replace('</div>', `${emp}<br/></div>`);

    }
  }

  // Calculate the average, min, max, and total
  const { average, min, max, total } = calcAverages(values, blueColors);

  // Add the "Total" row for each column
  const totalRow: CellData[] = columnHeaders.map((_, colIndex) => {
    let totalValue = values.reduce((sum, row) => sum + row[colIndex].value, 0);
    totalValue = granularity === 60 ? totalValue : totalValue / (60 / granularity);

    return {
      label: totalValue.toString(),
      value: totalValue,
      data: totalValue,
      textColor: "black",
      backgroundColor: "#e0e0e0"
    };
  });

  rowLabels.push({ label: "Total" });
  values.push(totalRow);

  

  return { values, rowLabels, columnHeaders: columnHeaders, average, min, max, total };
}

const ShiftTable: React.FC<{ shiftData: Shift[], granularity: number, displayHours: string, siteDate: Dayjs, site: Site }> = observer(({ shiftData, granularity, displayHours, siteDate, site }) => {  
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Table data for rendering in the chart
  const [tableData, setTableData] = useState<null | { values: CellData[][], rowLabels: RowColumnData[], columnHeaders: RowColumnData[], average: number, min: number, max: number, total: number }>(null);

  const { handleTableDoubleClick, SnapshotViewer } = useSnapshotViewer(granularity);

  // Generate the table data
  useEffect(() => {
    if (shiftData.length === 0) return;

    setIsLoading(true);

    const shiftAnalyzer = new ShiftAnalyzer(shiftData);
    const shiftIntervals = shiftAnalyzer.getShiftsDuringPeriod(siteDate.toDate(), siteDate.add(1, 'week').toDate(), granularity, site.timeZone);

    const r = shiftToTableData2(shiftAnalyzer, shiftIntervals, granularity, displayHours, siteDate, site);

    setTableData(r);

    setIsLoading(false);

  }, [shiftData, granularity, displayHours, siteDate, site]);

  if (isLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  if (shiftData.length === 0 || tableData === null) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }

  return (
    <>
      <CustomTable columnHeaders={tableData.columnHeaders} rowLabels={tableData.rowLabels} values={tableData.values} defaultOnDoubleClick={handleTableDoubleClick} />
      <Box component="div" display="flex" justifyContent={"space-between"} alignItems={"center"} gap={10}>
        <Typography sx={{ textAlign:"right", marginLeft: '10px'}}>Note: Double click a cell to view video snapshot</Typography>
        <Box component="div" display="flex" flexDirection="row" gap="20px" alignItems="center" justifyContent="right">
          <Typography>Average: {tableData.average.toFixed(2)}</Typography>
          <Typography>Min: {tableData.min}</Typography>
          <Typography>Max: {tableData.max}</Typography>
          <Typography>Total Hours: {tableData.total}</Typography>
          <IconButton
            color="primary"
            onClick={() => exportToCSV(tableData, true, 'staffing_data.csv')}
            sx={{ marginLeft: 2 }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <SnapshotViewer/>      
    </>
  );
});

export default ShiftTable;
