import { makeObservable, observable } from "mobx";
import { Quaternion, Vector3 } from "@babylonjs/core";

export default class Pose {
    @observable position: Vector3 = Vector3.Zero();
    @observable orientation: Quaternion = Quaternion.Identity();

    static identity = new Pose()
        
    constructor(json?: any) {
        if (json) {
            this.position = new Vector3(json.position.x, json.position.y, json.position.z)
            this.orientation = new Quaternion(json.orientation.x, json.orientation.y, json.orientation.z, json.orientation.w)
        }
        makeObservable(this);
    }

    static fromJson(json: any) {
        return new Pose(json);
    }

    toJson() {
        return {
            position: {
            x: this.position.x,
            y: this.position.y,
            z: this.position.z
            },
            orientation: {
            x: this.orientation.x,
            y: this.orientation.y,
            z: this.orientation.z,
            w: this.orientation.w
            }
        };
    }

    equals(pose: Pose | undefined): boolean {
        if (!pose) return false;
        return this.position.equals(pose.position) && this.orientation.equals(pose.orientation);
    }

}