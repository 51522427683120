import React from "react";
import { useEffect, useState } from "react";
import { IconButton, Typography, Alert, Box } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import { minutesToLabel } from "../util/Time";
import Site from "../models/Site";
import DownloadIcon from '@mui/icons-material/Download';
import utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver';
import SnapshotViewerDialog from "../components/SnapshotViewerDialog";
import duration, { Duration } from "dayjs/plugin/duration";
import Shift from '../models/Shift';
import CustomTable, { CellData, RowColumnData, ratioColors, blueColors, getColorFromValue } from "./CustomTable";
import { ShiftAnalyzer } from "../models/ShiftAnalyzer";
import { toZonedTime } from 'date-fns-tz';
import { SpecialDayChecker } from "../util/SpecialDay";
import useSnapshotViewer from "./useSnapshotViewer";

dayjs.extend(duration);
dayjs.extend(utc);

// This is the data fetched from the server and stored. 
// Minutes is the number of minutes into the day since midnight.
// Days are the different days (0-6) in the week
interface HeatmapData {
  minutes: number;
  days: Array<{ date: Dayjs, day: string, min: number, avg: number, max: number, count: number, employees: number, ratio: number }>;
}

// Export function
export function exportOccupancyDataToCSV2(heatmapData: HeatmapData[], site: Site, fileName: string = 'occupancy_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Date/Time', 'Count'];
  csvRows.push(headerRow.join(','));

  // Loop through each day (0 to 6)
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    // Loop through each time slot
    heatmapData.forEach(hourData => {
      if (hourData.minutes >= site.openHours[dayIndex * 2] && hourData.minutes <= site.openHours[dayIndex * 2 + 1]) {
        const dayData = hourData.days[dayIndex];
        const row = [`${dayData.date.format('MM/DD/YYYY')} ${minutesToLabel(hourData.minutes, true)}`, dayData.avg];
        csvRows.push(row.join(','));
      }
    });
  }

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}

// Core react component
const OccupancyTable: React.FC<{ taggedObjectCounts: TaggedObjectCounts[], shiftData: Shift[], granularity: number, displayHours: string, displayData: string, siteDate: Dayjs, site: Site }> = observer(({ taggedObjectCounts, shiftData, granularity, displayHours, displayData, siteDate, site }) => {

  const { handleTableDoubleClick, SnapshotViewer } = useSnapshotViewer(granularity);
  
  // Data used for rendering
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);

  // Data for rendering in the table
  const [tableData, setTableData] = useState<{ values: CellData[][], rowLabels: RowColumnData[], columnHeaders: RowColumnData[], average: number, min: number, max: number}>({
    values: [],
    rowLabels: [],
    columnHeaders: [],
    average: 0,
    min: 0,
    max: 0
  });  

  // Helper component to analyze shifts
  const [shiftAnalyzer, setShiftAnalyzer] = useState<ShiftAnalyzer>(new ShiftAnalyzer(shiftData));

  // Gets the number of employees working at a given time
  const getEmployeeCount = (dayjsDate: Dayjs, granularity: number, timeZone: string): number => {
    if (!shiftData) return 0;    

    const shiftInterval = shiftAnalyzer.getShiftsAtTime(toZonedTime(dayjsDate.toDate(), timeZone), granularity, timeZone);

    return shiftInterval.numberWorking;
  }

  // Calculate the heat map data
  useEffect(() => {
    if (taggedObjectCounts === null) return;

    // Initialize heatmapData with all possible time slots and days
    const data: HeatmapData[] = [];
    const startDate = siteDate.startOf('day');
    const endDate = startDate.add(7, 'day');

    // Loop through each minute bucket in a day
    for (let minutes = 0; minutes < 24 * 60; minutes += granularity) {
      const hourData: HeatmapData = { minutes: minutes, days: [] };

      // Loop through each day in the date range
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const date = startDate.add(dayIndex, 'day');
        const day = date.format('ddd M/D/YY');
        hourData.days.push({
          date: date,
          day: day,
          min: 0,
          avg: 0,
          max: 0,
          count: 0,
          employees: 0,
          ratio: 0
        });
      }

      data.push(hourData);
    }

    // Fill in the data from taggedObjectCounts
    taggedObjectCounts.forEach(toc => {
      // Convert to local time zone considering DST
      const localTime = toZonedTime(toc.time, site.timeZone);
      const minutesInDay = localTime.getHours() * 60 + localTime.getMinutes();
      const minutes = Math.floor(minutesInDay / granularity) * granularity;

      // Find the corresponding hourData
      const hourData = data.find(h => h.minutes === minutes);
      if (!hourData) return;

      // Find the corresponding dayData
      const day = dayjs(localTime).format('ddd M/D/YY');
      const dayData = hourData.days.find(d => d.day === day);
      if (!dayData) return;

      // Update the dayData with the taggedObjectCounts data
      const employees = getEmployeeCount(dayjs(localTime), granularity, site.timeZone);
      if (dayData.count === 0) {
        dayData.min = toc.min;
        dayData.avg = toc.avg;
        dayData.max = toc.max;
        dayData.count = 1;
        dayData.employees = employees;
        dayData.ratio = employees > 0 ? toc.avg / employees : 0;
      } else {
        dayData.min = Math.min(dayData.min, toc.min);
        dayData.max = Math.max(dayData.max, toc.max);
        dayData.avg = (dayData.avg * dayData.count + toc.avg) / (dayData.count + 1);
        dayData.ratio = dayData.employees > 0 ? dayData.avg / dayData.employees : 0;
        dayData.count += 1;
      }
    });

    setHeatmapData(data);
  }, [taggedObjectCounts, shiftData, granularity, siteDate, site]);
  // As the heat map data changes, generate the data for rendering
  useEffect(() => {
    if (heatmapData.length === 0) return;

    // Calculate the min/max staffed time
    const [minStaffedTime, maxStaffedTime] = shiftAnalyzer.getMinMaxStaffedTime(site.timeZone);
    const [minOpenTime, maxCloseTime] = site.getMinMaxOpenHours();

    // Get only the time slots that are within the selected hours
    const filteredHeatmapData = heatmapData.filter((hourData) => {
      const minutes = hourData.minutes;
      return (displayHours === "All" ||
        (displayHours === "Open" && (minutes >= minOpenTime && minutes < maxCloseTime)) ||
        (displayHours === "Staffed" && (minutes >= minStaffedTime && minutes < maxStaffedTime)));
    });

    let total = 0;
    let count = 0;
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;

    filteredHeatmapData.forEach((hourData) => {
      hourData.days.forEach((dayData) => {
        let yValue = dayData.avg;
        if (displayData === "Ratio") {
          yValue = dayData.ratio;
        } else if (displayData === "Min") {
          yValue = dayData.min;
        } else if (displayData === "Max") {
          yValue = dayData.max;
        }

        if (yValue && yValue > 0) {
          total += yValue;
          count++;
          if (yValue > max) max = yValue;
          if (yValue < min) min = yValue;
        }
      });
    });

    const newValues: CellData[][] = filteredHeatmapData.map((hourData) => {
      return hourData.days.map((dayData) => {
        let label = dayData.avg.toFixed(1);
        let yValue = dayData.avg;
        if (displayData === "MinAvgMax") {
          label = `${dayData.min} / ${dayData.avg.toFixed(1)} / ${dayData.max}`;
        } else if (displayData === "Ratio") {
          label = dayData.ratio.toFixed(1);
          yValue = dayData.ratio;
        } else if (displayData === "Min") {
          label = dayData.min.toFixed(1);
          yValue = dayData.min;
        } else if (displayData === "Max") {
          label = dayData.max.toFixed(1);
          yValue = dayData.max;
        }

        const tooltip = `
          <div style="padding: 5px;">
            <strong>Average:</strong> ${dayData.avg.toFixed(1)}<br/>
            <strong>Min:</strong> ${dayData.min}<br/>
            <strong>Max:</strong> ${dayData.max}<br/>
            <strong>Staff:</strong> ${dayData.employees}<br/>
            <strong>Ratio:</strong> ${dayData.ratio.toFixed(1)}<br/>
          </div>`;

        let backgroundColor = "";
        if (yValue === 0) {
          backgroundColor = "grey";
        } else if (displayData === "Ratio") {
          backgroundColor = getColorFromValue(ratioColors, min, max, yValue);
        } else {
          backgroundColor = getColorFromValue(blueColors, min, max, yValue);
        }
        return {
          label: label,
          value: yValue,
          tooltip: tooltip,
          textColor: "black",
          backgroundColor: backgroundColor,
          data: { date: dayData.date, minutes: hourData.minutes }
        };
      });
    });

    const newRowLabels = filteredHeatmapData.map((hourData) => ({ label: minutesToLabel(hourData.minutes, false) }));

    const specialDayChecker = new SpecialDayChecker();

    const newColumnHeaders = heatmapData[0]?.days.map((day) => {
      const specialDay = specialDayChecker.getSpecialDay(day.date.toDate());
      return {
        label: day.date.format('ddd M/D/YY'),
        textColor: specialDay ? 'red' : undefined,
        tooltip: specialDay ? specialDay.name : undefined
      };
    }) || [];


    const averageRow: CellData[] = newColumnHeaders.map((_, colIndex) => {
      const columnValues = newValues.map(row => row[colIndex].value);
      const columnAverage = columnValues.reduce((sum, value) => sum + value, 0) / columnValues.length;
      return {
        label: columnAverage.toFixed(1),
        value: columnAverage,
        textColor: "black",
        backgroundColor: "#e0e0e0",
        data: null
      };
    });
    newValues.push(averageRow);
    newRowLabels.push({ label: "Average" });
    

    setTableData({
      values: newValues,
      rowLabels: newRowLabels,
      columnHeaders: newColumnHeaders,
      average: total / count,
      min: min,
      max: max
    });
    
  }, [heatmapData, displayHours, displayData, granularity, shiftData, site]);  
  
  if (!tableData || tableData.values.length === 0) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }

  return (
    <>
      <CustomTable columnHeaders={tableData.columnHeaders} rowLabels={tableData.rowLabels} values={tableData.values} defaultOnDoubleClick={handleTableDoubleClick} />
      <Box component="div" display="flex" justifyContent={"space-between"} alignItems={"center"} gap={10}>
        <Typography sx={{ textAlign:"right", marginLeft: '10px'}}>Note: Double click a cell to view video snapshot</Typography>
        <Box component="div" display="flex" flexDirection="row" gap="20px" alignItems="center" justifyContent="right">
          <Typography>Overall Average: {tableData.average.toFixed(2)}</Typography>
          <Typography>Min: {tableData.min.toFixed(2)}</Typography>
          <Typography>Max: {tableData.max.toFixed(2)}</Typography>
          <IconButton
            color="primary"
            onClick={() => exportOccupancyDataToCSV2(heatmapData, site)}
            sx={{ marginLeft: 2 }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <SnapshotViewer/>
    </>
  );

});

export default OccupancyTable;
