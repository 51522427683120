import { FC } from "react";
import { observer } from "mobx-react"
import { Alert, Box, Button, List, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import SelectableListItemButton from "./SelectableListItemButton";
import RegionEntity, { RegionType } from "../models/layout/RegionEntity";
import ScanAreaLayoutViewModel, { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import Vector3Model from "../models/layout/Vector3Model";
import { Color3 } from "@babylonjs/core";
import Color3Model from "../models/layout/Color3Model";
import PoseModel from "../models/layout/PoseModel";

const RegionsAccordion: FC<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {

  function handleRemoveRegion(): void {
    if (layoutViewModel.selectedEntity instanceof RegionEntity) {
      layoutViewModel.scanAreaLayout.removeRegion(layoutViewModel.selectedEntity);
      layoutViewModel.selectEntity(undefined);
    }
  }

  function handleAddRegion(): void {
      let region = new RegionEntity({name: "New Region", color: Color3Model.fromColor3(Color3.Red()), regionType: RegionType.Circular, polygonPoints: [], position: Vector3Model.identity(), rotation: 0, circleRadius: 1, rectangleWidth: 1, rectangleHeight: 1});  
      layoutViewModel.scanAreaLayout.addRegion(region);
  }

    return (
        <Box component="div" width={"100%"} display="flex">
            <Accordion disableGutters sx={{width: "100%"}}
                  expanded={layoutViewModel.showRegions ?? true} 
                  onChange={(e, expanded) => {layoutViewModel.setShowRegions(expanded);}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row">
                    <DoorSlidingOutlinedIcon fontSize="small"/>
                    <Box component="div" width={10}/>
                    <Typography>Regions</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component='div' display="flex" flexDirection="column">
                      {layoutViewModel.scanAreaLayout.regions?.length ?
                        (<List dense={true} sx={{ bgcolor: 'background.paper', overflow: "clip" }} >
                            {layoutViewModel.scanAreaLayout.regions.map((region) => (
                                <SelectableListItemButton key={region.name} label={region.name?.length > 0 ?region.name : "Unnamed Region"} ctx={region}/>
                            ))}
                        </List>) : <Alert severity="warning">No regions.</Alert>
                      }
                      <Box component='div' display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button id="removeRegionButton"
                          disabled={!(layoutViewModel.selectedEntity instanceof RegionEntity)}
                          onClick={handleRemoveRegion}>
                          remove
                        </Button>
                        <Button id="addRegionButton" onClick={handleAddRegion}>add</Button>
                      </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
      )
    })
    
export default RegionsAccordion;

