import { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { Engine, Scene } from 'react-babylonjs';
import '@babylonjs/loaders';
import "@babylonjs/loaders/glTF";
import { Color3, Color4, Quaternion, Vector3 } from '@babylonjs/core/Maths/math';
import { Box } from "@mui/material";
import ScanModelLayer from "./ScanModelLayer";
import Scan from "../models/Scan";
import CameraCalibration from "../models/CameraCalibration";
import Camera from "../models/Camera";
import { ScanCameraViewModel } from "../pages/ScanPage";
import { ModelType } from "../models/ModelType";

const ScanAreaCameraLayer: FunctionComponent<{camera: Camera, calibration: CameraCalibration}>
      = observer(({camera, calibration}) => {
  const lineLength = 0.25;
  
  return (
    <>
      <transformNode name="tranform" key={camera.cameraId} position={calibration.pose?.position.asLHVector3() ?? Vector3.Zero()}>
        <transformNode name="tranform" rotationQuaternion={calibration.pose?.orientation.asLHQuaternion() ?? Quaternion.Identity()}>
          <sphere name="sphere" diameter={0.05}>
            {/* <standardMaterial name="material" diffuseColor={siteNavigator.currentCamera?.cameraId !== c.cameraId ? Color3.Red() : Color3.Green()} /> */}
            <standardMaterial name="material" diffuseColor={Color3.Green()} />
            <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(0, 0, lineLength)]} color={Color3.Blue()} />
            <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(0, lineLength, 0)]} color={Color3.Green()} />
            <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(lineLength, 0, 0)]} color={Color3.Red()} />
            <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(0, 0, -10)]} color={Color3.White()} />
          </sphere>
        </transformNode>
      </transformNode>
    </>
  )      
});

const ScanConfigScene : FunctionComponent<{
      scan: Scan, scanCameras: ScanCameraViewModel[], modelType: ModelType, clipHeight?: number}>
      = observer(({scan, scanCameras, modelType, clipHeight}) => {

  // useEffect(() => {
  //   const pairs = scan.area.site.cameras?.map(camera => {
  //     const calibration: CameraCalibration | undefined = scan.cameraCalibrations[camera.id];
  //     return {camera : camera, calibration: calibration};
  //   });
  //   if (pairs)
  //     setCameraCalibrationPairs(pairs);
  // }, [scan.area.site.cameras, scan.cameraCalibrations]);

  return (
   <>
      <Box component="div" overflow="hidden" sx={{ display: 'flex', flex: 1, backgroundColor: '#0000FF' }}>
        <Engine antialias canvasId="babylon-js" renderOptions={{whenVisibleOnly: true,}}>
            <Scene clearColor={new Color4(.5,.5,1,1)}>

              {true &&
              <arcRotateCamera position={new Vector3(0, 5, -5)} name="Camera1" radius={1} alpha={Math.PI / 2} beta={Math.PI / 2} target={new Vector3(0,0,0) }
                  inertia={0} wheelDeltaPercentage={.05} panningSensibility={500} panningInertia={0} maxZ={1000} minZ={.1} setActiveOnSceneIfNoneActive/>
              };

              <hemisphericLight name="light1" intensity={0.7} direction={new Vector3(0, 1, 0)}/>
              
              <lines name="zaxis" points={[new Vector3(0, 0, 0), new Vector3(0, 0, 2)]} color={Color3.Blue()} />
              <lines name="yaxis" points={[new Vector3(0, 0, 0), new Vector3(0, 2, 0)]} color={Color3.Green()} />
              <lines name="xaxis" points={[new Vector3(0, 0, 0), new Vector3(2, 0, 0)]} color={Color3.Red()} />

              <ScanModelLayer scan={scan} modelType={modelType} clipHeight={clipHeight}/ >

              {scanCameras.map((scanCamera) => (
                <>
                  {scanCamera.currentCalibration && scanCamera.showWithCurrentPose &&
                    <ScanAreaCameraLayer key={scanCamera.camera.cameraId + "-current"} camera={scanCamera.camera} calibration={scanCamera.currentCalibration} />
                  }
                  {scanCamera.scanCalibration && scanCamera.showWithScanPose &&
                    <ScanAreaCameraLayer key={scanCamera.camera.cameraId + "-scan"} camera={scanCamera.camera} calibration={scanCamera.scanCalibration} />
                  }
                </>
              ))}


              {/* <transformNode name="modelPosition" position={areaModelEntityToUse?.pose.position.asLHVector3 ?? Vector3.Zero()}>
                <transformNode name="modelRotation" rotationQuaternion={areaModelEntityToUse?.pose.orientation.asLHQuaternion ?? Quaternion.Identity()}>
                  <transformNode name="modelScale" scaling={new Vector3(areaModelEntityToUse?.scale ?? 1, areaModelEntityToUse?.scale ?? 1, areaModelEntityToUse?.scale ?? 1)}>
                    {areaModelEntity &&
                      <ScanModelLayer scan={scan} clipHeight={areaModelEntity.clipHeight} modelType={ModelType.ARModel} />
                    }
                  </transformNode>
                </transformNode>
              </transformNode> */}
            </Scene>
        </Engine>
      </Box>
    </>
  )
})

export default ScanConfigScene;


