import { useState, FunctionComponent, useEffect } from "react";
import Box from "@mui/material/Box";
import { useSiteBionicsApplication } from "../../models/SiteBionicsApplication";
import {accountCache } from "../../models/Cache";



const Developer : FunctionComponent = () => {

    const [debugData, setDebugData] = useState<any>();
    const siteBionicsApplication = useSiteBionicsApplication();

    useEffect(() => {

        const debugData = {
            userInfo: {
                userId: siteBionicsApplication?.me?.id,
                userName: siteBionicsApplication?.me?.email,
                firstName: siteBionicsApplication?.me?.firstName,
                lastName: siteBionicsApplication?.me?.lastName,
            },
            mruData: {
                sites: siteBionicsApplication?.mruData?.sites,
                accounts: siteBionicsApplication?.mruData?.accounts
            },
            selections: {
                accountId: siteBionicsApplication?.currentAccount?.accountId,
                siteId: siteBionicsApplication?.currentSite?.siteId,
                // scanId: siteBionicsApplication?.currentScan?.scanId,
                // scanAreaId: siteBionicsApplication?.currentScanArea?.scanAreaId,
                // modelType: siteBionicsApplication?.currentModelType,
                // cameraId: siteBionicsApplication?.currentCamera?.id,
                // hubId: siteBionicsApplication?.currentHub?.hubId,
                startTime: siteBionicsApplication?.currentSite?.siteNavigator?.startTime,
                endTime: siteBionicsApplication.currentSite?.siteNavigator?.endTime,
                pageState: JSON.stringify(siteBionicsApplication.currentSite?.siteNavigator?.pageState)
            }
        };
        setDebugData(debugData);



    }, [siteBionicsApplication]);
    
    return (

        <Box component="div" margin={5} flexGrow="1" display="flex" flexDirection="column">
            <div style={{wordBreak: "break-all"}}>
            <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap' }}>
                <code>{JSON.stringify(debugData, null, 2)}</code>
            </pre>
            </div>

        </Box>
    );
};

export default Developer;




