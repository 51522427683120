import { useState, useContext, createContext, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Outlet, useLoaderData, useOutletContext, useParams } from "react-router-dom";
import SiteNavigator from "../models/SiteNavigator";
import Site from "../models/Site";
import { useAccount } from "./AccountPage";
import NoAccess from "../components/NoAccess";
import { SiteBionicsApplication, useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { siteCache } from '../models/Cache';
import { Box, Typography} from '@mui/material';

// Context for the site
const SiteDataContext = createContext<Site | null | undefined>(undefined);

// Return the site context
export const useSite = (): Site => { 
  const context = useContext(SiteDataContext);
  if (context === undefined || context === null) {
    throw new Error("Cannot access site when null or undefined");    
  }
  return context;
};

export function useSitePageBreadcrumbs(pageName?  : string, additionalLinks?: {name: string, linkFragment: string}[] ) { 

  const site = useSite();
  const account = site.account;

  let breadcrumbs: {name: string, link: string}[] = [];

  let link = `/accounts`;
  breadcrumbs.push({name: "Accounts", link: "/accounts"});

  link += `/${account.accountId}`
  breadcrumbs.push({name: account.accountName, link: link});

  link += `/sites`
  breadcrumbs.push({name: "Sites", link: link});

  if (pageName === "" && !additionalLinks) {
    breadcrumbs.push({ name: site.siteName, link: "" });
  } else {
    link += `/${site.siteId}`
    breadcrumbs.push({ name: site.siteName, link: link });

    if (additionalLinks) {
      additionalLinks.forEach(al => {
        link += al.linkFragment;
        breadcrumbs.push({ name: al.name, link: link });
      });
    }

    if (pageName) {
      breadcrumbs.push({ name: pageName, link: "" });
    }
  }

  return breadcrumbs;
}


const SitePage : FunctionComponent = observer(() => {
  
  const siteBionicsApplication = useSiteBionicsApplication();
  const account = useAccount();
  const {accountId, siteId} = useParams<{accountId: string, siteId: string}>();
  const [site, setSite] = useState<Site | null | undefined>(undefined);

  useEffect(() => {
    if (accountId === undefined || siteId === undefined) return;
    
    siteCache.getObject(siteId, () => siteBionicsApplication.service.fetchSiteAsync(account, siteId)).then((site: Site | null) => {
      setSite(site);
      if (site) {
        siteBionicsApplication.setCurrentSite(site);
      }
    });    
      
  }, [])


  useEffect(() => {
    
    if (!siteBionicsApplication.mruData) {
      siteBionicsApplication.loadMRUDataAsync();
      return;
    }

    if (accountId === undefined || siteId === undefined || site === undefined) {
      return;
    } else if (site === null) {
      siteBionicsApplication.mruData.removeSite(accountId, siteId);
    } else {
      siteBionicsApplication.mruData.addSite(accountId, siteId);
    }
    
  }, [accountId, siteId, site, siteBionicsApplication, siteBionicsApplication.mruData]);
  
  return (
    <SiteDataContext.Provider value={site}>
      
      {site &&
        <Outlet/>
      }
      {site === null  &&
        <NoAccess account={account} title="Invalid Site" message="Site is either invalid or not accessible."/>
      }
      {(site === undefined) &&
      
        <Box component="div" display="flex" flexDirection="column" sx={{padding: '20px'}}> 
          <Typography>Loading...</Typography>
        </Box>
      }
    </SiteDataContext.Provider>
  )
})

export default SitePage;