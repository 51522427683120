import React, { useState, cloneElement, ReactElement } from 'react';
import { IconButton, Menu, MenuItemProps, MenuItem, Divider } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

interface ActionMenuProps {
  children: React.ReactNode;
  ariaLabel?: string;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ children, ariaLabel }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (child: ReactElement<MenuItemProps>) => {
    return cloneElement(child, {
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        if (child.props.onClick) {
          child.props.onClick(event as any);
        }
        handleMenuClose();
      }
    });
  };

  const renderChildren = (children: React.ReactNode): React.ReactNode[] => {
    return React.Children.toArray(children).flatMap((child) => {
      if (React.isValidElement(child)) {
        if (child.type === MenuItem) {
          return [handleMenuItemClick(child as ReactElement<MenuItemProps>)];
        } else if (child.props?.children) {
          return [cloneElement(child, {
            children: renderChildren(child.props.children)
          } as any)];
        }
      }
      return [child];
    });
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleMenuOpen} aria-label={ariaLabel ? ariaLabel : "operations"}>
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {renderChildren(children)}
      </Menu>
    </div>
  );
};

export default ActionMenu;
