import { useState } from 'react';
import SnapshotViewerDialog from "../components/SnapshotViewerDialog";
import duration, { Duration } from "dayjs/plugin/duration";
import dayjs, { Dayjs } from "dayjs";
import {CellData} from "./CustomTable";

const useSnapshotViewer = (granularity: number) => {
  const [snapshotViewerDialogOpen, setSnapshotViewerDialogOpen] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | undefined>(undefined);
  const [selectedDuration, setSelectedDuration] = useState<Duration | undefined>(undefined);

  const handleTableDoubleClick = (row: number, col: number, cell: CellData): void => {
    if (cell.data === null) return;
    const clickedData = cell.data;
    let date = dayjs(clickedData.date).hour(clickedData.minutes / 60).minute(clickedData.minutes % 60);
    let duration = dayjs.duration({ minutes: granularity });
    setSelectedDuration(duration);
    setSelectedStartTime(date);
    setSnapshotViewerDialogOpen(true);
  };

  const closeSnapshotViewerDialog = () => {
    setSnapshotViewerDialogOpen(false);
  };

  const SnapshotViewer = () => (
    selectedDuration && selectedStartTime && (
      <SnapshotViewerDialog
        open={snapshotViewerDialogOpen}
        onClose={closeSnapshotViewerDialog}
        startTime={selectedStartTime}
        duration={selectedDuration}
      />
    )
  );

  return {
    handleTableDoubleClick,
    SnapshotViewer,
  };
};

export default useSnapshotViewer;