import { FC } from "react";
import { observer } from "mobx-react"

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import 'm-react-splitters/lib/splitters.css';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SiteNavigator from "../models/SiteNavigator";
import TrackedObjectEvent from "../models/TrackedObjectEvent";
import { Color3 } from "@babylonjs/core";
import { encodeColor } from "../util/Colors";
import { formatDuration } from "../util/Time";





interface TrackedObjectEventsTimelineProps {
    siteNavigator: SiteNavigator;
}

const TrackedObjectEventsTimeline: FC<TrackedObjectEventsTimelineProps> = observer(({siteNavigator }) => {

    const siteBionicsApplication = useSiteBionicsApplication();

    let trackedObjectEvents = siteNavigator.currentTrackedObject?.trackedObjectEvents;
    const minTime = siteNavigator.currentTrackedObject?.startTime?.getTime() ?? 0;
    const maxTime = siteNavigator.currentTrackedObject?.endTime?.getTime() ?? 0;
    const timeSpan = maxTime - minTime;

    const getBarStyle = (trackedObjectEvent: TrackedObjectEvent) => {
        let startTime = trackedObjectEvent.startTime.getTime();
        let endTime = trackedObjectEvent.endTime.getTime();
        startTime = Math.max(startTime, minTime);
        endTime = Math.min(endTime, maxTime);
        const leftOffset = ((startTime - minTime) / timeSpan) * 100;
        const width = ((endTime - startTime) / timeSpan) * 100;
        return {
            marginLeft: `${leftOffset}%`,
            width: `${width}%`,
            height: '100%',
            backgroundColor: '#4CB8B1',
            borderRadius: '3px'
        };
    };
    
    return (
        <Box component="div">
            {trackedObjectEvents && trackedObjectEvents.length > 0 &&
                <TableContainer sx={{ width: "100%", height: "100%", paddingLeft: "10pt", paddingRight: "10pt" }}>
                    <Table size="small" stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell width={30}>#</TableCell>
                                <TableCell width={150}>Zone</TableCell>
                                <TableCell>Time Span</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowX: "auto" }}>
                            {trackedObjectEvents.sort((a,b)=>a.startTime.getTime() - b.startTime.getTime()).map((to, index) => (
                                <TableRow>
                                    <TableCell>{index}</TableCell>
                                    <TableCell>{to.zone}</TableCell>
                                    <TableCell>
                                        <div style={{ position: 'relative', width: '100%', height: '20px' }}>
                                            <div style={getBarStyle(to)}>
                                                {formatDuration(to.startTime, to.endTime)}
                                            </div>
                                            
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
})

export default TrackedObjectEventsTimeline;