import React from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

interface ToggleIconButtonProps {
  value: boolean;
  enabled: boolean;
  working: boolean;
  onClicked: () => void;
  trueIcon: React.ReactNode;
  falseIcon: React.ReactNode;
  disabledIcon: React.ReactNode;
}

const ToggleIconButton: React.FC<ToggleIconButtonProps> = observer(
  ({ value, enabled, working, onClicked, trueIcon, falseIcon, disabledIcon }) => {
    const handleClick = () => {
      if (!working && enabled) {
        onClicked();
      }
    };

    let iconToRender: React.ReactNode;

    if (working) {
      // While working, show a spinner icon
      iconToRender = <CircularProgress size={24} />;
    } else if (!enabled) {
      iconToRender = disabledIcon;
    } else {
      iconToRender = value ? trueIcon : falseIcon;
    }

    return (
      <IconButton size="small" onClick={handleClick} disabled={!enabled || working}>
        {iconToRender}
      </IconButton>
    );
  }
);

export default ToggleIconButton;
