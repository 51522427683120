import React, { useState, useEffect } from 'react';
import Site from "../models/Site";
import { accountCache, siteCache } from '../models/Cache';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import { observer } from "mobx-react-lite";
import { Tooltip } from '@mui/material';

interface CachedSiteControlProps {
  accountId: string;
  siteId: string;
}

const CachedSiteControl: React.FC<CachedSiteControlProps> = ({ accountId, siteId }) => {

  const [site, setSite] = useState<Site | undefined | null>(undefined);

  const siteBionicsApplication = useSiteBionicsApplication();

  useEffect(() => {
    const fetchData = async () => {
      let account = await accountCache.getObject(accountId, () => siteBionicsApplication.service.fetchAccountAsync(accountId));

      if (!account) {
        setSite(null);
        return;
      }

      let site = await siteCache.getObject(siteId, () => siteBionicsApplication.service.fetchSiteAsync(account!, siteId));      

      setSite(site);
    };

    fetchData();
  });

  if (site === undefined) {
    return (<Tooltip title={siteId}><span>Loading...</span></Tooltip>)
  } else if (site === null) {
    return (<span>{siteId}</span>);
  } else {
    return (<Tooltip title={siteId}><span>{site.siteName}</span></Tooltip>)
  }
};

export default observer(CachedSiteControl);
