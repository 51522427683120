
import { action, makeObservable, observable } from "mobx";
import Site from "./Site";
import { SiteBionicsApplication } from "./SiteBionicsApplication";

export default class Hub {

    @observable site: Site;
    @observable hubId: string;
    @observable name: string;
    @observable id: string;

    constructor(site: Site, hubId: string, name: string) {
        this.name = name ?? hubId;
        this.site = site;
        this.hubId = hubId;
        this.id = hubId;
        makeObservable(this);
    }

    @action
    async deleteAsync() {
        // client side lie... remove locally first
        let remainingHubs = this.site.hubs?.filter(h => h.hubId !== this.hubId);
        this.site.setHubs(remainingHubs);
        await SiteBionicsApplication.getInstance().service.unpairHubAsync(this);
        //todo: handle errer
    }

    async restartHubAsync() {
        await SiteBionicsApplication.getInstance().service.restartHubAsync(this);
    }

    @action
    async setName(name: string) {
        this.name = name;
    }
}
