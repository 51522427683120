import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Box, Card, CardMedia, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CameraIndoorOutlinedIcon from '@mui/icons-material/CameraIndoorOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useSelectionManager } from "../util/SelectionManager";
import { PreAuthUrl } from "../util/PreAuthUrl";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import Camera from "../models/Camera";
import CameraEntity from "../models/layout/CameraEntity";
import QuaternionModel from "../models/layout/QuaternionModel";


const CameraProperties: FunctionComponent<{ camera: CameraEntity }> = observer(({ camera }) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const selectionManager = useSelectionManager();
  const [cameraImagePreAuthUrl] = useState(new PreAuthUrl());

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <CameraIndoorOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Camera Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {camera.camera?.cameraName ?? "Unnamed Camera"}

          <form>
            <TextField
              label="Name"
              value={camera.camera?.cameraName ?? "Unnamed Camera"}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Field of View"
              type="number"
              value={camera.fieldOfView}
              inputProps={{step: 0.25, min: 45, max: 180}}
              onChange={(e) => {camera.setFieldOfView(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Distortion (K)"
              type="number"
              value={camera.distortionK}
              inputProps={{step: 0.0025, min: -2, max: +2}}
              onChange={(e) => {camera.setDistortionK(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position X" margin="normal"
              inputProps={{step: 0.025, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.position.x}
              onChange={(e) => {camera.pose.position.setX(parseFloat(e.target.value))}}
            />
            <TextField
              label="Position Y" margin="normal"
              inputProps={{step: 0.025, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.position.y}
              onChange={(e) => {camera.pose.position.setY(parseFloat(e.target.value))}}
            />
            <TextField
              label="Position Z" margin="normal"
              inputProps={{step: 0.025, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.position.z}
              onChange={(e) => {camera.pose.position.setZ(parseFloat(e.target.value))}}
            />
              <TextField
              label="Rotation X" margin="normal"
              inputProps={{step: 0.25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.orientation.asEulerAngles.x}
              onChange={(e) => {
                let eulerAngles = camera.pose.orientation.asEulerAngles;
                eulerAngles.set(parseFloat(e.target.value), eulerAngles.y, eulerAngles.z);
                camera.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
              }}
            />
            <TextField
              label="Rotation Y" margin="normal"
              inputProps={{step: 0.25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.orientation.asEulerAngles.y}
              onChange={(e) => {
                let eulerAngles = camera.pose.orientation.asEulerAngles;
                eulerAngles.set(eulerAngles.x, parseFloat(e.target.value), eulerAngles.z);
                camera.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
              }}
            />
          
          <TextField label="Cx" margin="normal" InputProps={{ readOnly: true }} value={camera.cx}/>
          <TextField label="Cy" margin="normal" InputProps={{ readOnly: true }} value={camera.cy}/>
          <TextField label="Fx" margin="normal" InputProps={{ readOnly: true }} value={camera.fx}/>
          <TextField label="Fy" margin="normal" InputProps={{ readOnly: true }} value={camera.fy}/>
          <TextField label="Width" margin="normal" InputProps={{ readOnly: true }} value={camera.width}/>
          <TextField label="Height" margin="normal" InputProps={{ readOnly: true }} value={camera.height}/>
          <TextField label="vFov" margin="normal" InputProps={{ readOnly: true }} value={camera.vfovDegrees}/>
          <TextField label="hFov" margin="normal" InputProps={{ readOnly: true }} value={camera.hfovDegrees}/>
          <TextField label="diagFov" margin="normal" InputProps={{ readOnly: true }} value={camera.fovDegrees}/>
          <TextField
              label="D1" margin="normal"
              inputProps={{step: 0.001, min: -1, max: 1}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.distortion[0]}
              onChange={(e) => {camera.setDistortionCoeff(0, parseFloat(e.target.value))}}
            />
          <TextField
              label="D2" margin="normal"
              inputProps={{step: 0.001, min: -1, max: 1}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.distortion[1]}
              onChange={(e) => {camera.setDistortionCoeff(1, parseFloat(e.target.value))}}
            />
          <TextField
              label="D3" margin="normal"
              inputProps={{step: 0.001, min: -1, max: 1}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.distortion[2]}
              onChange={(e) => {camera.setDistortionCoeff(2, parseFloat(e.target.value))}}
            />
          <TextField
              label="D4" margin="normal"
              inputProps={{step: 0.001, min: -1, max: 1}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.distortion[3]}
              onChange={(e) => {camera.setDistortionCoeff(3, parseFloat(e.target.value))}}
            />
          </form>
        </AccordionDetails>
      </Accordion>
      {/* <Card>
        <CardMedia
          component="img"
          alt="Camera Image"
          image={cameraImagePreAuthUrl.url}
        />
      </Card> */}
    </div>

  )
})

export default CameraProperties;