import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { ILoadedModel } from 'react-babylonjs'
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import "./SplatFileLoader";
import { Color3, Plane } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import ModelWithProgress from "./ModelWithProgress";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { ModelType } from "../models/ModelType";
import SiteNavigator from "../models/SiteNavigator";
import Scan from "../models/Scan";

const ScanModelLayer: FunctionComponent<{
    scan: Scan, modelType: ModelType, clipHeight?: number}>
     = observer(({scan, modelType, clipHeight}) => {
  
  const siteBionicsApplication = useSiteBionicsApplication();
  const [modelUrlRoot, setModelUrlRoot] = useState<any>(null);
  const [modelUrlFilename, setModelUrlFilename] = useState<any>(null);
  const [loadedModel, setLoadedModel] = useState<ILoadedModel | undefined>(undefined);
  const [version, setVersion] = useState(0);

  // load a new model when scan or model type changes
  useEffect(() => {
    setModelUrlFilename(null);
    setModelUrlRoot(null);
    setVersion(version + 1); // this version helps us work around the fact that loading a new mesh doesn't clear out the old one
    if (scan) loadScanModel(scan.area.site.account.accountId, scan.area.site.siteId, scan.scanId, modelType);

    // clean up callback
    return () => {
      if (loadedModel) {
        loadedModel.meshes?.forEach(mesh => {
          mesh.dispose();
        });
      }
      setLoadedModel(undefined);
    }
  }, [scan, modelType]);

  // when the clip height changes, clip the model
  useEffect(() => {
    if (loadedModel) {
      clipModel(loadedModel);
    }
  }, [clipHeight]);

  async function loadScanModel(accountId: string, siteId: string, scanId: string, modelType: ModelType) {
    const resourceName = `SiteScans/${scanId}/${ModelType[modelType]}` + (modelType === ModelType.GaussianSplats ? '.ply' : '.glb')
    siteBionicsApplication.service.fetchSiteResourceSasUri(accountId, siteId, resourceName).then((sasUri) => {
      const root = 'https://sitebionicsstorage.blob.core.windows.net/siteblobs/';
      const filename = sasUri.replace(root, '');
      setModelUrlRoot(root);
      setModelUrlFilename(filename);
    });
  }
  
  function clipModel(model: ILoadedModel) {
      const clipPlane = clipHeight ? new Plane(0, 1, 0, -clipHeight) : null;
      model.meshes!.forEach((mesh) => {
        if (mesh.material)
          mesh.material.clipPlane = clipPlane;
      });
  }

  // callback for model loader, set the state and clip the model
  function onModelLoaded(model: ILoadedModel) {
    setLoadedModel(model);
    clipModel(model);
  }

  return (
    <>
      {modelUrlRoot &&
        <ModelWithProgress key={version} rootUrl={`${modelUrlRoot}`} sceneFilename={`${modelUrlFilename}`}
          progressBarColor={Color3.FromInts(255, 165, 0)} center={new Vector3(0, 0, 0)}
          modelRotation={new Vector3(0, 0, 0)} onModelLoaded={onModelLoaded} />}
    </>
  );
})

export default ScanModelLayer;


