import { ExtendedModel, idProp, model, modelAction, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import PoseModel from "./PoseModel";
import { action, computed, observable } from "mobx";
import Camera from "../Camera";
import CameraPose from "../CameraPose";
import { Vector2, Vector3 } from "@babylonjs/core";
import Vector2Model from "./Vector2Model";
import { computeGroundIntersection, projectPointToGroundPlane, undistortPointBrownConrady, undistortPointFisheye4, undistortPointFisheye4Fov } from "../../util/MathUtil";


@model("SiteBionics/CameraEntity")
export default class CameraEntity extends ExtendedModel(LayoutEntity, {
    pose: prop<PoseModel>(PoseModel.identity).withSetter(),
    cameraId: prop<string>("").withSetter(),
    fieldOfView: prop<number>(91.25).withSetter(),
    distortionK: prop<number>(-0.1675).withSetter(),
    alignmentPoints: prop<Vector2Model[]>(() => []).withSetter(),
    cx: prop<number>(1).withSetter(),
    cy: prop<number>(1).withSetter(),
    fx: prop<number>(1).withSetter(),
    fy: prop<number>(1).withSetter(),
    width: prop<number>(1).withSetter(),
    height: prop<number>(1).withSetter(),
    distortion: prop<number[]>(() => []).withSetter(),
}) {
    @modelAction addAlignmentPoint(alignmentPoint: Vector2) {
        this.alignmentPoints.push(Vector2Model.fromVector2(alignmentPoint));
    }

    @modelAction setDistortionCoeff(index: number, value: number) {
        const newDistortion = [...this.distortion];
        newDistortion[index] = value;
        this.distortion = newDistortion;
    }
    
    @computed
    get hfovDegrees(): number {
        return 2 * Math.atan(this.width / (2 * this.fx)) * (180 / Math.PI);
    }

    @computed
    get vfovDegrees(): number {
        return 2 * Math.atan(this.height / (2 * this.fy)) * (180 / Math.PI);
    }

    @computed
    get fovDegrees(): number {
        const hfov = this.hfovDegrees;
        const vfov = this.vfovDegrees;
        return Math.sqrt(hfov * hfov + vfov * vfov);
    }
    
    @observable camera?: Camera
    @action setCamera(camera: Camera) { this.camera = camera; };

    undistortPoint(point: Vector2): Vector2 {   
        if (!this.camera) return point;
        return undistortPointFisheye4(point,
            this.cx, this.cy, this.fx, this.fy,
            this.distortion[0], this.distortion[1], this.distortion[2], this.distortion[3]);
        // return undistortPointFisheye4Fov(point,
        //     this.width, this.height, this.cx, this.cy, this.fieldOfView,
        //     //0,0,0,0);
        //     this.distortion[0], this.distortion[1], this.distortion[2], this.distortion[3]);
        
    }

    projectPointToGroundPlane(point: Vector2, planeHeight: number = 0): Vector3 | undefined {
        if (!this.camera) return undefined;
        return computeGroundIntersection(point, this.cx, this.cy, this.fx, this.fy,
            this.pose!.orientation.asQuaternion, this.pose!.position.asVector3, planeHeight);
    }

    static fromCameraPose(cameraPose: CameraPose): CameraEntity {
        return new CameraEntity({cameraId: cameraPose.cameraId, pose: PoseModel.fromPose(cameraPose.pose)});
    }
}

