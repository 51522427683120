import { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import Scan from "../models/Scan";
import { ScanState } from "../models/ScanState";

const ScanIndicator: FunctionComponent<{ scan: Scan }> = observer(({ scan }) => {
  const [currentState, setCurrentState] = useState<ScanState>(scan.scanState);
  const [isPolling, setIsPolling] = useState(currentState === ScanState.Submitted || currentState === ScanState.Processing);

  const siteBionicsApplication = useSiteBionicsApplication();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isPolling) {
      interval = setInterval(async () => {
        const newScanState = await siteBionicsApplication.service.fetchScanState(scan);
        if (newScanState !== currentState) {
          setCurrentState(newScanState);
          scan.setScanState(newScanState);

          if (
            newScanState === ScanState.Unsubmitted ||
            newScanState === ScanState.Cancelled || // Corrected from Canceled to Cancelled
            newScanState === ScanState.Error ||
            newScanState === ScanState.Complete
          ) {
            setIsPolling(false);
          }
        }
      }, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPolling, currentState, scan, siteBionicsApplication]);

  const renderState = (): JSX.Element => {
    switch (currentState) {
      case ScanState.Unsubmitted:
      case ScanState.Cancelled: 
      case ScanState.Error:
      case ScanState.Complete:
        return <Typography fontSize={14}>{ScanState[currentState]}</Typography>;
      case ScanState.Submitted:
      case ScanState.Processing:
        return (
          <Box component="div" display="flex" alignItems="center">
            <Stack direction="column" spacing={1}>
              {ScanState[currentState]}
              <LinearProgress sx={{ ml: 2, width: "100%", height: "1pt"}} />
            </Stack>
          </Box>
        );
      default:
        return <Typography fontSize={14}>Unknown State: {currentState}</Typography>;
    }
  };

  return <div>{renderState()}</div>;
});

export default ScanIndicator;
