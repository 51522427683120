import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import { observer } from "mobx-react";
import { Toolbar, Paper, TableContainer, Box, Typography } from '@mui/material';

interface TitleToolbarProps {
  title?: string;
  children?: ReactNode;
  alignRight?: boolean;
  sx?: React.CSSProperties; // Define sx as an optional prop
}

const TitleToolbar: FunctionComponent<TitleToolbarProps> = observer(({ title, children, sx, alignRight }) => {
  // Convert children to an array
  const childrenArray = React.Children.toArray(children) as ReactNode[];

  return (
    <Box component="div" sx={sx}>
      <TableContainer component={Paper}>
        <Toolbar variant="dense" sx={{ display: "flex", alignItems: "center", gap: "5pt" }}>
          {title && 
            <Typography variant="h6" color="inherit" component="div">
              {title}
            </Typography>
          }

          <Box component="div" sx={{ display: "flex", flex: 1, justifyContent: alignRight ? 'flex-end' : 'flex-start', gap: "10pt" }}>
            {childrenArray}
          </Box>
        </Toolbar>
      </TableContainer>
    </Box>
  );
});

export default TitleToolbar;
