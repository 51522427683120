import { useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Typography } from '@mui/material';
import Site from "../models/Site";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DwellTimeDetailChart from "../charts/DwellTimeDetailChart";
import WeekPicker from "../components/WeekPicker";
import useQueryState from "../hooks/useQueryState";
import PageState from "../models/PageState";


const DwellTimeDetailContainer: React.FC<{ pageState: PageState, site: Site }> = observer(({ pageState, site }) => {
  const [startDate, setStartDate] = useQueryState<Dayjs>("d2", pageState, "wd", dayjs(), (value) => value.toISOString(), (value) => dayjs(value)); 
  const [hasData, setHasData] = useState<boolean>(false);

  const handleRefresh = () => {
    setHasData(true);
    setStartDate(startDate, true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>

        <Box component="div" gap={2} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', margin: '10px' }}>
          <Typography variant="h4">Dwell Time Detail</Typography>
          <WeekPicker
            weekLabel="Date"
            initialStartDate={startDate}
            useMonday={site.dayOfWeekStart === 1}
            onChange={(date) => {
              setHasData(false);
              setStartDate(date);
            }}
          />
          <Button id="refreshChart" onClick={handleRefresh} size="small">Refresh</Button>
        </Box>

        {hasData ? (
          <DwellTimeDetailChart startDate={startDate} site={site} />
        ) : (
          <Box component="div" sx={{ marginLeft: '10px', display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '10px' }}>
            <Typography>Click refresh to render chart</Typography>
          </Box>
        )}

      </Box>
    </LocalizationProvider>
  );
});

export default DwellTimeDetailContainer;
