import { useEffect, useState } from "react";
import { Typography, Select, IconButton, Alert, Box, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import { SiteEvent } from "../models/SiteEvent";
import Site from "../models/Site";
import DownloadIcon from '@mui/icons-material/Download';
import CustomTable, { CellData, RowColumnData, blueColors, calcAverages, exportToCSV, getColorFromValue } from "./CustomTable";
import { TransactionAnalyzer, TransactionAnalysisResults } from "../models/TransactionAnalyzer";
import Shift from "../models/Shift";
import { ShiftAnalyzer } from "../models/ShiftAnalyzer";
import {SpecialDayChecker} from "../util/SpecialDay";
import useSnapshotViewer from "./useSnapshotViewer";

export function transactionToTableData(site: Site, shiftAnalyzer: ShiftAnalyzer, analysisResults: TransactionAnalysisResults, displayHours: string)
  : null | { values: CellData[][]; rowLabels: RowColumnData[]; columnHeaders: RowColumnData[], average: number, min: number, max: number, total: number } {

  // Figure out the min/max staffed time and the min/max open time
  const [minStaffedTime, maxStaffedTime] = shiftAnalyzer.getMinMaxStaffedTime(site.timeZone);
  const [minOpenTime, maxCloseTime] = site.getMinMaxOpenHours();

  // Filter intervals based on displayHours setting
  const filteredIntervals = analysisResults.intervals.filter((interval) => {
    const intervalTime = interval.intervalStart;
    const intervalMinutes = intervalTime.getHours() * 60 + intervalTime.getMinutes();
    if (displayHours === "Open") {
      return intervalMinutes >= minOpenTime && intervalMinutes < maxCloseTime;
    } else if (displayHours === "Staffed") {
      return intervalMinutes >= minStaffedTime && intervalMinutes < maxStaffedTime;
    }
    return true; // "All"
  });

  // Extract unique days from intervals for column headers
  const uniqueDays = Array.from(
    new Set(filteredIntervals.map((interval) => interval.intervalStart.toLocaleDateString(undefined, { weekday: 'short', month: '2-digit', day: '2-digit', year: '2-digit' })))
  );

  const specialDayChecker = new SpecialDayChecker();

  // Generate column headers (dates)
  const columnHeaders: RowColumnData[] = uniqueDays.map(day => {
    const date = dayjs(day, 'ddd MM/DD/YY');
    const specialDay = specialDayChecker.getSpecialDay(date.toDate());
    return {
      label: day,
      textColor: specialDay ? 'red' : undefined,
      tooltip: specialDay ? specialDay.name : undefined
    };
  });

  // Generate row labels (time intervals)
  const rowLabelsSet = new Set(
    filteredIntervals.map((interval) =>
      interval.intervalStart.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
    )
  );
  const rowLabels: RowColumnData[] = Array.from(rowLabelsSet)
    .sort((a, b) => dayjs(a, 'hh:mm A').diff(dayjs(b, 'hh:mm A')))
    .map(time => ({ label: time }));

  // Create a mapping of intervals to their positions in the table
  const dayIndexMap = uniqueDays.reduce((map, day, index) => {
    map[day] = index;
    return map;
  }, {} as { [key: string]: number });

  const timeIndexMap = rowLabels.reduce((map, rowLabel, index) => {
    map[rowLabel.label] = index;
    return map;
  }, {} as { [key: string]: number });

  // Initialize cell data grid
  const values: CellData[][] = Array(rowLabels.length)
    .fill(null)
    .map(() => Array(uniqueDays.length).fill(null));

  // Populate the cell data with interval data
  filteredIntervals.forEach((interval) => {
    const dayLabel = interval.intervalStart.toLocaleDateString(undefined, { weekday: 'short', month: '2-digit', day: '2-digit', year: '2-digit' });
    const timeLabel = interval.intervalStart.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });

    const dayIndex = dayIndexMap[dayLabel];
    const timeIndex = timeIndexMap[timeLabel];

    if (dayIndex !== undefined && timeIndex !== undefined) {
      const value = numberToCurrency(interval.totalOrderAmount - interval.totalOrderDiscount);
      values[timeIndex][dayIndex] = {
        value: interval.totalOrderAmount - interval.totalOrderDiscount,
        textColor: "black",
        tooltip: "Orders: " + numberToCurrency(interval.totalOrderCount) + ", Items: " +  interval.totalItemCount.toString() ,
        data : { date: interval.intervalStart, minutes: interval.intervalStart.getHours() * 60 + interval.intervalStart.getMinutes() }
      } as CellData;
    }
  });

  if (values.length === 0) return null;

  // Calculate the average, min, max, and total
  const { average, min, max, total } = calcAverages(values, blueColors);

  if (values.length > 0) {

    // Add another row with the column totals  
    const totalRow: CellData[] = values[0].map((_, colIndex) => {
      let totalValue = values.reduce((sum, row) => sum + row[colIndex].value, 0);
      return {
        value: totalValue,
        data: totalValue,
        textColor: "black",
        backgroundColor: "#e0e0e0"
      };
    });

    values.push(totalRow);

    rowLabels.push({ label: "Totals" });
  }

  return { columnHeaders, rowLabels, values, average, min, max, total };
}

function numberToCurrency(value: number): string {
  return "$" + value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const TransactionTable: React.FC<{ transactionEventData: SiteEvent[], shiftData: Shift[], granularity: number, displayHours: string, siteDate: Dayjs, site: Site }> = observer(({ transactionEventData, shiftData, granularity, displayHours, siteDate, site }) => {  
  
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Table data for rendering in the chart
  const [tableData, setTableData] = useState<null | { values: CellData[][], rowLabels: RowColumnData[], columnHeaders: RowColumnData[], average: number, min: number, max: number, total: number }>(null);

  const shiftAnalyzer = new ShiftAnalyzer(shiftData);

  const { handleTableDoubleClick, SnapshotViewer } = useSnapshotViewer(granularity);
  
  // Generate the table data
  useEffect(() => {
    if (transactionEventData.length === 0) return;

    setIsLoading(true);

    const transactionAnalyzer = new TransactionAnalyzer(transactionEventData);
    const transactionAnalysis = transactionAnalyzer.analyze(siteDate.toDate(), siteDate.add(1, 'week').toDate(), granularity, site.timeZone);
    const r = transactionToTableData(site, shiftAnalyzer, transactionAnalysis, displayHours);
    setTableData(r);

    setIsLoading(false);

  }, [transactionEventData, granularity, displayHours, siteDate, site]);

  if (isLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  if (transactionEventData.length === 0 || tableData === null) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }

  return (
    <>
      <CustomTable formatter={numberToCurrency} columnHeaders={tableData!.columnHeaders} rowLabels={tableData!.rowLabels} values={tableData!.values} defaultOnDoubleClick={handleTableDoubleClick} />
      <Box component="div" display="flex" justifyContent={"space-between"} alignItems={"center"} gap={10}>
        <Typography sx={{ textAlign:"right", marginLeft: '10px'}}>Note: Double click a cell to view video snapshot</Typography>
        <Box component="div" display="flex" flexDirection="row" gap="20px" alignItems="center" justifyContent="right">
          <Typography>Average: {numberToCurrency(tableData.average)}</Typography>
          <Typography>Min: {numberToCurrency(tableData.min)}</Typography>
          <Typography>Max: {numberToCurrency(tableData.max)}</Typography>
          <Typography>Total: {numberToCurrency(tableData.total)}</Typography>
          <IconButton
            color="primary"
            onClick={() => exportToCSV(tableData, true, 'transaction_data.csv', numberToCurrency)}
            sx={{ marginLeft: 2 }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <SnapshotViewer/>
    </>
  );
});

export default TransactionTable;
