import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import { Box, Button, ButtonGroup } from '@mui/material';
import { SelectionManager, SelectionManagerContext } from "../util/SelectionManager";
import SiteViewerScene from "../components-3d/SiteViewerScene";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import ScanAreaAccordion from "../components/ScanAreaAccordian";
import EntrancesAccordion from "../components/EntrancesAccordian";
import RegionsAccordion from "../components/RegionsAccordian";
import SelectEntityPropertiesPane from "../components/SelectedEntityPropertiesPane";
import CamerasAccordion from "../components/CamerasAccordian";
import EntrancesLayer from "../components-3d/EntrancesLayer";
import RegionsLayer from "../components-3d/RegionsLayer";
import { UndoManager, getSnapshot } from "mobx-keystone";
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import HistoryIcon from '@mui/icons-material/History';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import ScanAreaLayoutEditor from "../models/layout/ScanAreaLayoutEditor";
import { ScanAreaLayoutViewModelContext, useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import GridLinesLayer from "../components-3d/GridLinesLayer";
import AreaModelsAccordion from "../components/AreaModelsAccordian";
import CamerasLayer from "../components-3d/CamerasLayer";
import SnapshotViewer from "../components/SnapshotViewer";
import PointersLayer from "../components-3d/PointersLayer";
import TriggersLayer from "../components-3d/TriggersLayer";
import TriggersAccordion from "../components/TriggersAccordian";
import WallsAccordion from "../components/FloorPlanAccordion";
import WallsLayer from "../components-3d/WallsLayer";
import AreaModelEntity from "../models/layout/AreaModelEntity";

const Editor: FunctionComponent = observer(() => {
  const [selectionManager] = useState(new SelectionManager());
  const site = useSite();
  const siteNavigator = site.siteNavigator;
  const breadcrumbs = useSitePageBreadcrumbs("Editor");
  
  const siteBionicsApplication = useSiteBionicsApplication();
  const [editor, setEditor] = useState<ScanAreaLayoutEditor | undefined>(undefined);

  // Initial load (one time) - This effect ensures all the scan areas are loaded for the selected site 
  // and sets the current scan area to the first one
  useEffect(() => {
    siteNavigator.site.loadAreasAsync().then((scanAreas) => {
      if (scanAreas?.length) {
          siteNavigator.setCurrentScanArea(scanAreas[0]);
      }
    });
  }, []);

  // Load layout for selected scan area - This is a reaction to the current scan changing (from above effect or UI),
  // we load the scan area layout
  useEffect(() => {
    if (siteNavigator.currentScan) {
      siteBionicsApplication.service.fetchScanAreaLayoutAsync(siteNavigator.currentScan).then((scanAreaLayout) => {
      //siteBionicsApplication.service.fetchScanAreaLayoutAsync2(siteNavigator.currentScan).then((scanAreaLayout) => {
      // we set the current scan area layout in the site navigator but this is not what we edit...
      // we will edit a draft using the editor class in the next effect
      siteNavigator.setCurrentScanAreaLayout(scanAreaLayout);
     })
    }
  }, [siteNavigator, siteNavigator.currentScan]);

  // As a reaction to the current scan area layout changing (from above effect),
  // we now set up an editor and link up the cameras we have for the site
  useEffect(() => {
    if (siteNavigator.currentScanAreaLayout) {
      const editor = new ScanAreaLayoutEditor(siteNavigator.currentScanAreaLayout)
      setEditor(editor);
      siteNavigator.site.loadCamerasAsync().then((cameras) => {
        editor.layoutViewModel.scanAreaLayout.synchCameras(cameras ?? []);
      });
    } else {
      setEditor(undefined);
    }
  }, [siteNavigator.currentScanAreaLayout]);
  
  const handleSave = () => {
    if (editor) {
      siteBionicsApplication.service.saveScanAreaLayoutAsync(editor.layoutViewModel.scanAreaLayout);
      // migrating to new location
      // siteBionicsApplication.service.saveScanAreaLayoutAsync2(editor.layoutViewModel.scanAreaLayout);
      editor.commit();
    }
  };

  const handleRevert = () => {
    if (editor) {
      editor.reset();
    }
  };

  const handleDownload = () => {
    if (editor) {
      const blob = new Blob([JSON.stringify(getSnapshot(editor.layoutViewModel.scanAreaLayout))], { type: 'application/json' });
      const link = document.createElement('a');
      link.download = "Layout.json";
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  const handleUndo = () => {
    if (editor && editor.undoManager.canUndo) {
      editor.undoManager.undo();
    }
  };

  const handleRedo = () => {
    if (editor && editor.undoManager.canRedo)
      editor.undoManager.redo();
  };  

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      {editor &&
        <ScanAreaLayoutViewModelContext.Provider value={editor?.layoutViewModel}>
          <SelectionManagerContext.Provider value={selectionManager}>

            <Box component='div' >
              <ButtonGroup variant="contained">
                <Button onClick={handleDownload} startIcon={<DownloadIcon />} />
                <Button onClick={handleRevert} startIcon={<HistoryIcon />} />
                <Button onClick={handleSave} startIcon={<SaveIcon />} />
                <Button onClick={handleUndo} startIcon={<UndoIcon />} />
                <Button onClick={handleRedo} startIcon={<RedoIcon />} />
              </ButtonGroup>
            </Box>

            <Box component="div" flexGrow="1" overflow="hidden" display="flex" flexDirection="row">
              {/* left side accordian panel */}
              <Box component="div" minWidth="300px" width="300px" display="flex" flexDirection="column" overflow="auto">
                <ScanAreaAccordion siteNavigator={siteNavigator} />
                {editor?.layoutViewModel &&
                  <>
                    <AreaModelsAccordion layoutViewModel={editor.layoutViewModel} />
                    <CamerasAccordion siteNavigator={siteNavigator} />
                    <EntrancesAccordion layoutViewModel={editor.layoutViewModel} />
                    <TriggersAccordion layoutViewModel={editor.layoutViewModel} />
                    <RegionsAccordion layoutViewModel={editor.layoutViewModel} />
                    <WallsAccordion layoutViewModel={editor.layoutViewModel} />
                  </>
                }
              </Box>
              {/* central panel */}
              <Box component="div" flexGrow={1} display="flex" flexDirection="row" sx={{ background: "red" }}>
                {editor?.layoutViewModel.showCameraPane &&
                  <Box component="div" minWidth="70%" >
                    <SnapshotViewer siteNavigator={siteNavigator} layoutViewModel={editor.layoutViewModel} />
                  </Box>
                }
                <Box component="div" sx={{ display: "flex", flexgrow: 1, width: "100%", overflow: "hidden", background: "green" }} >
                  {siteNavigator.currentScan &&
                    <SiteViewerScene siteNavigator={siteNavigator} scanAreaLayout={editor.layoutViewModel.scanAreaLayout} showCameras={editor.layoutViewModel.showCameras}> 

                      {editor?.layoutViewModel.showEntrances &&
                        <EntrancesLayer layoutViewModel={editor.layoutViewModel} />}

                      {editor?.layoutViewModel.showTriggers &&
                        <TriggersLayer layoutViewModel={editor.layoutViewModel} />}

                      {editor?.layoutViewModel.showRegions &&
                        <RegionsLayer layoutViewModel={editor.layoutViewModel} />}
                      
                      {editor?.layoutViewModel.showWalls &&
                        <WallsLayer layoutViewModel={editor.layoutViewModel} />}

                      <GridLinesLayer size={15} interval={1} />

                    </SiteViewerScene>
                  }
                </Box>
              </Box>
              {/* right side property panel */}
              <Box component="div" width="300px" minWidth="300px" display="flex" flexDirection="column" overflow="auto">
                <SelectEntityPropertiesPane />
              </Box>
            </Box>
          </SelectionManagerContext.Provider>
        </ScanAreaLayoutViewModelContext.Provider>
      }
    </>
  )
})

export default Editor;