
import { makeObservable, observable } from "mobx";
import { ModelType } from "./ModelType";
import Pose from "./Pose";

export default class Model {
    @observable modelType: ModelType;
    @observable modePose: Pose;
    @observable modeScale: number;
    
    constructor(modelType: ModelType, modePose: Pose, modeScale: number) {
        this.modelType = modelType; 
        this.modePose = modePose;
        this.modeScale = modeScale;    
        makeObservable(this);
    }

    static fromJson(json: any) {
        return new Model(json.modelType, Pose.fromJson(json.modePose), json.modeScale);
    }

}
