import { FC } from "react";
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

interface ShowHideControlProps {
  show: boolean;
  enabled: boolean;
  onToggle: () => void;
}

const ShowHideToggle: FC<ShowHideControlProps> = ({ show, enabled, onToggle }) => {
    const handleClick = () => {
      if (enabled) {
        onToggle();
      }
    };

    let icon;
    if (!enabled) {
      icon = <CloseIcon color="disabled" />;
    } else {
      icon = show ? <VisibilityIcon fontSize="small"/> : <VisibilityOffIcon fontSize="small"/>;
    }

    return (
      <IconButton size="small" onClick={handleClick} disabled={!enabled}>
        {icon}
      </IconButton>
    );
}


export default ShowHideToggle;
