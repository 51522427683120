import React from 'react';
import { TextField, Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

interface AddressFormFieldsProps {
  control: any;
  errors: any;
  touchedFields: any;
  register: any;
  required: boolean;  // Indicates if the address is required (which kicks off additional validation)
}

const AddressFormFields: React.FC<AddressFormFieldsProps> = ({ control, errors, touchedFields, register, required }) => {
  const stateAbbreviations = [
    "", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME",
    "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA",
    "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];

  return (
    <>
      <TextField
        id="street1"
        label={"Street 1"}
        margin="dense"
        type="string"
        fullWidth
        variant="standard"
        required={required}
        {...register("street1", required ? { required: "Street 1 is required" } : {})}
        error={!!(touchedFields["street1"] && errors["street1"])}
        helperText={touchedFields["street1"] && errors.street1?.message}
      />

      <TextField
        id="street2"
        label={"Street 2"}
        margin="dense"
        type="string"
        fullWidth
        variant="standard"
        {...register("street2")}
      />

      <TextField
        id="city"
        label={"City"}
        margin="dense"
        type="string"
        fullWidth
        required={required}
        variant="standard"
        {...register("city", required ? { required: "City is required" } : {})}
        error={!!(touchedFields["city"] && errors["city"])}
        helperText={touchedFields["city"] && errors.city?.message}
      />

      <TextField
        id="state"
        label={"State"}
        margin="dense"
        type="string"
        fullWidth
        required={required}
        variant="standard"
        {...register("state", {
          required: required ? "State is required" : undefined,
          validate: (value: string) =>
            stateAbbreviations.includes(value.toUpperCase()) || "Invalid state abbreviation"
        })}
        error={!!(touchedFields["state"] && errors["state"])}
        helperText={touchedFields["state"] && errors.state?.message}
      />

      <TextField
        id="postalCode"
        label={"Postal Code"}
        margin="dense"
        type="string"
        fullWidth
        required={required}
        variant="standard"
        {...register("postalCode", {
          required: required ? "Postal Code is required" : undefined,
          pattern: {
            value: /^\d{5}(-\d{4})?$/, // Regex for 5-digit or 9-digit (ZIP+4) zip code
            message: "Postal code must be 5 digit or 5-4 digit"
          }
        })}
        error={!!(touchedFields["postalCode"] && errors["postalCode"])}
        helperText={touchedFields["postalCode"] && errors.postalCode?.message}
      />

      <TextField
        id="country"
        label={"Country"}
        margin="dense"
        type="string"
        fullWidth
        required={required}
        variant="standard"
        {...register("country", required ? { required: "Country is required" } : {})}
        error={!!(touchedFields["country"] && errors["country"])}
        helperText={touchedFields["country"] && errors.country?.message}
      />
    </>
  );
};

export default AddressFormFields;
