
import { action, makeObservable, observable } from "mobx";
import Site from "./Site";
import UserAccountRole from "./UserAccountRole";
import Address from "./Address";
import { RoleMap } from "./RoleMap";
import { siteCache } from "./Cache";
import { AccountInvite} from "./Invite";
import { SiteBionicsApplication } from "./SiteBionicsApplication";

export default class Account {
  @observable accountId: string;
  @observable id: string;
  @observable accountName: string;  
  @observable accountRoles: string[];
  @observable baseSiteRoles: string[];
  @observable accountCapabilities: string[];
  @observable baseSiteCapabilities: string[];
  @observable billingAddress: Address;
  
  @observable roleMap: RoleMap | null;
  
  @observable userAccountRoles?: UserAccountRole[];
  @observable accountInvites?: AccountInvite[];
  @observable sites?: Site[];

  constructor(
      accountId: string, 
      accountName: string, 
      accountRoles: string[] | null, 
      baseSiteRoles: string[] | null,
      accountCapabilities: string[] | null,
      baseSiteCapabilities: string[] | null,
      billingAddress: Address | null
  ) {
    this.accountId = accountId;
    this.id = accountId;
    this.accountName = accountName;
    this.accountRoles = accountRoles ? accountRoles : [];
    this.accountCapabilities = accountCapabilities ? accountCapabilities : [];
    this.baseSiteRoles = baseSiteRoles ? baseSiteRoles : [];
    this.baseSiteCapabilities = baseSiteCapabilities ? baseSiteCapabilities : [];
    this.billingAddress = billingAddress ? 
        new Address(billingAddress.street1, billingAddress.street2, billingAddress.country, billingAddress.city, billingAddress.state, billingAddress.postalCode) :
        new Address("", "", "", "", "", "");
    this.roleMap = null;
    makeObservable(this);
  }

  @action
  setAccountName(accountName: string) {
    this.accountName = accountName;
  }

  @action
  setSites(sites?: Site[]) {
      this.sites = sites;
  }

  @action
  setUserAccountRoles(userAccountRoles?: UserAccountRole[]) {
      this.userAccountRoles = userAccountRoles;
  }

  @action
  setAccountInvites(accountInvites?: AccountInvite[]) {
      this.accountInvites = accountInvites;
  }

  @action
  async loadSitesAsync(refresh: boolean = false): Promise<Site[] | undefined> {
    if (this.sites === undefined || refresh) {
      //let sites = await this.dataContext.service.fetchSiteListAsync(this);
      let sites = await siteCache.getList(this.accountId, () => SiteBionicsApplication.getInstance().service.fetchSiteListAsync(this));
      
      this.setSites(sites);
    }
    return this.sites;
  }

  @action
  async loadUserAccountRolesAsync(refresh: boolean = false, userId: string = ""): Promise<UserAccountRole[] | undefined> {
    if (this.userAccountRoles === undefined || refresh) {
      let userAccountRoles = await SiteBionicsApplication.getInstance().service.fetchUserAccountRoles(this.accountId);

      if (userId && userId !== "") {
        const userIndex = userAccountRoles.findIndex(role => role.userId === userId);
        if (userIndex !== -1) {
          const [userRole] = userAccountRoles.splice(userIndex, 1);
          userAccountRoles.unshift(userRole);
        }
      }

      this.setUserAccountRoles(userAccountRoles);
    }

    return this.userAccountRoles;
  }

  @action
  async loadAccountInvites(refresh: boolean = false): Promise<AccountInvite[] | undefined> {
    if (this.accountInvites === undefined || refresh) {
      let accountInvites = await SiteBionicsApplication.getInstance().service.fetchAccountInvites(this.accountId);

      this.setAccountInvites(accountInvites);
    }

    return this.accountInvites;
  }
  
  
  hasAccountRole(accountRole : string) : boolean {
    return (this.accountRoles && this.accountRoles?.indexOf(accountRole) !== -1) ? true : false;    
  }

  hasAccountCapability(capability: string) : boolean {
    return (this.accountCapabilities && this.accountCapabilities?.indexOf(capability) !== -1) ? true : false;
  }

  haseBaseSiteRole(accountRole : string) : boolean {
    return (this.baseSiteRoles && this.baseSiteRoles?.indexOf(accountRole) !== -1) ? true : false;
  }

  hasBaseSiteCapability(capability: string) : boolean {
    return (this.baseSiteCapabilities && this.baseSiteCapabilities?.indexOf(capability) !== -1) ? true : false;
  }

  @action
  updateData(accountName: string, billingAddress: Address) {
    this.accountName = accountName;
    this.billingAddress = billingAddress;
  }

  @action
  updateFrom(data: Account) {
    this.accountName = data.accountName;
    this.billingAddress.updateFrom(data.billingAddress);    
  }

  @action
  setRoleMap(roleMap: RoleMap | null) {
    this.roleMap = roleMap;
  }


  roleMapPromise : Promise<RoleMap> | null = null;

  async loadRoleMapAsync(refresh: boolean = false) : Promise<RoleMap> {

    if (refresh) {
      this.setRoleMap(null);
    }

    if (this.roleMap != null) {
      return Promise.resolve(this.roleMap);
    }

    if (this.roleMapPromise != null) {
      return this.roleMapPromise;
    }

    // @ts-ignore
    this.roleMapPromise = SiteBionicsApplication.getInstance().service.fetchRoleMap(this.accountId).then((roleMap: RoleMap) => {
      this.setRoleMap(roleMap);
      this.roleMapPromise = null;

      return this.roleMap;
    });    
    
    return this.roleMapPromise!;
  }

  clone() : Account {
    return new Account(this.accountId, this.accountName, this.accountRoles, this.baseSiteCapabilities.slice(), this.accountCapabilities.slice(), this.baseSiteCapabilities.slice(), this.billingAddress.clone());
  }  
}
