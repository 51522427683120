import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"

import { Box, Slider } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSite, useSitePageBreadcrumbs } from "./SitePage";
import OccupancyChart from "../charts/OccupancyChart";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TrackedObjectEvent from "../models/TrackedObjectEvent";
import SiteViewerScene from "../components-3d/SiteViewerScene";
import { Vector3 } from "@babylonjs/core";
import HeatmapLayer from "../components-3d/HeatmapLayer";
import HeatmapEntry from "../models/HeatmapEntry";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";
import ScanAreaLayout from "../models/layout/ScanAreaLayout";

const Heatmap : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const siteNavigator = useSite().siteNavigator;
  const breadcrumbs = useSitePageBreadcrumbs("Heatmap");
  const [date, setDate] = useState<Dayjs>(dayjs().startOf('day'));
  const [heatmapEntries, setHeatmapEntries] = useState<HeatmapEntry[]>([]);
  const [clipValue, setClipValue] = useState<number>(1);
    
  useEffect(() => {
    siteNavigator.site.loadAreasAsync().then((scanAreas) => {
      if (scanAreas?.length) {
          siteNavigator.setCurrentScanArea(scanAreas[0]);
          siteNavigator.currentScan?.loadScanAreaLayoutAsync().then((scanAreaLayout) => {
            siteNavigator.setCurrentScanAreaLayout(scanAreaLayout);
          });
      }
    });
    siteNavigator.site.loadCamerasAsync().then((cameras) => {
      if (cameras?.length) {
        siteNavigator.setCurrentCameraById(cameras[0].cameraId);
      }
    });
  }, []);



  useEffect(() => {
    siteBionicsApplication.service.fetchHeatmapDataAsync(siteNavigator.site,
            date, date.add(1, 'day')).then((heatmapEntries) => {
              setHeatmapEntries(heatmapEntries);
          });
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" overflow="clip" display="flex" height="100%" flexDirection="column">

        <Box component="div" display="flex" width="100%" flexDirection="row" >
          <BreadcrumbBar breadcrumbs={breadcrumbs} />
          <Box component="div" sx={{ flexGrow: 1 }} />
        </Box>

        <Box component="div" display="flex" width="100%" flexDirection="row" >
          <DatePicker 
            label="Date"
            value={date}
            onChange={(newValue) => {
              setDate(newValue!);
            }}
          />
          <Slider sx={{ width: "200px", marginLeft: "20px" }} 
            value={clipValue}
            max={1440}
            min={0} 
            step={1} 
            valueLabelDisplay="auto"
            onChange={(e, v) => {setClipValue(v as number)}}/>
        </Box>

        <Box component="div"  flexGrow={1}>
          {siteNavigator.currentScanAreaLayout &&
            <SiteViewerScene siteNavigator={siteNavigator} scanAreaLayout={siteNavigator.currentScanAreaLayout} showCameras={false}>
              <HeatmapLayer heatmapEntries={heatmapEntries} clipValue={clipValue}/>
            </SiteViewerScene>
          }
        </Box>
      </Box>
    </LocalizationProvider>
  )
})

export default Heatmap;