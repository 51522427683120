import React, { useState, useEffect } from 'react';
import Hub from "../models/Hub";
import Site from "../models/Site";
import { hubCache } from '../models/Cache';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import { observer } from "mobx-react-lite";
import { Tooltip } from '@mui/material';

interface CachedHubControlProps {
  site: Site;
  hubId: string;
}

const CachedHubControl: React.FC<CachedHubControlProps> = ({ site, hubId }) => {

  const [hub, setHub] = useState<Hub | undefined | null>(undefined);

  const siteBionicsApplication = useSiteBionicsApplication();

  useEffect(() => {
    const fetchData = async () => {
      let hub = await hubCache.getObject(hubId, () => siteBionicsApplication.service.fetchHubAsync(site, hubId));

      setHub(hub);
    };

    fetchData();
  });

  if (hub === undefined) {
    return (<Tooltip title={hubId}><span>Loading...</span></Tooltip>)
  } else if (hub === null) {
    return (<span>{hubId}</span>);
  } else {
    return (<Tooltip title={hubId}><span>{hub.name}</span></Tooltip>)
  }
};

export default observer(CachedHubControl);
