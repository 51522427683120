import { action, makeObservable, observable } from "mobx";

// Pagestate is a simple structure that can store state data for the app. Its intended use
// is to store state data as key/value pairs scoped to a "page".  This allows for page1 to have state
// independent of page2. It allows for "sticky" settings the UX
//
// NOTE: Page state can be shared across pages. The notion of a "page" is simply conventional. Two
// "web-pages" can share the same page name.

export default class PageState {
  @observable state: Map<string, Map<string, any>> = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  setPageState(page: string, key: string, value: any) {
    if (!this.state.has(page)) {
      this.state.set(page, new Map());
    }
    this.state.get(page)!.set(key, value);
  }

  getPageState(page: string, key: string, defaultValue: any) {
    return this.state.get(page)?.get(key) ?? defaultValue;
  }
}
