import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Box, Backdrop, CircularProgress, Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography, Menu, MenuItem, IconButton, Divider } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import { ScanState } from "../models/ScanState";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import NoAccess from "../components/NoAccess";
import TitleToolbar from "../components/TitleToolbar";
import Scan from "../models/Scan";
import ScanArea from "../models/ScanArea";
import SiteNavigator from "../models/SiteNavigator";
import ScanAreaDialog from "../dialogs/ScanAreaDialog";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import Confirm from "../components/Confirm";
import { NavLink } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import ScanIndicator from "../components/ScanIndicator";
import ActionMenu from "../components/ActionMenu";

const ScansList : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const breadcrumbs = useSitePageBreadcrumbs("Scans");
  const site = useSite();
  const siteNavigator = site.siteNavigator;

  const [creatingScanArea, setCreatingScanArea] = useState(false);

  const [updateScanArea, setUpdateScanArea] = useState<ScanArea | null>(null);
  const [deleteScanArea, setDeleteScanArea] = useState<ScanArea | null>(null);
  const [deleteScan, setDeleteScan] = useState<Scan | null>(null);

  const [busy, setBusy] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
      let loadedScans: Scan[] = [];
      if (!site.hasSiteCapability("ViewSite")) return;
      
      siteNavigator.site.loadAreasAsync().then((scanAreas) => {
        scanAreas?.forEach((scanArea) => {
          scanArea.loadScansAsync()
          });
      })
  }, []);

  
  const handleDeleteScan = () => {
    setBusy(true);
    siteBionicsApplication.service.deleteScanAsync(deleteScan!).then((result) => {
      if (result === false) {
        siteBionicsApplication.setLastError('Unable to delete scan while submitted or processing.', "error");
        setDeleteScan(null);
        setBusy(false);
        return;
      }

      deleteScan!.area.scans = deleteScan!.area.scans!.filter(item => item.scanId !== deleteScan!.scanId);

      setDeleteScan(null);
      setBusy(false);
    });
  }


  const handleDeleteScanArea = () => {
    setBusy(true);
    siteBionicsApplication.service.deleteScanAreaAsync(site, deleteScanArea!.scanAreaId).then((result) => {
      if (result === false) {
        siteBionicsApplication.setLastError('Unable to delete scan area while scan is submitted or processing.', "error");
        setDeleteScanArea(null);
        setBusy(false);
        return;
      }
      
      let index = site.scanAreas!.findIndex(obj => deleteScanArea!.scanAreaId === obj.scanAreaId);

      // Remove it
      if (index !== -1) {
        site.scanAreas!.splice(index!, 1);
      }

      setDeleteScanArea(null);
      setBusy(false);
    });
  }

  const handleUpdateScanArea = (name: string, description: string) => {

    setBusy(true);
    siteBionicsApplication.service.updateScanAreaAsync(site, updateScanArea!.scanAreaId, name, description).then(() => {
      updateScanArea!.setData(name, description);
      setUpdateScanArea(null);
      setBusy(false);
    });    
  }     

  const handleCreateScanArea = (name: string, description: string) => {

    setBusy(true);
    siteBionicsApplication.service.createScanAreaAsync(site, name, description).then((scanArea: ScanArea) => {
      site.scanAreas!.push(scanArea);
      setCreatingScanArea(false);
      setBusy(false);
    });    
  }     

  const handleSubmitScan = (scan: Scan, forceResubmit: boolean) => {
    setBusy(true);
    siteBionicsApplication.service.submitScanProcessingJob(scan, forceResubmit).then((result: boolean) => {
      if (result === true) {
        siteBionicsApplication.setLastError('Scan job submitted', "success");
        scan.scanState = ScanState.Submitted;
      } else {
        siteBionicsApplication.setLastError('Unable to submit scan job. Job already submitted.', "error");
      }

      setBusy(false);
    });
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, scanArea: ScanArea) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuEditScanArea = (scanArea: ScanArea) => {
    setUpdateScanArea(scanArea);
    handleMenuClose();
  };

  const handleMenuDeleteScanArea = (scanArea: ScanArea) => {
    setDeleteScanArea(scanArea);
    handleMenuClose();
  };
  
  return (
    <>

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleToolbar title="Scan Areas" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
        <ActionMenu>
          <MenuItem onClick={() => setCreatingScanArea(true)}>Add Scan Area...</MenuItem>
        </ActionMenu>        
      </TitleToolbar> 


      <Box component="div" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>

        {siteNavigator?.site.scanAreas?.map((scanArea) => (
          <div key={scanArea.scanAreaId}>
            <Box component="div" sx={{height: "10pt"}}/>
            
            <TitleToolbar title={scanArea.scanAreaName}>
              <ActionMenu>
                  <MenuItem onClick={() => handleMenuEditScanArea(scanArea)}>Edit Scan Area...</MenuItem>
                  <MenuItem onClick={() => handleMenuDeleteScanArea(scanArea)}>Delete Scan Area...</MenuItem>
                </ActionMenu>              
            </TitleToolbar>
            
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow key="scansHeader">
                    <TableCell align="left">Version</TableCell>                                       
                    <TableCell align="left">Time</TableCell>
                    <TableCell align="left">Scan Id</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Processing Status</TableCell>
                    <TableCell align="left"># Models</TableCell>
                    <TableCell align="left"># Camera Calibrations</TableCell>
                    
                  </TableRow>
                </TableHead>

                <TableBody>
                  {scanArea.scans?.map((scan) => (
                      <TableRow key={scan.scanId}>                        
                        <TableCell align="left">{scan.scanVersion}</TableCell>
                        <TableCell align="left">{scan.scanTime.toLocaleDateString() + ' ' + scan.scanTime.toLocaleTimeString()}</TableCell>
                        <TableCell align="left">
                          <NavLink style={{ color: 'white' }} to={`/accounts/${scanArea.site.account.accountId}/sites/${scanArea.site.siteId}/scans/${scan.scanId}`}>{scan.scanId}</NavLink>
                        </TableCell>
                        <TableCell align="left">
                          <ActionMenu>
                            <MenuItem onClick={() => setDeleteScan(scan)}>Delete Scan...</MenuItem>
                            <MenuItem onClick={() => handleSubmitScan(scan, false)}>Resubmit Scan...</MenuItem>                           
                            {siteBionicsApplication.isSystemAdministrator &&     
                              <MenuItem sx={{ borderTop: '1px solid gray' }} onClick={() => handleSubmitScan(scan, true)}>Force Resubmit Scan...</MenuItem>                              
                             }
                          </ActionMenu>
                        </TableCell>
                        <TableCell align="left"><ScanIndicator scan={scan} /></TableCell>
                        <TableCell align="left">{Object.keys(scan.models || {}).length}</TableCell>
                        <TableCell align="left">{Object.keys(scan.cameraCalibrations || {}).length}</TableCell>


                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </Box>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      {deleteScan &&
        <Confirm
          title="Delete Scan"
          confirmationText={`Are you sure you want to delete the scan?`}
          confirmButtonLabel="Delete"
          onClose={() => setDeleteScan(null)}
          onConfirm={handleDeleteScan}
        />
      }
      {deleteScanArea &&
        <Confirm
          title="Delete Scan Area"
          confirmationText={`Are you sure you want to delete ${deleteScanArea.scanAreaName}?`}
          confirmButtonLabel="Delete"
          onClose={() => setDeleteScanArea(null)}
          onConfirm={handleDeleteScanArea}
        />
      }

      {creatingScanArea &&
        <ScanAreaDialog
          title="Add Scan Area"
          name=""
          description=""
          onSave={handleCreateScanArea}
          onClose={() => setCreatingScanArea(false)}
        />
      }

      {updateScanArea &&
        <ScanAreaDialog
          title="Edit Scan Area"
          name={updateScanArea!.scanAreaName}
          description={updateScanArea!.scanAreaDescription}
          onSave={handleUpdateScanArea}
          onClose={() => { setUpdateScanArea(null)}}
        />
      }
    </>
  )
})

export default ScansList;

