import React, { useState, useEffect, useCallback } from 'react';
import { Box, IconButton, CircularProgress, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { Pause, PlayArrow, SkipPrevious, SkipNext, Fullscreen } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Camera from "../models/Camera";

interface CameraCarouselProps {
  accountId: string;
  siteId: string;
  cameras: Camera[];
  small?: boolean;
  showFullScreenButton?: boolean; // Prop to control the visibility of the full screen button
  initialImageIndex?: number; // Prop for the initial image index
  startPaused?: boolean; // Prop to determine if the carousel should start paused
}

const CameraCarousel: React.FC<CameraCarouselProps> = ({
  accountId,
  siteId,
  cameras,
  small = false,
  showFullScreenButton = false,
  initialImageIndex = 0,
  startPaused = false
}) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const [currentIndex, setCurrentIndex] = useState(initialImageIndex);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [paused, setPaused] = useState(startPaused);

  const fetchImageUrl = useCallback(async (accountId: string, siteId: string, cameraId: string) => {
    setLoading(true);
    try {
      const imageUrl = await siteBionicsApplication.service.fetchCameraImageUrl(accountId, siteId, cameraId);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching image URL:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchImageUrl(accountId, siteId, cameras[currentIndex].id);
  }, [currentIndex, fetchImageUrl, cameras]);

  useEffect(() => {
    if (!paused && !loading) {
      const timer = setTimeout(() => {
        console.log("Timer");
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cameras.length);
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [paused, loading, cameras.length]);

  const handleNext = () => {
    setPaused(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cameras.length);
  };

  const handlePrevious = () => {
    setPaused(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + cameras.length) % cameras.length);
  };

  const handlePausePlay = () => {
    setPaused((prevPaused) => !prevPaused);
  };

  const navigate = useNavigate();

  const handleFullScreen = () => {
    navigate(`/accounts/${accountId}/sites/${siteId}/video?camera=${cameras[currentIndex].cameraId}`);
  };

  return (
    <Box component="div" textAlign="center" sx={{ width: '100%', height: '100%' }}>
      <Typography fontSize={small ? "x-small" : "medium"}>{cameras[currentIndex].cameraName} ({currentIndex + 1} of {cameras.length})</Typography>
      <Box component="div" position="relative" display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', height: 'calc(100% - 40px)', '&:hover .controls': { opacity: 1 } }}>
        {loading && <CircularProgress />}
        {!loading && imageUrl === "" &&
          <Typography>Unable to fetch image</Typography>
        }
        {!loading && imageUrl !== "" &&
          <Box component="div" position="relative" sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={imageUrl || ''} alt={cameras[currentIndex].cameraName} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
            <IconButton onClick={handlePrevious} disabled={loading} className="controls" sx={{ position: 'absolute', left: '5%', top: '50%', transform: 'translateY(-50%)', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', opacity: 0, transition: 'opacity 0.3s' }}>
              <SkipPrevious sx={{ fontSize: small ? 20 : 40 }} />
            </IconButton>
            <IconButton onClick={handlePausePlay} disabled={loading} className="controls" sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', opacity: 0, transition: 'opacity 0.3s' }}>
              {paused ? <PlayArrow sx={{ fontSize: small ? 20 : 40 }} /> : <Pause sx={{ fontSize: small ? 20 : 40 }} />}
            </IconButton>
            <IconButton onClick={handleNext} disabled={loading} className="controls" sx={{ position: 'absolute', right: '5%', top: '50%', transform: 'translateY(-50%)', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', opacity: 0, transition: 'opacity 0.3s' }}>
              <SkipNext sx={{ fontSize: small ? 20 : 40 }} />
            </IconButton>
            {showFullScreenButton && (
              <IconButton onClick={handleFullScreen} className="controls" sx={{ position: 'absolute', right: '5%', bottom: '5%', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', opacity: 0, transition: 'opacity 0.3s' }}>
                <Fullscreen sx={{ fontSize: small ? 20 : 40 }} />
              </IconButton>
            )}
          </Box>
        }
      </Box>
    </Box>
  );
};

export default CameraCarousel;