import React, { useEffect, useState } from 'react';
import GeneralCard from './GeneralCard';
import { Radar } from '@mui/icons-material';
import Account from '../models/Account';
import Site from '../models/Site';
import { observer } from "mobx-react";
import Scan from '../models/Scan';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ScansCardProps {
  account: Account;
  site: Site;
}

const ScansCard: React.FC<ScansCardProps> = observer(({ account, site }) => {
  const [recentScans, setRecentScans] = useState<Scan[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadScans = async () => {
      await site.loadAreasAsync();
      const scanAreas = site.scanAreas || [];
      await Promise.all(scanAreas.map(scanArea => scanArea.loadScansAsync()));

      const allScans = scanAreas.flatMap(scanArea => scanArea.scans).filter((scan): scan is Scan => scan !== undefined);
      const sortedScans = allScans.sort((a, b) => new Date(b.scanTime).getTime() - new Date(a.scanTime).getTime());
      setRecentScans(sortedScans.slice(0, 3));
    };

    loadScans();
  }, [site]);

  return (
    <GeneralCard
      title="Scans"
      icon={<Radar style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}
      moreLink={`/accounts/${account.accountId}/sites/${site.siteId}/scans`}
    >
      <Box component="div" display="flex" flexDirection="column" sx={{ gap: "10px" }}>
        <Typography>Recent Scans:</Typography>
        {recentScans.length > 0 ? (
          recentScans.map(scan => (
            <Typography
              key={scan.scanId}
              onClick={() => navigate(`/accounts/${account.accountId}/sites/${site.siteId}/scans/${scan.scanId}`)}
              sx={{ cursor: 'pointer', color: 'primary.main', textDecoration: 'underline' }}
            >
              V{scan.scanVersion} - {new Date(scan.scanTime).toLocaleString()}
            </Typography>
          ))
        ) : (
          <Typography>Loading recent scans...</Typography>
        )}
      </Box>
    </GeneralCard>
  );
});

export default ScansCard;
