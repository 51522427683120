import {FunctionComponent, useState, useEffect} from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import Areas from "./Areas";
import Cameras from "./Cameras";
import HowItWorks from "./HowItWorks";
import Hubs from "./Hubs";
import Integrations from "./Integrations";
import Profile from "./Profile";
import Scans from "./Scans";
import ScansList from "./ScansList";
import SiteCards from "./SiteCards";
import Sites from "./Sites";
import Video from "./Video";
import Viewer from "./Viewer";
import ErrorPage from "./ErrorPage";
import Console from "./Console";
import Accounts from "./Accounts";
import AccountsList from "./AccountsList";
import AccountInfo from "./AccountInfo";
import AccountCards from "./AccountCards";
import SitesList from "./SitesList";
import SiteSnapshots from "./SiteSnapshots";
import SiteShifts from "./SiteShifts";
import SiteEvents from "./SiteEvents";
import SiteInfo from "./SiteInfo";
import SitePage from "./SitePage";
import Tracking from "./Tracking";
import Management from "./management/Management";
import ProvisionedHubs from "./management/ProvisionedHubs";
import AllUsers from "./management/AllUsers";
import Developer from "./debug/Developer";
import Debug from "./debug/Debug";
import ManageVideo from "./management/ManageVideo";
import AccountUsers from "./AccountUsers";
import SiteUsers from "./SiteUsers";
import Invite from "./Invite";
import AccountPage from "./AccountPage";
import { AuthenticationResult} from '@azure/msal-browser';
import Welcome from "./Welcome";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useMsal } from '@azure/msal-react';
import Dashboard from "./Dashboard";
import Heatmap from "./Heatmap";
import Editor from "./Editor";
import WithTitle from "../components/WithTitle";
import Hardware from "./Hardware";
import { useIsAuthenticated } from '@azure/msal-react';
import { observer } from "mobx-react"
import { Backdrop, CircularProgress, GlobalStyles } from "@mui/material";
import { AccountInfo as MsalAccountInfo } from "@azure/msal-common";
import UserInfo from '../models/UserInfo';
import Interstitial from "./Interstitial";
import { TermsOfServiceData } from "./ToS";
import ProtectedElement from "../components/ProtectedElement";
import ScanPage from "./ScanPage";

const App : FunctionComponent = observer(() => {    
    
    const siteBionicsApplication = useSiteBionicsApplication(); 
    
    //const isMsalAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [interstialData, setInterstialData] = useState<{type: string, user: null | UserInfo, isSystemAdministrator: boolean}>({type: "none", user: null, isSystemAdministrator: false });

    const userInfoFromMsal = (msalAccountInfo: MsalAccountInfo) => {
        let first = "";
        let last = "";
        if (msalAccountInfo.name) {
            const nameParts = msalAccountInfo.name?.split(' ');
            if (nameParts.length === 1) {
                last = nameParts[0];
            } else {
                first = nameParts[0];
                last = nameParts.slice(1).join(' ');
            }
        }
        
        return new UserInfo(msalAccountInfo.localAccountId, msalAccountInfo.username, first, last, TermsOfServiceData.version, new Date());
    }

    const acceptTerms = (firstName: string, lastName: string) => {

        // Update the user data
        
        // For a new user we get an updated first/last name
        if (interstialData.type === "new") {
            interstialData.user!.firstName = firstName;
            interstialData.user!.lastName = lastName;
        }

        // We've seen the latest TOS
        interstialData.user!.tosVersion = TermsOfServiceData.version;
        interstialData.user!.tosDate = new Date();
        
        // Update the user data on the backend
        setLoading(true);
        const result = siteBionicsApplication.service.upsertUserInfo(interstialData.user!).then((result: boolean) => {

            setLoading(false);

            if (!result) return;

            siteBionicsApplication.setMeExtended(interstialData.user!, interstialData.isSystemAdministrator);
            
            setInterstialData({type: "none", user: null, isSystemAdministrator: false});
        });
        

    }
/*
    useEffect(() => {
        if (!siteBionicsApplication.me && isMsalAuthenticated && accounts && accounts.length > 0) {
            setAccount(accounts[0]);
        }
    }, [accounts, isMsalAuthenticated]);
*/
    const setAccount = async (account: any) => {
        const accountId = account.localAccountId;
        const isSystemAdministrator = account.idTokenClaims?.roles?.includes('SiteBionics.SystemAdministrator') ?? false;
        const userInfo = await siteBionicsApplication.service.fetchUserInfo(accountId);                                        

        // If no user we need to create one
        if (!userInfo) {
            setInterstialData({type: "new", user: userInfoFromMsal(account), isSystemAdministrator: isSystemAdministrator })                    
        } else if ((userInfo.tosVersion ?? 0) < TermsOfServiceData.version) {                    
            setInterstialData({type: "tos", user: userInfoFromMsal(account), isSystemAdministrator: isSystemAdministrator });
        } else {
            siteBionicsApplication.setMeExtended(userInfo, isSystemAdministrator);
        }
    }

    useEffect(() => {

        
        const process = async () => {
            const cachedUserId = localStorage.getItem('userId');
            if (cachedUserId && localStorage.getItem('sbjwt')) {
                // This is a test user. This will need to be refactored when adding tests for the interstitial data.
                siteBionicsApplication.service.coreAuthType = "sbjwt";
                const userInfo = await siteBionicsApplication.service.fetchUserInfo(cachedUserId);
                if (userInfo) {
                    siteBionicsApplication.setMeExtended(userInfo, false);
                    setIsAuthenticating(false);
                }
            } else {
                
                const response = await instance.handleRedirectPromise();
    
                if (response) {            
                    siteBionicsApplication.service.coreAuthType = "msal";
                    await setAccount(response.account);

                    setIsAuthenticating(false);
                } else {
                    if (accounts && accounts.length >= 1)
                    {
                        await setAccount(accounts[0]);
                        setIsAuthenticating(false);
                    }
                    setIsAuthenticating(false);
                }            
            }
        };

        if (!siteBionicsApplication.me) {
            process();
        }
        
    }, [accounts]);
    
    
    
    
    // While loading information we display nothing    
    if (isAuthenticating) {
        return (<></>);
    }

    // If we need to display the interstitial screens...
    if (interstialData.type !== "none") {        
        const interstialRouter: any = createBrowserRouter([
            {
                path: "/", element: <Console modal={true}/>, errorElement: <WithTitle title="Error" children={<ErrorPage/>} />, children: [
                    { index: true, element: <WithTitle title="Welcome" children={<Interstitial firstName={interstialData.user!.firstName} lastName={interstialData.user!.lastName} onAccept={acceptTerms} newUser={interstialData.type === "new"}/>} /> },
                    { path: "*", element: <WithTitle title="Welcome" children={<Interstitial firstName={interstialData.user!.firstName} lastName={interstialData.user!.lastName} onAccept={acceptTerms} newUser={interstialData.type === "new"}/>} /> }
                ]
            }
        ])
    
        return (<>
            {loading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open><CircularProgress color="inherit" /></Backdrop>
            }
            <RouterProvider router={interstialRouter!} />
        </>);
    }

    if (siteBionicsApplication.me === undefined) {
        const unAuthRouter: any = createBrowserRouter([
            { 
              path: "/", element: <Console/>, errorElement: <WithTitle title="Error" children={<ErrorPage />} />, children: [
                { index: true, element: <WithTitle title="Welcome" children={<Welcome />} /> },
                { path: "invite", element: <WithTitle title="Invite" children={<Invite />} /> },
                { path: "*", element: <WithTitle title="Welcome" children={<Welcome />} /> },
              ]
            },
        ]);
        return (<>
            <RouterProvider router={unAuthRouter!} />
        </>);
    }

    const authRouter = createBrowserRouter([
        { 
            path: "/", element: <Console />, errorElement: <WithTitle title="Error" children={<ErrorPage />} />, children: [
            { index: true, element: <WithTitle title="Account List" children={<AccountsList />} /> },
            { path: "management", element: <Management />, children: [
                { index: true, element: <ProtectedElement title="Provisioned Hubs" element={<ProvisionedHubs />} sysAdmin={true} /> },
                { path: "provisionedhubs", element: <ProtectedElement title="Provisioned Hubs" element={<ProvisionedHubs />} sysAdmin={true} /> },
                { path: "users", element: <ProtectedElement title="All Users" element={<AllUsers />} sysAdmin={true} /> },
                ]
            },
            { path: "debug", element: <Debug />, children: [
                { path: "developer", element: <ProtectedElement title="Developer" element={<Developer />} sysAdmin={true} /> },
                ]
            },
            { path: "profile", element: <WithTitle title="Profile" children={<Profile />} /> },
            { path: "settings", element: <WithTitle title="Settings" children={<Settings />} /> },
            { path: "howitworks", element: <WithTitle title="How It Works" children={<HowItWorks />} /> },
            { path: "invite", element: <WithTitle title="Invite" children={<Invite />} /> },
            { path: "accounts", element: <Accounts />, children: [
                { index: true, element: <WithTitle title="Accounts" children={<AccountsList />} /> },
                { path: ":accountId", element: <AccountPage />, children: [
                    { index: true, element: <WithTitle context="account" children={<AccountCards />} /> },
                    { path: "info", element: <WithTitle title="Account Info" context="account" children={<AccountInfo />} /> },
                    { path: "users", element: <ProtectedElement title="Account Users" context="account" element={<AccountUsers />} accountCapability="ManageAccountUsers" /> },
                    { path: "sites", element: <Sites />, children: [
                        { index: true, element: <WithTitle title="Sites" context="account" children={<SitesList />} /> },
                        { path: ":siteId", element: <SitePage />, children: [
                            { index: true, element: <ProtectedElement context="site" element={<SiteCards />} siteCapability="ViewSite" /> },
                            { path: "snapshots", element: <ProtectedElement title="Snapshots" context="site" element={<SiteSnapshots />} siteCapability="ViewSite" /> },                            
                            { path: "shifts", element: <ProtectedElement title="Shifts" context="site" element={<SiteShifts />} siteCapability="UpdateSite" /> },
                            { path: "events", element: <ProtectedElement title="Events" context="site" element={<SiteEvents />} siteCapability="UpdateSite" /> },
                            { path: "viewer", element: <ProtectedElement title="Viewer" context="site" element={<Viewer />} siteCapability="ViewSite" /> },
                            { path: "editor", element: <ProtectedElement title="Editor" context="site" element={<Editor />} siteCapability="UpdateSite" /> },
                            { path: "video", element: <ProtectedElement title="Video" context="site" element={<Video />} siteCapability="ViewSite" /> },
                            { path: "tracking", element: <ProtectedElement title="Tracking" context="site" element={<Tracking />} siteCapability="ViewSite" /> },
                            { path: "scans", element: <Scans/>, children: [
                                { index: true, element: <WithTitle title="Scans" context="site" children={<ScansList />} /> },
                                { path: ":scanId", element: <ProtectedElement title="ScanPage" context="site" element={<ScanPage/>} siteCapability="ViewSite" />}
                            ]},
                            { path: "areas", element: <ProtectedElement title="Areas" context="site" element={<Areas />} siteCapability="ViewSite" /> },
                            { path: "hardware", element: <ProtectedElement title="Hardware" context="site" element={<Hardware />} siteCapability="UpdateSite" /> },
                            { path: "integrations", element: <ProtectedElement title="Integrations" context="site" element={<Integrations />} siteCapability="ViewSite" /> },
                            { path: "users", element: <ProtectedElement title="Site Users" context="site" element={<SiteUsers />} siteCapability="ManageSiteUsers" /> },
                            { path: "info", element: <ProtectedElement title="Site Info" context="site" element={<SiteInfo />} siteCapability="ViewSite" /> },
                            { path: "dashboard", element: <ProtectedElement title="Dashboard" context="site" element={<Dashboard />} siteCapability="ViewSite" /> },
                            { path: "heatmap", element: <ProtectedElement title="Heatmap" context="site" element={<Heatmap />} siteCapability="ViewSite" /> },
                            { path: "managevideo", element: <ProtectedElement title="Manage Video" context="site" element={<ManageVideo />} sysAdmin={true} /> }
                            ]
                        },
                        ]
                    },
                    ]
                }
                ]
            }
            ]
        }
        ]);
    
    return (<>
        <GlobalStyles
            styles={{
            /* For WebKit browsers */
            '::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#1E1E1E',
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: '#121212',
            },
            /* For Firefox */
            '*': {
                scrollbarWidth: 'thin',
                scrollbarColor: '#1E1E1E #121212',
            },
            }}
        />
        <RouterProvider router={authRouter!} />
    </>);

});

export default App;
