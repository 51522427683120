import { observer } from "mobx-react";
import { FunctionComponent, useEffect, useState } from "react";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { Tooltip, Backdrop, CircularProgress, Tabs, IconButton, Tab, Checkbox, MenuItem, Toolbar, Typography, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSitePageBreadcrumbs } from "./SitePage";
import CachedUserControl from "./CachedUserControl";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import UserSiteRole from "../models/UserSiteRole";
import {SiteInvite} from "../models/Invite";
import RoleDialog from "../dialogs/RoleDialog";
import Confirm from "../components/Confirm";
import {useSite} from "./SitePage";
import TitleToolbar from "../components/TitleToolbar";
import ActionMenu from "../components/ActionMenu";
import { Lock } from '@mui/icons-material';

const SiteUsers: FunctionComponent = observer(() => {

  const site = useSite();
  const siteBionicsApplication = useSiteBionicsApplication();

  const [selectedUserSiteRole, setSelectedUserSiteRole] = useState<UserSiteRole | undefined>(undefined);
  const [selectedSiteInvite, setSelectedSiteInvite] = useState<SiteInvite | undefined>(undefined);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);

  const [busy, setBusy] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedUserSiteRole(undefined);
    setSelectedSiteInvite(undefined);
    setTabValue(newValue);
  };

  const breadcrumbs = useSitePageBreadcrumbs("Team Members");
  
  useEffect(() => {

    if (!site.hasSiteCapability("ManageSiteUsers")) return;
    
    site.loadUserSiteRolesAsync(false, siteBionicsApplication.me?.id);

    site.loadSiteInvitesAsync();

    site.account.loadUserAccountRolesAsync(false, siteBionicsApplication.me?.id);

    site.account.loadAccountInvites();

    site.account.loadRoleMapAsync(); 
  });  

  const handleAdd = (userId: string, requiresMatch: boolean, accountRoles: string[], siteRoles: string[]) => {

    setBusy(true);
    if (userId.indexOf("@") !== -1) {
      siteBionicsApplication.service.createSiteInvitation(site.account.accountId, site.siteId, siteRoles, userId, requiresMatch).then(() => {
        site.loadSiteInvitesAsync(true);
        setAdding(false);
        setBusy(false);
      });
    } else {
      let newUSR = new UserSiteRole(site.account.accountId, site.siteId, userId, siteRoles);
      siteBionicsApplication.service.upsertUserSiteRoles(newUSR.accountId, newUSR.siteId, newUSR).then(() => {

        site.userSiteRoles!.push(newUSR);

        setAdding(false);
        setBusy(false);
      });    
    }
  } 

  const handleEdit = (userId: string, requiresMatch: boolean, accountRoles: string[], siteRoles: string[]) => {
    setBusy(true);
    if (selectedUserSiteRole) {
      let newUSR = new UserSiteRole(site.account.accountId, site.siteId, selectedUserSiteRole!.userId, siteRoles);
      siteBionicsApplication.service.upsertUserSiteRoles(newUSR.accountId, newUSR.siteId, newUSR).then(() => {

        selectedUserSiteRole?.updateFrom(newUSR);      

        setEditing(false);
        setBusy(false);
      });    
    } else {
      siteBionicsApplication.service.updateSiteInvitation(site.account.accountId, site.siteId, selectedSiteInvite!.id, siteRoles, requiresMatch, true).then(() => {

        selectedSiteInvite!.updateData(siteRoles, requiresMatch);

        setEditing(false);
        setBusy(false);
      });
      
    }
  } 


  const handleDelete = () => {
    setBusy(true);
    if (selectedUserSiteRole) {
      siteBionicsApplication.service.deleteUserSiteRoles(site.account.accountId, site.siteId, selectedUserSiteRole!.userId).then(() => {
        const index = site.userSiteRoles!.findIndex(obj => obj === selectedUserSiteRole);
        if (index !== -1) {
          site.userSiteRoles!.splice(index, 1)
        }
    
        setDeleting(false);
        setBusy(false);
      });
    } else {
      siteBionicsApplication.service.deleteSiteInvitation(site.account.accountId, site.siteId, selectedSiteInvite!.id).then(() => {

        const index = site.siteInvites!.findIndex(obj => obj === selectedSiteInvite);
        if (index !== -1) {
          site.siteInvites!.splice(index, 1)
        }

        setSelectedSiteInvite(undefined);
    
        setDeleting(false);
        setBusy(false);
      });
    }
  };

  return (
    <>
    
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleToolbar title="Site Team Members" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
        <ActionMenu>
          <MenuItem onClick={() => { setTabValue(1); setAdding(true); }}>Invite User...</MenuItem>
        </ActionMenu>
      </TitleToolbar>

      <Box component="div" sx={{ height: "10pt" }} />


      <Tabs value={tabValue} onChange={handleTabChange} aria-label="users invites" sx={{paddingX: "10pt"}}>
        <Tab label="Users" />
        <Tab label="Invites" />
      </Tabs>      

      

        
      {(!site.userSiteRoles || !site.siteInvites || 
        !site.account.userAccountRoles || !site.account.accountInvites ||
        !site.account.roleMap) ?
        <Typography>Loading...</Typography>
      : 
        <>                                  
          {tabValue === 0 &&
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row">Site Role</TableCell>
                  </TableRow>
                </TableHead>      
                                
                <TableBody>                                  

                  {site?.userSiteRoles?.map((r) => (                    
                    <TableRow key={r.userId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                      
                      <TableCell>                                          
                        <Typography><CachedUserControl userId={r.userId}></CachedUserControl></Typography>
                      </TableCell>
                      <TableCell>                   
                        {r.userId !== siteBionicsApplication.me?.id ?
                          <ActionMenu>
                            <MenuItem onClick={() => { setSelectedUserSiteRole(r); setEditing(true); }}>Edit...</MenuItem>
                            <MenuItem onClick={() => { setSelectedUserSiteRole(r); setDeleting(true); }}>Delete...</MenuItem>
                          </ActionMenu>
                          :
                          <Tooltip title="You cannot edit your own roles."><Lock/></Tooltip>                            
                        }
                      </TableCell>
                      <TableCell>
                        <Typography>{site?.account.roleMap?.getSiteFriendlyRoleNames(r.siteRoles)}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                                  
                  {site?.account?.userAccountRoles?.map((r) => (
                    r.baseSiteRoles.length > 0 && (
                      <TableRow key={r.userId + "all"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <Typography><CachedUserControl userId={r.userId}></CachedUserControl></Typography>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={r.userId !== siteBionicsApplication.me?.id ? "Access to this site is granted through account permissions. Visit the Account -> Team Members page to change access." : "You cannot edit your own roles."}
                          >
                            <Lock sx={{ margin: "8px" }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {site?.account.roleMap?.getSiteFriendlyRoleNames(r.baseSiteRoles)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          }
          {tabValue === 1 &&
              <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
              <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row">Date Sent</TableCell>
                    <TableCell component="th" scope="row">Expires</TableCell>
                    <TableCell component="th" scope="row">Accepted</TableCell>
                    <TableCell component="th" scope="row">Site Role</TableCell>
                  </TableRow>
                </TableHead>           
                <TableBody>
                  {site?.siteInvites?.map((r) => (
                    <TableRow key={r.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                                        
                      <TableCell>
                        <Typography><Tooltip title={r.id}><span>{r.sentToEmail}</span></Tooltip></Typography>                                          
                      </TableCell>
                      <TableCell>
                        <ActionMenu>
                          <MenuItem onClick={() => { setSelectedSiteInvite(r); setEditing(true); }}>Edit...</MenuItem>
                          <MenuItem onClick={() => { setSelectedSiteInvite(r); setDeleting(true); }}>Delete...</MenuItem>
                        </ActionMenu>
                      </TableCell>
                      <TableCell>
                        <Typography>{r.sentDate.toLocaleString()}</Typography>                                          
                      </TableCell>
                      <TableCell>
                        <Typography>{r.expDate.toLocaleString()}</Typography>                                          
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {r.accepted ? "Yes" : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {site?.account.roleMap?.getSiteFriendlyRoleNames(r.siteRoles)}
                        </Typography>
                      </TableCell>
                    </TableRow>                
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      {adding &&
        <RoleDialog
          title="Invite User"
          siteRoles={[]}
          saveButtonLabel="Invite"
          account={site!.account}
          onCancel={() => setAdding(false)}
          onSave={handleAdd}/>
      }

      {editing &&
        <RoleDialog 
          title="Edit Roles"
          userId={selectedUserSiteRole ? selectedUserSiteRole!.userId : selectedSiteInvite?.sentToEmail}              
          siteRoles={selectedUserSiteRole? selectedUserSiteRole!.siteRoles : selectedSiteInvite?.siteRoles}
          saveButtonLabel="Save"
          account={site!.account}
          onCancel={() => setEditing(false)} 
          onSave={handleEdit}   
          requiresMatch={selectedUserSiteRole ? true : selectedSiteInvite?.requiresMatch}             
        />
      }
      {deleting &&           
        <Confirm
          confirmationText={"Are you sure you want to delete this user's roles?"}
          onClose={() => setDeleting(false)}
          onConfirm={handleDelete}
        />
      }
    </>
  );
});

export default SiteUsers;