import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { Box, Slider } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useSite } from '../pages/SitePage';
import { observer } from 'mobx-react';

const Timeline: FunctionComponent = observer(() => {
  const site = useSite();
  const siteNavigator = site.siteNavigator;

  const handleSliderUpdate = (event: Event, newValue: number | number[]) => {
    siteNavigator.setCurrentTime(dayjs(new Date(newValue as number)));
  };

  return (
    <Box component="div">
      <Slider 
        value={siteNavigator.currentTime.toDate().getTime()} 
        size="small"
        step={0.1} 
        max={siteNavigator.endTime.toDate().getTime()} 
        min={siteNavigator.startTime.toDate().getTime()} 
        onChange={handleSliderUpdate} />
    </Box>
  );
});

export default Timeline;
