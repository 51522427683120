import { action, makeObservable, observable, runInAction } from "mobx";
import UserInfo from "./UserInfo";
import { SiteBionicsService } from "./SiteBionicsService";
import Account from "./Account";
import Site from "./Site";
import { accountCache, siteCache } from './Cache';
import MRUData from "./MRUData";

export class SiteBionicsApplication {
    private static instance : SiteBionicsApplication; 
    public static getInstance(): SiteBionicsApplication {
        if (!SiteBionicsApplication.instance) {
            SiteBionicsApplication.instance = new SiteBionicsApplication();
        }
        return SiteBionicsApplication.instance;
    }

    @observable accountList?: Account[];
    @observable me?: UserInfo;
    @observable mruData?: MRUData;
    @observable currentAccount?: Account;
    @observable currentSite?: Site;
    @observable lastError?: string = undefined;
    @observable isSystemAdministrator: boolean = false;
    @observable lastErrorSeverity?: string = undefined;

    service: SiteBionicsService;
    
    private constructor() {
        this.service = new SiteBionicsService(); 
        makeObservable(this);
    }

    @action
    setLastError(lastError?: string, lastErrorSeverity?: string) {
        this.lastError = lastError;
        this.lastErrorSeverity = lastErrorSeverity;
    }

    @action
    setIsSystemAdministrator(isSystemAdministrator: boolean) {
        this.isSystemAdministrator = isSystemAdministrator;
    }    
   
    @action
    setCurrentSite(currentSite?: Site) {
        this.currentSite = currentSite;
        //this.siteNavigator = currentSite ? new SiteNavigator(currentSite) : undefined;
    }

    @action
    setCurrentAccount(currentAccount?: Account) {
        this.currentAccount = currentAccount;
        this.currentSite = undefined;
    }

    @action
    setAccountList(accountList: Account[]) {
        this.accountList = accountList;
    }

    @action
    setMRUData(mruData: MRUData) {
        this.mruData = mruData;
    }    

    setMe(userInfo: UserInfo) {
        this.setMeInternal(userInfo);
    }

    @action
    setMeExtended(userInfo: UserInfo, isSystemAdministrator: boolean) {
        
        this.accountList = undefined;
        this.mruData = undefined;
        accountCache.resetCache();
        siteCache.resetCache();

        this.me = userInfo;
        this.isSystemAdministrator = isSystemAdministrator;        
    }

    @action
    setMeInternal(userInfo: UserInfo) {
        this.isSystemAdministrator = false;
        this.accountList = undefined;
        this.mruData = undefined;
        accountCache.resetCache();            

        this.me = userInfo;
    }

    @action
    clearMe() {
        this.isSystemAdministrator = false;
        this.accountList = undefined;
        this.mruData = undefined;
        accountCache.resetCache();            

        this.me = undefined;
    }

    async loadAccountsAsync() : Promise<Account[]> {        

        if (this.accountList === undefined) {
            let accountList = await accountCache.getList(this.me!.id, () => this.service.fetchAccountListAsync());
            this.setAccountList(accountList);
        }

        return this.accountList!;
    };    

    async loadMRUDataAsync() : Promise<MRUData> {
        if (this.mruData === undefined) {
            let mruData = await this.service.fetchMRUUserData();
            this.setMRUData(mruData);
        }
        return this.mruData!;
    }
    
    async setCurrentAccountByIdAsync(accountId: string) : Promise<Account | null>  {
        if (this.currentAccount?.accountId !== accountId) {

            let account = await accountCache.getObject(accountId, () => this.service.fetchAccountAsync(accountId));
            
            if (account === null) {
                return null;
            }
            this.setCurrentAccount(account);
        }
        return this.currentAccount!;
    }

    async setCurrentSiteByIdAsync(accountId: string, siteId: string) : Promise<Site | null>  {
        let currentAccount = await this.setCurrentAccountByIdAsync(accountId);
        if (currentAccount && this.currentSite?.siteId !== siteId) {

            let site = await siteCache.getObject(siteId, () => this.service.fetchSiteAsync(currentAccount, siteId))

            if (site === null) return null;

            this.setCurrentSite(site!);
        }
        
        if (this.currentSite === undefined) return null;

        return this.currentSite;
    }
}

export const useSiteBionicsApplication = () => {
    return SiteBionicsApplication.getInstance();
};

