import { useEffect, useState } from "react";
import { Alert, Box, CircularProgress } from '@mui/material';
import { Dayjs } from "dayjs";
import { convertTimezoneOnly } from "../util/Time";
import { observer } from "mobx-react";
import { SiteEvent } from "../models/SiteEvent";
import Shift from "../models/Shift";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import Site from "../models/Site";
import TransactionTable from "./TransactionTable";
import ChartFilters from "./ChartFilters";

const TransactionChart: React.FC<{ date: Dayjs, site: Site }> = observer(({ date, site }) => {

  const siteBionicsApplication = useSiteBionicsApplication();

  // Transaction data as returned from the server
  const [shiftData, setShiftData] = useState<Shift[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [transactionEventData, setTransactionEventData] = useState<SiteEvent[]>([]);

  // Controls the display of the data
  const [granularity, setGranularity] = useState<number>(60);
  const [displayHours, setDisplayHours] = useState<string>("Open");

  // Convert the date to the site's timezone
  const siteDate = convertTimezoneOnly(date.startOf('day'), site.timeZone);
  const siteEndDate = convertTimezoneOnly(date.add(7, 'days').startOf('day'), site.timeZone);

  // Get the shift data from the server
  useEffect(() => {
    setIsLoading(true);
    siteBionicsApplication.service.fetchShiftsAsync(site, siteDate.utc(), siteEndDate.utc()).then((shifts) => {
      setShiftData(shifts);

      siteBionicsApplication.service.fetchSiteEventsAsync(site, siteDate.utc(), siteDate.add(1, 'week').utc(), "Transaction").then((transactionEventData) => {
        setTransactionEventData(transactionEventData);
        setIsLoading(false);
      });
    });
  }, [date]);

  if (isLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  if (transactionEventData.length === 0) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }

  const hasShiftData = shiftData.length > 0;

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      <ChartFilters
        displayHours={displayHours}
        setDisplayHours={setDisplayHours}
        granularity={granularity}
        setGranularity={setGranularity}
        hasShiftData={hasShiftData}
      />
      <Box component="div" display="flex" width="100%" overflow="auto">
        <Box component="div" sx={{ width: '100%' }}>
          <TransactionTable transactionEventData={transactionEventData} shiftData={shiftData} granularity={granularity} displayHours={displayHours} siteDate={siteDate} site={site} />
        </Box>
      </Box>
    </Box>
  );
});

export default TransactionChart;