
import { action, makeObservable, observable } from "mobx";
import Site from "./Site";
import Scan from "./Scan";
import { SiteBionicsApplication } from "./SiteBionicsApplication";
import ScanAreaLayout from "./layout/ScanAreaLayout";

export default class ScanArea {

    @observable site: Site;
    @observable scanAreaId: string;
    @observable scanAreaName: string;
    @observable scanAreaDescription: string;
    @observable scans?: Scan[];

    constructor(site: Site, scanAreaId: string, scanAreaName: string, scanAreaDescription: string) {
        this.site = site;
        this.scanAreaId = scanAreaId;
        this.scanAreaName = scanAreaName;
        this.scanAreaDescription = scanAreaDescription;
        makeObservable(this);
    }

    static fromJson(site: Site, json: any) {
        return new ScanArea(site, json.id, json.scanAreaName, json.scanAreaDescription);
    }

    @action
    setScans(scans?: Scan[]) {
        this.scans = scans;
    }

    @action
    setData(name: string, description: string) {
      this.scanAreaName = name;
      this.scanAreaDescription = description;
    }

    @action
    async loadScansAsync(refresh: boolean = false): Promise<Scan[] | undefined> {
      if (this.scans === undefined || refresh) {
        let scans = await SiteBionicsApplication.getInstance().service.fetchScanListAsync(this);
        if (scans) {
          scans.sort((a, b) => new Date(b.scanTime).getTime() - new Date(a.scanTime).getTime());
        }
        this.setScans(scans);
      }
      return this.scans;
    }
}