
import { makeObservable, observable, action } from "mobx";
import Site from "./Site";
import { Color3 } from "@babylonjs/core";
import { Dayjs } from "dayjs";
import { SiteBionicsApplication } from "./SiteBionicsApplication";
import { cameraColors } from "../util/Colors";
import CameraCalibration from "./CameraCalibration";

export enum CameraType {
    ONVIF,
    RTSP,
    Blink
  }
  
export default class Camera {

    id: string; //what is this?

    @observable cameraId: string;
    @observable site: Site;    
    @observable cameraType: CameraType;
    @observable hubId: string;
    @observable address: string;
    @observable manufacturer: string;
    @observable model: string;
    @observable serialNumber: string;
    @observable onvifChannel: number;
    @observable onvifSubStream: number;
    @observable autoRtspUrl: boolean;
    @observable rtspUrl: string;
    @observable width: number;
    @observable height: number;

    // temporal
    @observable temporalModifiedTime: Date;
    @observable cameraName: string;
    @observable scanAreaId: string;
    @observable calibration: CameraCalibration;
    @observable fov: number; // legacy
    @observable distortionCoeff: number[]; // legacy
    
    // view model props
    @observable color: Color3;
    @observable videoTimeOffset: number = 0;

    constructor(site: Site, cameraId: string, 
            cameraName: string, cameraType: CameraType, scanAreaId: string, hubId: string, address: string,
            manufacturer: string, model: string, serialNumber: string,        
            onvifChannel: number, onvifSubStream: number, autoRtspUrl: boolean, rtspUrl: string, 
            width: number, height: number,
            temporalModifiedTime: Date,
            calibration: CameraCalibration,
            color: Color3) {
        
        this.site = site;
        this.id = cameraId;
        this.cameraId = cameraId;
        this.cameraType = cameraType;
        this.address = address;
        this.manufacturer = manufacturer;
        this.model = model;
        this.serialNumber = serialNumber;
        this.onvifChannel = onvifChannel;
        this.onvifSubStream = onvifSubStream;
        this.autoRtspUrl = autoRtspUrl;
        this.width = width;
        this.height = height;
        this.color = color;
        this.rtspUrl = rtspUrl;

        // temporal
        this.temporalModifiedTime = temporalModifiedTime;
        this.scanAreaId = scanAreaId;
        this.hubId = hubId;
        this.cameraName = cameraName ?? cameraId;
        this.calibration = calibration;
        
        // legacy
        this.distortionCoeff = [calibration.distCoef1, calibration.distCoef2, calibration.distCoef3, calibration.distCoef4, calibration.distCoef5];
        this.fov = calibration.horizFovDeg;

        this.videoTimeOffset = -2;

        makeObservable(this);
    }

    toJson() {
        return {
            accountId: this.site.account.accountId,
            siteId: this.site.siteId,
            id: this.cameraId,
            cameraType: this.cameraType,
            address: this.address,
            manufacturer: this.manufacturer,
            model: this.model,
            serialNumber: this.serialNumber,
            onvifChannel: this.onvifChannel,
            onvifSubStream: this.onvifSubStream,
            autoRtspUrl: this.autoRtspUrl,
            rtspUrl: this.rtspUrl,
            width: this.width,
            height: this.height,

            temporalModifiedTime: this.temporalModifiedTime.toISOString(),
            name: this.cameraName,
            scanAreaId: this.scanAreaId,
            hubId: this.hubId,
            calibration: this.calibration.toJson(),

            color: this.color,
            videoTimeOffset: this.videoTimeOffset
        };
    }
    static fromJson(site: Site, json: any, index: number = -1) : Camera {
        return new Camera(site, json.id, json.name, json.cameraType,
                        json.scanAreaId, json.hubId, json.address, json.manufacturer, json.model, json.serialNumber,
                        json.onvifChannel, json.onvifSubStream, json.autoRtspUrl, json.rtspUrl, 
                        json.width ?? 2960, json.height ?? 1668,
                        new Date(json.temporalModifiedTime),
                        CameraCalibration.fromJson(json.calibration),
                        json.color ?? cameraColors(index));
    }

    clone() : Camera {
        // review: should we be doing a deep clone of calibration?
        let camera = new Camera(this.site, this.cameraId, this.cameraName, this.cameraType, this.scanAreaId, this.hubId,
            this.address, this.manufacturer, this.model, this.serialNumber, this.onvifChannel, this.onvifSubStream, this.autoRtspUrl, this.rtspUrl, this.width, this.height,
            this.temporalModifiedTime, this.calibration, this.color);
        return camera;
    }

    @action updateFrom(camera: Camera) {
        // review: should we be doing a deep clone of calibration?
        this.address = camera.address;
        this.cameraName = camera.cameraName;
        this.cameraType = camera.cameraType;
        this.fov = camera.fov;
        this.hubId = camera.hubId;
        this.manufacturer = camera.manufacturer;
        this.model = camera.model;
        this.onvifChannel = camera.onvifChannel;
        this.onvifSubStream = camera.onvifSubStream;
        this.autoRtspUrl = camera.autoRtspUrl;
        this.rtspUrl = camera.rtspUrl;
        this.scanAreaId = camera.scanAreaId;
        this.serialNumber = camera.serialNumber;
        this.color = camera.color;
        this.width = camera.width;
        this.height = camera.height;
        this.calibration = camera.calibration;
        this.distortionCoeff = camera.distortionCoeff;
        this.videoTimeOffset = camera.videoTimeOffset;
        this.temporalModifiedTime = camera.temporalModifiedTime;
        this.cameraId = camera.cameraId;
    }   
    
    @action setName(cameraName: string) {
        this.cameraName = cameraName;
    }

    @action setCameraType(cameraType: CameraType) {
        this.cameraType = cameraType;
    }

    @action setScanAreaId(scanAreaId: string) {
        this.scanAreaId = scanAreaId;
    }

    @action setHubId(hubId: string) {
        this.hubId = hubId;
    }

    @action setAddress(address: string) {
        this.address = address;
    }

    @action setManufacturer(manufacturer: string) {
        this.manufacturer = manufacturer;
    }
    
    @action setModel(model: string) {
        this.model = model;
    }

    @action setWidth(width: number) {
        this.width = width;
    }

    @action setHeight(height: number) {
        this.height = height;
    }    

    @action setSerialNumber(serialNumber: string) {
        this.serialNumber = serialNumber;
    }

    @action setOnvifChannel(onvifChannel: number) {
        this.onvifChannel = onvifChannel;
    }

    @action setOnvifSubStream(onvifSubStream: number) {
        this.onvifSubStream = onvifSubStream;
    }

    @action setFov(fov: number) {
        this.fov = fov;
    }

    @action setDistortionCoeff(distortionCoeff: number[]) {
        this.distortionCoeff = distortionCoeff;
    }

    @action setColor(color: Color3) {
        this.color = color;
    }

    @action setAutoRtspUrl(autoRtspUrl: boolean) {
        this.autoRtspUrl = autoRtspUrl;
    }

    @action setRtspUrl(rtspUrl: string) {
        this.rtspUrl = rtspUrl;
    }

    @action setVideoTimeOffset(videoTimeOffset: number) {
        this.videoTimeOffset = videoTimeOffset;
    }

    async getSnapShotSasUrlAsync() : Promise<string> {
        return SiteBionicsApplication.getInstance().service.fetchCameraSnapShotSasUri(this);
    }

    async getCameraMaskSasUrlAsync() : Promise<string> {
        return SiteBionicsApplication.getInstance().service.fetchCameraMaskSasUri(this);
    }

    async getSnapShots(startTime: Dayjs, endTime: Dayjs) : Promise<any> {
        return SiteBionicsApplication.getInstance().service.fetchCameraSnapShots(this, startTime, endTime);
    }
}
