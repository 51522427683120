import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Tooltip, Box, Button, Checkbox, IconButton, Backdrop, CircularProgress, Link, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CameraDialog from "../dialogs/CameraDialog";
import { useSite } from "./SitePage";
import Camera, { CameraType } from "../models/Camera";
import Confirm from "../components/Confirm";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { Color3 } from "@babylonjs/core";
import TitleToolbar from "../components/TitleToolbar";
import { HubStatusCamera, HubStatusResponse } from "../models/HubStatusResponse";
import StatusLED from "../components/StatusLED";
import Site from "../models/Site";
import CachedHubControl from "./CachedHubControl";
import CameraCalibration from "../models/CameraCalibration";
import ActionMenu from "../components/ActionMenu";

interface CamerasProps {

  hubStatuses: Record<string, HubStatusResponse | null> | null;
}

const Cameras : React.FC<CamerasProps> = observer(( {hubStatuses}) => {
  
  const site = useSite();

  const siteBionicsApplication = useSiteBionicsApplication(); 
  
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {

    if (!site.cameras) {
      setLoading(true);
      site.loadCamerasAsync().then(() => {
        setLoading(false);
      })

      return;
    }

  }, [site, site.cameras]);

  
  // Dialogs

  const [editCamera, setEditCamera] = useState<Camera | undefined>(undefined);
  const [addingCamera, setAddingCamera] = useState(false);

  const [deleteCamera, setDeleteCamera] = useState<Camera | undefined>(undefined);

  const handleEditClick = (camera: Camera) => {
    setEditCamera(camera);
  };
    
  const handleCreateClick = () => {
    const camera = new Camera(site, "", "", CameraType.ONVIF,
      "", "", "", "", "", "", 0, 0, true, "", 2960, 1668, new Date(), new CameraCalibration(), Color3.Magenta());
    setEditCamera(camera);
    setAddingCamera(true);
  };  
  
  const handleSaveEditClick = (camera: Camera) => {
    setBusy(true);
    if (addingCamera) {
      siteBionicsApplication.service.createCameraAsync(site, camera!).then((newCamera: Camera) => {
        site.cameras!.push(newCamera);
        setEditCamera(undefined);
        setAddingCamera(false);
        setBusy(false);
      });
      
    } else {
      setBusy(true);      
      siteBionicsApplication.service.updateCameraAsync(camera).then(() => {
        setEditCamera(undefined);
        setBusy(false);
      });
    }
  };

  const handleDelete = () => {
    setBusy(true);

    siteBionicsApplication.service.deleteCameraAsync(deleteCamera!).then(() => {
      
      // Find the camera in the list
      let index = site.cameras!.findIndex(obj => deleteCamera!.cameraId === obj.cameraId);

      // Remove it
      if (index !== -1) {
        site.cameras!.splice(index!, 1);
      }

      // Close the dialog
      setDeleteCamera(undefined);
    
      setBusy(false);
    });
    
  };

  const getCameraHealth = (cameraId: string): [string, number | null, number | null] => {
    if (hubStatuses === null) return ["grey", 0, 0];
  
    // Loop through the hubs    
    for (const key of Object.keys(hubStatuses)) {
  
      const hubStatus = hubStatuses[key];
  
      // Loop through the cameras
      for (const camera of hubStatus?.status.cameras ?? []) {
        if (camera.cameraId === cameraId) {
          if ((camera.videoFPS ?? 0) < 10) return ["red", camera.videoFPS, camera.inferenceFPS];
          return ["green", camera.videoFPS, camera.inferenceFPS];
        }
      }
    }
    
    return ["red", 0, 0];
  };

  return (
    
    <>
      
      <TitleToolbar title="Cameras" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
        <ActionMenu>
          <MenuItem onClick={handleCreateClick}>Add Camera...</MenuItem>
        </ActionMenu>        
      </TitleToolbar>
      
      <Box component="div" sx={{ height: '10pt' }}/>      
      
      {loading && <Typography>Loading...</Typography>}
      
      {site.cameras && site.cameras.length === 0 && 
        <Typography>No available cameras</Typography>
      }

      {site.cameras && site.cameras.length > 0 &&
            
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: '15%' }}>Camera Name</TableCell>
                <TableCell align="left" sx={{ width: '5%' }}></TableCell>
                <TableCell align="left" sx={{ width: '5%' }}>Health</TableCell>
                <TableCell align="left">Video FPS</TableCell>
                <TableCell align="left">Inference FPS</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Hub</TableCell>
                <TableCell align="left">Manufacturer / Model</TableCell>
                
              </TableRow>
            </TableHead>
            
            <TableBody>
              {site.cameras?.map((c) => {
                const cameraHealth = getCameraHealth(c.cameraId);
                return (
                <TableRow key={c.cameraId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                  
                    <TableCell align="left" sx={{ width: '15%' }}><Tooltip title={c.cameraId}><span>{c.cameraName}</span></Tooltip></TableCell>
                    <TableCell align="left" sx={{ width: '5%' }}>
                      <ActionMenu>
                        <MenuItem onClick={() => { handleEditClick(c); }}>Edit Camera...</MenuItem>
                        <MenuItem onClick={() => { setDeleteCamera(c); }}>Delete Camera...</MenuItem>
                      </ActionMenu>
                    </TableCell>

                    <TableCell align="left"sx={{ width: '5%' }}><StatusLED color={cameraHealth[0]}/></TableCell>
                    <TableCell align="left">{cameraHealth[1]?.toFixed(1) ?? "N/A"}</TableCell>
                    <TableCell align="left">{cameraHealth[2]?.toFixed(1) ?? "N/A"}</TableCell>
                    <TableCell align="left">{CameraType[c.cameraType]}</TableCell>
                  
                    <TableCell align="left">
                      <CachedHubControl site={site} hubId={c.hubId}/>                    
                    </TableCell>

                    <TableCell align="left">
                      {c.serialNumber ?
                        <Tooltip title={c.serialNumber}>
                          <span>{c.manufacturer ?? ""} / {c.model ?? "" }</span>
                        </Tooltip>
                      :
                        <span>{c.manufacturer ?? ""} / {c.model ?? "" }</span>
                      }
                      </TableCell>                  
                </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      { deleteCamera &&
        <Confirm
          confirmationText={"Are you sure you want to delete the camera '" + deleteCamera.cameraName + "'?"}
          confirmButtonLabel="Delete"
          onClose={() => setDeleteCamera(undefined)}
          onConfirm={handleDelete}
        />             
      }     

      {editCamera &&
        <CameraDialog      
          camera={editCamera}
          site={site}
          title={addingCamera ? "Create Camera" : "Edit Camera"}
          saveButtonLabel={addingCamera ? "Create" : "Save"}
          onCancel={() => {
            setEditCamera(undefined);
            setAddingCamera(false);
          }}
          onSave={handleSaveEditClick}
        />        
        
      }

    </>
  )
})

export default Cameras;