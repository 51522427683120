import { observer } from "mobx-react";
import { FunctionComponent, useEffect, useState} from "react";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { IconButton, MenuItem, Backdrop, Tooltip, CircularProgress, Tabs, Tab, Toolbar, Typography, Checkbox, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAccount, useAccountBreadcrumbs } from "./AccountPage";
import RoleDialog from "../dialogs/RoleDialog";
import CachedUserControl from "./CachedUserControl";
import UserAccountRole from "../models/UserAccountRole";
import { AccountInvite } from "../models/Invite";
import Confirm from "../components/Confirm";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import TitleToolbar from "../components/TitleToolbar";
import ActionMenu from "../components/ActionMenu";
import { Lock } from "@mui/icons-material";

const AccountUsers: FunctionComponent = observer(() => {

  const account = useAccount();
  const siteBionicsApplication = useSiteBionicsApplication();

  const [selectedUserAccountRole, setSelectedUserAccountRole] = useState<UserAccountRole | undefined>(undefined);
  const [selectedAccountInvite, setSelectedAccountInvite] = useState<AccountInvite | undefined>(undefined);

  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);

  const [busy, setBusy] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedUserAccountRole(undefined);
    setSelectedAccountInvite(undefined);
    setTabValue(newValue);
  };

  const breadcrumbs = useAccountBreadcrumbs("Users");

  useEffect(() => {    
    if (!account.hasAccountCapability("ManageAccountUsers")) return;

    account.loadUserAccountRolesAsync(false, siteBionicsApplication.me?.id);  

    account.loadAccountInvites();

    account.loadRoleMapAsync(); 

  });  
  
  const handleAdd = (userId: string, requiresMatch: boolean, accountRoles: string[], baseSiteRoles: string[]) => {

    setBusy(true);
    if (userId.indexOf("@") !== -1) {
      siteBionicsApplication.service.createAccountInvitation(account.accountId, accountRoles, baseSiteRoles, userId, requiresMatch).then(() => {
        account!.loadAccountInvites(true);
        setAdding(false);
        setBusy(false);
      });
    } else {
      let newUAR = new UserAccountRole(account.accountId, userId, accountRoles, baseSiteRoles);
      siteBionicsApplication.service.upsertUserAccountRoles(account.accountId, newUAR).then(() => {

        account.userAccountRoles!.push(newUAR);

        setAdding(false);
        setBusy(false);
      });    
    }
  } 

  const handleSave = (userId: string, requiresMatch: boolean, accountRoles: string[], siteRoles: string[]) => {
    setBusy(true);    
    if (selectedUserAccountRole) {
      let newUAR = new UserAccountRole(account.accountId, selectedUserAccountRole!.userId, accountRoles, siteRoles);    
      siteBionicsApplication.service.upsertUserAccountRoles(account.accountId, newUAR).then(() => {
        selectedUserAccountRole?.updateFrom(newUAR);
        setEditing(false);
        setBusy(false);
      });    
    } else {
      siteBionicsApplication.service.updateAccountInvitation(account.accountId, selectedAccountInvite!.id, accountRoles, siteRoles, requiresMatch, true).then(() => {
        selectedAccountInvite!.updateData(accountRoles, siteRoles, requiresMatch);
        setEditing(false);
        setBusy(false);
      })
      
    }
  } 

  

  const handleDelete = () => {
    setBusy(true);
    if (selectedUserAccountRole) {
      siteBionicsApplication.service.deleteUserAccountRoles(account!.accountId, [selectedUserAccountRole!.userId]).then(() => {
        const index = account!.userAccountRoles!.findIndex(obj => obj === selectedUserAccountRole);
        if (index !== -1) {
          account!.userAccountRoles!.splice(index, 1)
        }

        setSelectedUserAccountRole(undefined);
    
        setDeleting(false);
        setBusy(false);
      });
    } else {
      siteBionicsApplication.service.deleteAccountInvitation(account!.accountId, selectedAccountInvite!.id).then(() => {
        const index = account!.accountInvites!.findIndex(obj => obj === selectedAccountInvite);
        if (index !== -1) {
          account!.accountInvites!.splice(index, 1)
        }

        setSelectedAccountInvite(undefined);
    
        setDeleting(false);
        setBusy(false);
      });
      
    }
  }  

  return (
    <>      

      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleToolbar title="Account Users" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>        
        <ActionMenu>
          <MenuItem onClick={() => { setTabValue(1); setAdding(true); }}>Invite User...</MenuItem>
        </ActionMenu>
      </TitleToolbar>
      
      <Box component="div" sx={{ height: "10pt" }} />
            
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{paddingX: "10pt"}}>
        <Tab label="Users" />
        <Tab label="Invites" />
      </Tabs>
        
      {tabValue === 0 &&
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">Email</TableCell>              
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row">Account Roles</TableCell>
                <TableCell component="th" scope="row">Base Site Roles</TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>              
              {account?.userAccountRoles?.map((r) =>
                <TableRow key={r.userId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Typography><CachedUserControl userId={r.userId}></CachedUserControl></Typography>
                  </TableCell>
                  <TableCell>
                    {r.userId !== siteBionicsApplication.me?.id ?
                      <ActionMenu>
                        <MenuItem onClick={() => { setSelectedUserAccountRole(r); setEditing(true); }}>Edit Roles...</MenuItem>
                        <MenuItem onClick={() => { setSelectedUserAccountRole(r); setDeleting(true); }}>Delete Roles...</MenuItem>
                      </ActionMenu>
                      :
                      <Tooltip title="You cannot edit your own roles"><IconButton><Lock /></IconButton></Tooltip>
                    }
                  </TableCell>
                  <TableCell>
                    <Typography>{account!.roleMap?.getAccountFriendlyRoleNames(r.accountRoles)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{account!.roleMap?.getSiteFriendlyRoleNames(r.baseSiteRoles)}</Typography>
                  </TableCell>
                </TableRow>
              )}              
            </TableBody>              
          </Table>
        </TableContainer>
      }      
      {tabValue === 1 && 
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">Email</TableCell>    
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row">Date Sent</TableCell>
                <TableCell component="th" scope="row">Expires</TableCell>
                <TableCell component="th" scope="row">Account Roles</TableCell>
                <TableCell component="th" scope="row">Base Site Roles</TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>              
              {account?.accountInvites?.map((r) => (
                !r.accepted ? (
                <TableRow key={r.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                                    
                  <TableCell>
                    <Typography><Tooltip title={r.id}><span>{r.sentToEmail}</span></Tooltip></Typography>
                  </TableCell>                  
                  <TableCell>
                    <ActionMenu>
                      <MenuItem onClick={() => { setSelectedAccountInvite(r); setEditing(true); }}>Edit Roles...</MenuItem>
                      <MenuItem onClick={() => { setSelectedAccountInvite(r); setDeleting(true); }}>Delete Roles...</MenuItem>
                     </ActionMenu>
                  </TableCell>
                  <TableCell>
                    <Typography>{r.sentDate.toLocaleString()}</Typography>
                  </TableCell>         
                  <TableCell>
                    <Typography>{r.expDate.toLocaleString()}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{account!.roleMap?.getAccountFriendlyRoleNames(r.accountRoles)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{account!.roleMap?.getSiteFriendlyRoleNames(r.baseSiteRoles)}</Typography>        
                  </TableCell>                  
                </TableRow>
              ) : ""))}
            </TableBody>              
          </Table>
        </TableContainer>
      }
      
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      {adding && 
        <RoleDialog 
          title="Invite User"
          accountRoles={[]}
          siteRoles={[]}
          saveButtonLabel="Invite"
          account={account!}
          onCancel={() => {setAdding(false)}} 
          onSave={handleAdd}></RoleDialog>
      }
      {editing && 
        <RoleDialog 
          title="Edit Roles"
          saveButtonLabel="Save"
          userId={selectedUserAccountRole ? selectedUserAccountRole!.userId : selectedAccountInvite!.sentToEmail}
          accountRoles={selectedUserAccountRole ? selectedUserAccountRole!.accountRoles : selectedAccountInvite?.accountRoles}
          siteRoles={selectedUserAccountRole ? selectedUserAccountRole!.baseSiteRoles : selectedAccountInvite?.baseSiteRoles}
          requiresMatch={selectedUserAccountRole ? true : selectedAccountInvite!.requiresMatch}
          account={account!}
          onSave={handleSave}
          onCancel={() => {setEditing(false)}}>                  
        </RoleDialog>
      }
      {deleting &&           
        <Confirm
          confirmationText={"Are you sure you want to delete this user's roles?"}
          onClose={() => {setDeleting(false)}}
          onConfirm={handleDelete}
        />
      }
    </>
  );
});

export default AccountUsers;