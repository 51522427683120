import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import PageState from "../models/PageState";

// useQueryState is a hook to replace useState. It allows for a component to set a state with an initial value and to update it, similar to useState. 
//
// But it adds in the capability to use query string data and/or global saved state for the initial value. It also allows for (the optional) setting 
// of the state to modify the query string as the state is set.
//
// Global state uses the pageState construct. 

function useQueryState<T>(page: string, pageState: PageState, key: string, defaultValue: T, serialize: (value: T) => string, deserialize: (value: string) => T): [T, (value: T, save?: boolean, replace?: boolean) => void] {

  // Value from the query string
  const [searchParams, setSearchParams] = useSearchParams();
  const queryValue = searchParams.has(key) ? deserialize(searchParams.get(key)!) : null;

  // Value from the saved global state
  const navigatorValue = pageState.getPageState(page, key, null);

  // The initial value comes first from the query string, next from saved global state, and finally the default value
  const initialValue = queryValue ?? navigatorValue ?? defaultValue;
  const [state, setState] = useState<T>(initialValue); 

  // Setting the query state
  const setQueryState = (value: T, save: boolean = false, replaceQueryString: boolean = false) => {
    setState(value);
    if (save) {
      pageState.setPageState(page, key, value);
      const newParams = replaceQueryString ? new URLSearchParams() : new URLSearchParams(window.location.search);
      newParams.set(key, serialize(value));
      setSearchParams(newParams);
    }
  };

  // Return the value and the method to change it
  return [state, setQueryState];
}

export default useQueryState;