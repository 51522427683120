
import { action, makeObservable, observable } from "mobx";
import { ScanState } from "./ScanState";
import ScanArea from "./ScanArea";
import CameraCalibration from "./CameraCalibration";
import Pose from "./Pose";
import Model from "./Model";
import ScanAreaLayout from "./layout/ScanAreaLayout";
import { SiteBionicsApplication } from "./SiteBionicsApplication";

export default class Scan {

    @observable area: ScanArea;
    @observable scanId: string;
    @observable scanVersion: number;
    @observable scanTime: Date;
    @observable scanState: ScanState;
    @observable scanPose: Pose;
    @observable models : Model[]
    @observable cameraCalibrations: { [key: string]: CameraCalibration }
    @observable scanAreaLayout?: ScanAreaLayout; //legacy... this needs to move to the scan area

    constructor(area: ScanArea, scanId: string,
            scanVersion: number, scanTime: Date, scanState: ScanState,
            scanPose: Pose, models: Model[], cameraCalibrations: { [key: string]: CameraCalibration }) {    
        
        this.area = area;
        this.scanId = scanId;
        this.scanPose = scanPose;
        this.scanVersion = scanVersion;
        this.scanTime = scanTime;
        this.scanState = scanState;
        this.models = models;
        this.cameraCalibrations = cameraCalibrations;

        makeObservable(this);
  }

  @action
  setScanState(scanState: ScanState) {
    this.scanState = scanState;
  }


    static fromJson(area: ScanArea, json: any): Scan {
        const scanId = json.id; //intentional... the json has id, not scanId
        const scanVersion = json.scanVersion;
        const scanTime = new Date(json.scanTime);
        const scanState = json.scanProcessState;
        const scanPose = Pose.fromJson(json.scanPose);
        const models = json.models?.map((model: any) => Model.fromJson(model)) ?? [];
        const cameraCalibrations = Object.keys(json.cameraCalibrations).reduce((acc: { [key: string]: CameraCalibration }, key: string) => {
            acc[key] = CameraCalibration.fromJson(json.cameraCalibrations[key]);
            return acc;
        }, {});
        
        return new Scan(area, scanId, scanVersion, scanTime, scanState, scanPose, models, cameraCalibrations);
    }

    @action
    setScanAreaLayout(scanAreaLayout: ScanAreaLayout) {
        this.scanAreaLayout = scanAreaLayout;
    }

    @action 
    async loadScanAreaLayoutAsync(refresh: boolean = false): Promise<ScanAreaLayout | undefined> {
        if (this.scanAreaLayout === undefined || refresh) {
            let scanAreaLayout = await SiteBionicsApplication.getInstance().service.fetchScanAreaLayoutAsync(this);
            this.setScanAreaLayout(scanAreaLayout);
        }
        return this.scanAreaLayout;
    }

}
