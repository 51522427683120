import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { observer } from "mobx-react-lite";
import { useForm } from 'react-hook-form';

interface ScanAreaDialogProps {
  title: string; 

  name: string;

  description: string;

  saveLabel?: string;

  onClose: () => void;

  onSave: (name: string, description: string) => void;
}

interface FormValues {
  name: string;
  description: string;
}

const ScanAreaDialog: React.FC<ScanAreaDialogProps> = ({
  title = "Edit Scan Area",
  name = "",
  description = "",
  saveLabel = "Save",
  onClose,
  onSave
 }) => {
  
  //const [formData, setFormData] = useState({name: name})
  const {register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      name: name,
      description: description
    },
    mode: "onBlur"
  });

      
  const handleCancelEditClick = () => {
    onClose();
  }

  const handleSave = (data: FormValues) => {        
      onSave(data.name, data.description);    
  };

  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    
    if (reason === "backdropClick") return;

    onClose();

  }
    
  return (
    <>    
      <Dialog open={true} onClose={handleCancelLocal} maxWidth="lg" >
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSave)} >
          <DialogContent>

            <TextField 
              id="name" 
              label="Name"
              margin="dense" 
              type="string" 
              fullWidth 
              required
              variant="standard"
              {...register("name", { required: "Name is required"})}
              error={!!(touchedFields["name"] && errors["name"])}
              helperText={touchedFields["name"] && errors.name?.message}               
            /> 

            <TextField
              id="description"
              label="Description"
              margin="dense"
              type="string"
              fullWidth
              required
              variant="standard"
              {...register("description", { required: "Description is required"})}
              error={!!(touchedFields["description"] && errors["description"])}
              helperText={touchedFields["description"] && errors.description?.message}
            /> 
              
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleCancelEditClick} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="outlined" disabled={!isValid} color="primary">
              {saveLabel}
            </Button>
          
          </DialogActions>
        </form>
      </Dialog>      
    </>
  )
};

export default observer(ScanAreaDialog);