import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import { SelectionManager, SelectionManagerContext } from "../util/SelectionManager";
import SiteViewerScene from "../components-3d/SiteViewerScene";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { ModelType } from "../models/ModelType";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";
import { draft } from "mobx-keystone";

const Viewer: FunctionComponent = observer(() => {
  const [selectionManager] = useState(new SelectionManager());
  const site = useSite();
  const siteNavigator = site.siteNavigator;
  const boxStyle = { border: '1px solid black' };
  const breadcrumbs = useSitePageBreadcrumbs("Viewer");
  
  const [availableModelTypes, setAvailableModelTypes] = useState<{name : string, modelType: ModelType}[]>([]);
  
  useEffect(() => {
    siteNavigator.currentScan?.loadScanAreaLayoutAsync().then((scanAreaLayout) => {
      siteNavigator.setCurrentScanAreaLayout(scanAreaLayout);
    });
  }, [siteNavigator.currentScan]);

  useEffect(() => {
    if (!site.hasSiteCapability("ViewSite")) return;
    siteNavigator.site.loadAreasAsync().then((scanAreas) => {
      if (scanAreas?.length) {
          siteNavigator.setCurrentScanArea(scanAreas[0]);
      }
    });
    siteNavigator.site.loadCamerasAsync().then((cameras) => {
      if (cameras?.length) {
        siteNavigator.setCurrentCameraById(cameras[0].cameraId);
      }
    });
  }, []);
  
  useEffect(() => {
    const scan = siteNavigator.currentScan;
    if (!scan) {
      setAvailableModelTypes([]);
      siteNavigator.setCurrentModelType(ModelType.Lidar);
    } else {
      const availableModelTypes = scan.models.map((model) => { return { name: ModelType[model.modelType], modelType: model.modelType } });
      setAvailableModelTypes(availableModelTypes);
      const hasModels = availableModelTypes && availableModelTypes.length > 0;
      siteNavigator.setCurrentModelType(hasModels ? availableModelTypes[0].modelType : ModelType.Lidar);
    }
  }, [siteNavigator.currentScan]);
  
  const dateOptions : Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

  const handleAreaChange = (event: SelectChangeEvent) => {
    siteNavigator.setCurrentScanAreaById(event.target.value);
  };

  const handleScanVersionChange = (event: SelectChangeEvent) => {
    siteNavigator.setCurrentScanByScanId(event.target.value);
  };

  const handleModelTypeChange = (event: SelectChangeEvent) => {
    siteNavigator.setCurrentModelType(parseInt(event.target.value));
  };

  return (
    <SelectionManagerContext.Provider value={selectionManager}>
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <Toolbar variant="dense">
        <Box component="div" margin={2}>
          <FormControl size="small">
            <InputLabel id="scan-area-id-label">Area</InputLabel>
            <Select
              labelId="scan-area-id-label"
              id="scan-area-id"
              value={siteNavigator.currentScanArea?.scanAreaId ?? ""}
              label="Area"
              onChange={handleAreaChange}>

              {siteNavigator.site.scanAreas?.map((sa) => (
                <MenuItem key={sa.scanAreaId} value={sa.scanAreaId}>{sa.scanAreaName}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small">
            <InputLabel id="scan-version-label">Scan Version</InputLabel>
            <Select
              labelId="scan-version-label"
              id="scan-version"
              value={siteNavigator.currentScan?.scanId ?? ""}
              label="Scan Version"
              onChange={handleScanVersionChange}>

              {siteNavigator?.currentScanArea?.scans?.map((s) => (
                <MenuItem key={s.scanId} value={s.scanId}>v{String(s.scanVersion)} - {s.scanTime.toLocaleDateString(undefined, dateOptions)}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small">
            <InputLabel id="model-type-label">View Type</InputLabel>
            <Select
              labelId="model-type-label"
              id="model-type"
              value={siteNavigator.currentModelType.toString()}
              label="Model Type"
              onChange={handleModelTypeChange}>

              {availableModelTypes.map((mt) => (
                <MenuItem key={mt.modelType} value={mt.modelType}>{mt.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {/* <FormControl>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={siteNavigator.overlayFloorPlan}
                  onChange={(e, v) => siteNavigator.setOverlayFloorPlan(v)}
                />
              }
              label="Overlay Floorplan"
            />
          </FormControl> */}
                            
        </Box>
      </Toolbar>

      <Box component="div" flexGrow="1" overflow="hidden">
        {siteNavigator.currentScanAreaLayout && 
          <SiteViewerScene siteNavigator={siteNavigator} scanAreaLayout={siteNavigator.currentScanAreaLayout} showCameras={false}/>
        }
      </Box>
      
    </SelectionManagerContext.Provider>
  )
})

export default Viewer;


