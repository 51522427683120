import { useState, FunctionComponent, useEffect, FC } from "react";
import { observer } from "mobx-react"
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Paper, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { SelectionManager } from "../util/SelectionManager";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { ModelType } from "../models/ModelType";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import TitleToolbar from "../components/TitleToolbar";
import { useParams } from "react-router-dom";
import ScanArea from "../models/ScanArea";
import { SiteBionicsApplication } from "../models/SiteBionicsApplication";
import Scan from "../models/Scan";
import ScanConfigScene from "../components-3d/ScanConfigScene";
import Camera from "../models/Camera";
import { action, makeObservable, observable, set } from "mobx";
import CameraCalibration from "../models/CameraCalibration";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CameraIndoorOutlinedIcon from '@mui/icons-material/CameraIndoorOutlined';
import ShowHideToggle from "../components/ShowHideToggle";
import ToggleIconButton from "../components/ToggleIconButton";
import { ArrowForward, Business, Check, CheckBoxOutlineBlank, Info, InsertLink, Publish } from "@mui/icons-material";
import { ScanState } from "../models/ScanState";
import ScanIndicator from "../components/ScanIndicator";
import ActionMenu from "../components/ActionMenu";
import Model from "../models/Model";


export class ScanModelViewModel {
  @observable model: Model;
  @observable show: boolean = false;

  constructor(model: Model) {
      this.model = model;
      this.show = model.modelType === ModelType.Lidar;
      makeObservable(this);
  }
 
  @action
  setShow(show: boolean) {
    this.show = show;
  }
}

export class ScanCameraViewModel {
  @observable camera: Camera;
  @observable currentCalibration?: CameraCalibration;
  @observable scanCalibration?: CameraCalibration
  @observable showWithCurrentPose: boolean = false;
  @observable showWithScanPose: boolean = true;
  @observable posesAreEqual: boolean = false;
  @observable isUpdating: boolean = false;

  constructor(camera: Camera, currentCalibration?: CameraCalibration, scanCalibration?: CameraCalibration) {
      this.camera = camera;
      this.currentCalibration = currentCalibration;
      this.scanCalibration = scanCalibration;
      this.posesAreEqual = this.scanCalibration !== undefined && this.currentCalibration !== undefined && this.scanCalibration.equals(this.currentCalibration);
      makeObservable(this);
  }

 
  @action
  setShowWithCurrentPose(show: boolean) {
    this.showWithCurrentPose = show;
  }

  @action
  setShowWithScanPose(show: boolean) {
    this.showWithScanPose = show;
  }

  @action
  makeScanCalibrationCurrent() {
    if (!this.scanCalibration) return;
    this.isUpdating = true;
    let camera = this.camera.clone();
    camera.calibration = this.scanCalibration;
    SiteBionicsApplication.getInstance().service.updateCameraAsync(camera).then(() => { 
      this.camera.updateFrom(camera);
      this.currentCalibration = this.scanCalibration;
      this.posesAreEqual = true;
      this.isUpdating = false 
    });
  }

}

const ScanInfoAccordion: FC<{ scan?: Scan}> = observer(({ scan }) => {

  return (
      <Box component="div" width="100%" display="flex">
          <Accordion disableGutters sx={{ width: "100%" }} defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row">
                      <Info fontSize="small" />
                      <Box component="div" width={10} />
                      <Typography>Scan Information</Typography>
                  </Stack>
              </AccordionSummary>
              <AccordionDetails>
                  <TableContainer component={Paper} sx={{bgcolor: '#1E1E1E'}}>
                    <Table size="small" aria-label="scan cameras table">
                      <TableBody>
                        <TableRow>
                          <TableCell>Area:</TableCell>
                          <TableCell>{scan?.area.scanAreaName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Scan Version:</TableCell>
                          <TableCell>{scan?.scanVersion}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Scan Time:</TableCell>
                          <TableCell>{scan?.scanTime.toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Scan Id:</TableCell>
                          <TableCell>{scan?.scanId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Processing Status:</TableCell>
                          <TableCell>{scan&& (<ScanIndicator scan={scan}/>)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
              </AccordionDetails>
          </Accordion>
      </Box>
  )
})

const ScanModelsAccordion: FC<{ models: ScanModelViewModel[]}> = observer(({ models }) => {

  return (
      <Box component="div" width="100%" display="flex">
          <Accordion disableGutters sx={{ width: "100%" }} defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row">
                      <Business fontSize="small" />
                      <Box component="div" width={10} />
                      <Typography>Generated Models</Typography>
                  </Stack>
              </AccordionSummary>
              <AccordionDetails>
                  <TableContainer component={Paper} sx={{bgcolor: '#1E1E1E'}}>
                    <Table size="small" aria-label="scan cameras table">
                      <TableHead>
                      <TableRow>
                        <TableCell>Model Type</TableCell>
                        <TableCell align="right">Show</TableCell>
                        <TableCell align="right">Published</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {models.map((model) => (
                        <TableRow key={"model-type-" + model.model.modelType}>
                        <TableCell>{ModelType[model.model.modelType]}</TableCell>
                        <TableCell align="right">
                          <ShowHideToggle show={model.show} enabled={true} onToggle={() => {model.setShow(!model.show) }} />
                        </TableCell>
                        <TableCell padding="checkbox" align="center">
                          <ToggleIconButton 
                              value={false} 
                              enabled={true} 
                              working={false} onClicked={() => { }} 
                              trueIcon={<Check/>} falseIcon={<CheckBoxOutlineBlank/>} disabledIcon={undefined} />
                        </TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
              </AccordionDetails>
          </Accordion>
      </Box>
  )
})

const ScanCamerasAccordion: FC<{ scanCameras: ScanCameraViewModel[]}> = observer(({ scanCameras }) => {

  return (
      <Box component="div" width="100%" display="flex">
          <Accordion disableGutters sx={{ width: "100%" }} defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row">
                      <CameraIndoorOutlinedIcon fontSize="small" />
                      <Box component="div" width={10} />
                      <Typography>Detected Cameras</Typography>
                  </Stack>
              </AccordionSummary>
              <AccordionDetails>
                    <TableContainer component={Paper} sx={{bgcolor: '#1E1E1E'}}>
                    <Table size="small" aria-label="scan cameras table">
                      <TableHead>
                      <TableRow>
                        <TableCell>Camera</TableCell>
                        <TableCell align="center" width="10px">Detected Position</TableCell>
                        <TableCell />
                        <TableCell align="center" width="10px">Configured Position</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {scanCameras.map((sc) => (
                        <TableRow key={sc.camera.cameraId}>
                        <TableCell>{sc.camera.cameraName ?? "Unnamed Camera"}</TableCell>
                        <TableCell align="center" padding="checkbox">
                          <ShowHideToggle show={sc.showWithScanPose} enabled={sc.scanCalibration?.pose !== undefined} onToggle={() => { sc.setShowWithScanPose(!sc.showWithScanPose) }} />
                        </TableCell>
                        <TableCell align="center" padding="checkbox" >
                          <ToggleIconButton
                              value={sc.posesAreEqual} 
                              enabled={sc.scanCalibration?.pose !== undefined} 
                              working={sc.isUpdating} onClicked={() => { sc.makeScanCalibrationCurrent() }} 
                              trueIcon={<InsertLink fontSize="small"/>} falseIcon={<ArrowForward fontSize="small"/>} disabledIcon={undefined} />
                        </TableCell>
                        <TableCell align="center" padding="checkbox">
                          <ShowHideToggle show={sc.showWithCurrentPose} enabled={sc.currentCalibration?.pose !== undefined} onToggle={() => { sc.setShowWithCurrentPose(!sc.showWithCurrentPose) }} />
                        </TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                    </TableContainer>
              </AccordionDetails>
          </Accordion>
      </Box>
  )
})

const ScanPage: FunctionComponent = observer(() => {
  const site = useSite();
  const {scanId} = useParams<{scanId: string}>();
  const [scan, setScan] = useState<Scan|undefined>();
  const [scanArea, setScanArea] = useState<ScanArea|undefined>();
  const [scanModels, setScanModels] = useState<ScanModelViewModel[]>([]);
  const [scanCameras, setScanCameras] = useState<ScanCameraViewModel[]>([]);
  const breadcrumbs = useSitePageBreadcrumbs("", [{name: "Scans", linkFragment: "/scans"}]);

  // Initial load (one time) - This effect ensures all the cameras, scan areas and scans are loaded for the selected scan
  // and sets the scan and scan area to the one specified in the URL
  useEffect(() => {
    
    // load areas and scans for the site
    site.loadAreasAsync().then((scanAreas) => {
      scanAreas?.forEach((scanArea) => {
        scanArea.loadScansAsync().then((scans) => {
          scans?.forEach((scan) => {
            if (scan.scanId === scanId) {
              setScan(scan);
              setScanArea(scanArea);
              let scanModels = scan.models.map((model) => new ScanModelViewModel(model)).sort((a, b) => a.model.modelType - b.model.modelType);
              setScanModels(scanModels);
            }
          });
        });
      });
    });

    //load the cameras for the site in parallel
    site.loadCamerasAsync()
    
  }, []);

  
  // fet the available model types for the scan when the scan changes
  useEffect(() => {
    if (!scan || !scan.models || scan.models.length === 0) {
      setScanModels([]);
    } else {
      let scanModels = scan.models.map((model) => new ScanModelViewModel(model))
      setScanModels(scanModels);
    }
  }, [scan]);

  useEffect(() => {
        
      const scanCameras = site.cameras?.map(camera => {
            const calibration: CameraCalibration | undefined = scan?.cameraCalibrations[camera.id];
            return new ScanCameraViewModel(camera, camera.calibration, calibration);
          }) ?? [];
        
      setScanCameras(scanCameras);
    
  }, [scan, site, site?.cameras]);

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs} pageName="Scan" />
      
      <TitleToolbar title="Scan" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>          
      </TitleToolbar>
      
      {/* <Box component="div" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
        <TableContainer component={Paper}>
          <Toolbar variant="dense" sx={{ display: "flex", alignItems: "center", gap: "5pt" }}>
              <Typography fontSize={18} color="inherit" component="div">Scan</Typography>
          </Toolbar>
        </TableContainer>
      </Box> */}
      
      <Box component="div" flexGrow="1" overflow="hidden" display="flex" flexDirection="row" sx={{padding: "10pt"}}>
              {/* left side accordian panel */}
              <Box component="div" minWidth="400px" width="400px" display="flex" flexDirection="column" overflow="auto">
                <ScanInfoAccordion scan={scan} />
                <Box component="div" height="10pt"/>
                <ScanModelsAccordion models={scanModels ?? []} />
                <Box component="div" height="10pt"/>
                <ScanCamerasAccordion scanCameras={scanCameras} />
              </Box>
              {/* central panel */}
              <Box component="div" flexGrow={1} display="flex" flexDirection="row" sx={{ background: "red" }}>
               
                <Box component="div" sx={{ display: "flex", flexgrow: 1, width: "100%", overflow: "hidden", background: "green" }} >
                  {scan && 
                    <ScanConfigScene scan={scan} scanCameras={scanCameras} modelType={ModelType.Lidar} />
                  }
                </Box>
              </Box>
              {/* right side property panel */}
              {/* <Box component="div" width="300px" minWidth="300px" display="flex" flexDirection="column" overflow="auto">
              </Box> */}
            </Box>
  </>      
  )
})

export default ScanPage;


