import { useState } from "react";
import { Box, Paper, Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, MenuItem, FormHelperText, InputLabel, Select } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { observer } from "mobx-react-lite";
import Address from "../models/Address";
import { useForm, Controller} from "react-hook-form";
import AddressFormFields from "../components/AddressFormFields";

interface AccountDialogProps {

  title: string;

  name?: string;

  address?: Address;

  saveLabel?: string;

  nameLabel?: string;

  addressLabel?: string;

  onClose: () => void;

  onSave: (name: string, address: Address) => void;
}

interface FormValues {
  name: string;

  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

const AccountDialog: React.FC<AccountDialogProps> = ({
    title,
    name = "",
    address = new Address("", "", "", "", "", ""),
    saveLabel = "Save",
    nameLabel = "Name",
    addressLabel = "Address",
    onClose,
    onSave
 }) => {
  
  const {control, watch, register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      name: name,

      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country
    },
    mode: "onBlur"
  });
      
  // When the cancel button is clicked
  const handleCancelEditClick = () => {
    onClose();
  }

  // Prevents clicks on the backdrop from cancelation
  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    
    if (reason === "backdropClick") return;

    onClose();

  }

  const handleSaveEditClick = (data:FormValues ) => {        
    const address = new Address(data.street1, data.street2, data.country, data.city, data.state, data.postalCode);
      onSave(data.name, address);    
  };      

  const aiv = errors["street1"] || errors["street2"] || errors["city"] || errors ["state"] || errors["postalCode"] || errors["country"];

  return (
    <>    
      <Dialog open={true} onClose={handleCancelLocal} maxWidth="lg" >
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSaveEditClick)}>
        <DialogContent>
          <Paper elevation={2} sx={{ padding: 2, marginTop: 2, backgroundColor: 'transparent' }}>
            <TextField 
              id="name" 
              label={nameLabel} 
              margin="dense" 
              type="string" 
              variant="standard"
              required
              fullWidth 
              {...register("name", { required: nameLabel + " is required"})}
              error={!!(touchedFields["name"] && errors["name"])}
              helperText={touchedFields["name"] && errors.name?.message}                        
            />                  
          </Paper>

          <Box component="div" sx={{height: 20}}/> 

          <Accordion sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {aiv ? 
              <InputLabel sx={{ fontSize: "0.875rem", color: "red" }}>
              Account Address (errors)
            </InputLabel>
              :
              <InputLabel sx={{ fontSize: "0.875rem", color: "text.secondary" }}>
                Account Address
              </InputLabel>
              }
            </AccordionSummary>
            <AccordionDetails>
              <AddressFormFields
                control={control}
                errors={errors}
                touchedFields={touchedFields}
                register={register}
                required={false}
              />
          
            </AccordionDetails>
          </Accordion>  
            
        </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancelEditClick} color="secondary">Cancel</Button>
        <Button variant="outlined" disabled={!isValid} onClick={handleSubmit(handleSaveEditClick)} color="primary">{saveLabel}</Button>
      </DialogActions>
      </form>
    </Dialog>      

</>
  )
};

export default observer(AccountDialog);