import { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Typography, Button, CircularProgress, Backdrop, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import TitleSimple from "../components/TitleSimple";
import { SiteEventUpdate } from "../models/SiteEvent";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";

function readCSVFile(file: File): Promise<SiteEventUpdate[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      const siteEvents: SiteEventUpdate[] = [];
      let currentSiteEvent: SiteEventUpdate | null = null;

      const rows = text.split('\n').map(row => row.trim()).filter(row => row.length > 0);
      const headers = rows[0].split(',');

      rows.slice(1).forEach((row) => {
        const values = row.split(',');
        const rowObject: any = {};
        headers.forEach((header, index) => {
          rowObject[header] = values[index];
        });

        const { 'eventDate': eventDate, 'eventType': eventType, 'eventId': eventId, 'eventItem.itemId': itemId, ...rest } = rowObject;

        const additionalProperties: Record<string, any> = {};
        let eventItem: any = itemId ? { itemId, additionalProperties: {} } : undefined;

        // Loop through each of the remaining header values
        Object.keys(rest).forEach(key => {

          // Get the name of the column and its type
          const [name, type] = key.split(':');

          // Get the value of this cell
          const value = rest[key];

          // Convert to the right type
          const parsedValue = type === 'number' ? parseFloat(value) : value;

          if (name.startsWith('eventItem.')) {
            if (eventItem) {
              const itemKey = name.replace('eventItem.', '');
              eventItem.additionalProperties[itemKey] = parsedValue;
            }
          } else {
            additionalProperties[name] = parsedValue;
          }
        });

        if (
          currentSiteEvent &&
          currentSiteEvent.eventDate === eventDate &&
          currentSiteEvent.eventType === eventType &&
          currentSiteEvent.eventId === eventId
        ) {
          // Append to the current SiteEvent
          if (eventItem) {
            currentSiteEvent.eventItems?.push(eventItem);
          }
        } else {
          // Start a new SiteEvent
          if (currentSiteEvent) {
            siteEvents.push(currentSiteEvent);
          }

          currentSiteEvent = new SiteEventUpdate(
            eventDate,
            eventType,
            eventId,
            eventItem ? [eventItem] : [],
            additionalProperties
          );
        }
      });

      if (currentSiteEvent) {
        siteEvents.push(currentSiteEvent);
      }
      resolve(siteEvents);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}


const SiteEvents: FunctionComponent = observer(() => {
  const [file, setFile] = useState<File | null>(null);
  const [events, setEvents] = useState<SiteEventUpdate[]>([]);
  const [loaded, setLoaded] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState<string>("Local site time");

  const site = useSite();
  const siteBionicsApplication = useSiteBionicsApplication();
  const account = site.account;
  const breadcrumbs = useSitePageBreadcrumbs("Events");

  useEffect(() => {
    account.loadRoleMapAsync();
  }, [account]);

  // Function to handle file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  // Function to parse the CSV file
  const handleSubmit = async () => {
    setLoaded(null);
    setEvents([]);
    setLoading(true);

    if (file) {
      try {
        const siteEventUpdates = await readCSVFile(file);
        setEvents(siteEventUpdates);
        const timezoneParam = timezone === "UTC" ? "UTC" : undefined;
        siteBionicsApplication.service.updateSiteEventsAsync(site, siteEventUpdates, timezoneParam).then((count: number) => {
            setLoaded(count);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error reading CSV file:", error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading &&
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open><CircularProgress color="inherit" /></Backdrop>
      }
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleSimple
        title="Events"
        sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}
      />

      <Box component="div" sx={{ paddingX: "10pt", overflow: "auto"}}>

        <Box component="div" sx={{ maxWidth: "600px" }}>
          <Typography>
            Upload a CSV file containing site events. These are events that occur external to the SiteBionics system and are imported.<br />
            <br />
            The columns in the CSV are defined in the header. There are two practical use cases of site events<br />
            <br />            
            The first is to import transaction or order data. Since a transaction can have multiple items, an individual transaction is represented by multiple rows in the CSV file.<br />
            <br />
            The columns are:<br />
            <br />
            <table style={{ border: "1px solid white", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid white", padding: "8px" }}>Column Header</th>
                  <th style={{ border: "1px solid white", padding: "8px" }}>Data Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventDate</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Formatted as YYYY-MM-DDTHH:MM:SS, this is the date of the transaction</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventType</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Transaction</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>A unique id identifying a specific transaction or order</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>A unique id identifying a line item in a transaction. Must be unique in scope of eventDate/eventType/eventId</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemAmount:number</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Amount the item cost</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemDiscount:number</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Amount the item was discounted</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemQuantity:number</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Number of items ordered</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemDepartment</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Department of the item</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.cashierEmployeId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Employee id of the cashier</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.salesEmployeId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Employee making the sale</td>
                </tr>
              </tbody>
            </table>
            <br/>
            The second is to import scan events. Again, multiple items being scanned at the same time are supported.<br />
            <br />
            The columns are:<br />
            <br />
            <table style={{ border: "1px solid white", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid white", padding: "8px" }}>Column Header</th>
                  <th style={{ border: "1px solid white", padding: "8px" }}>Data Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventDate</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Formatted as YYYY-MM-DDTHH:MM:SS, this is the date of the transaction</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventType</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>Scan</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>A unique id identifying a specific transaction or order</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>A unique id identifying a line item in a scan. Must be unique in scope of eventDate/eventType/eventId</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemUPC</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>A UPC code for the scanned item</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid white", padding: "8px" }}>eventItem.itemSerialId</td>
                  <td style={{ border: "1px solid white", padding: "8px" }}>A serial number for the scanned item</td>
                </tr>
              </tbody>
            </table>
            <br />
          </Typography>
          <br />
          <input type="file" accept=".csv" onChange={handleFileUpload} />

          {/* Timezone dropdown */}
          <FormControl fullWidth sx={{ marginTop: "20pt"}}>
            <InputLabel id="timezone-label">Timezone of Dates</InputLabel>
            <Select
              labelId="timezone-label"
              value={timezone}
              label="Timezone of Dates"
              onChange={(e) => setTimezone(e.target.value)}
            >
              <MenuItem value="Local site time">Local site time</MenuItem>
              <MenuItem value="UTC">UTC</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "10pt" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>

        {/* Display the parsed events */}
        {events.length > 0 && (
          <Box component="div" sx={{ marginTop: "20pt" }}>
            <Typography variant="h6">Parsed events from the file: {events.length}</Typography>
            {events.map((event, index) => (
              <Box component="div" key={index} sx={{ marginBottom: "10pt" }}>
                <Typography>
                  {event.eventType}: {event.eventDate}, {event.eventId} <br />
                  {event.additionalProperties && Object.entries(event.additionalProperties).map(([key, value]) => (
                    <span key={key}>{key}: {value}, </span>
                  ))}
                  <br />
                  {event.eventItems && event.eventItems.map((eventItem, index) => (
                    <span key={index}>
                      {eventItem.itemId}
                      {eventItem.additionalProperties && Object.entries(eventItem.additionalProperties).map(([key, value]) => (
                        <span key={key}> {key}: {value}, </span>
                      ))}
                      <br />
                    </span>
                  ))}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {loaded != null &&
          <Box component="div" sx={{ marginTop: "20pt" }}>
            <Typography variant="h6">Processed on backend: {loaded}</Typography>
          </Box>
        }
      </Box>
    </>
  );
});

export default SiteEvents;
