import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { IconButton, Tooltip, Menu, MenuItem, Box, Button, Checkbox, Divider, Backdrop, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { SiteBionicsApplication, useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import PairHubDialog from "../dialogs/PairHubDialog";
import { HubRecordingRequest } from "../models/SiteBionicsService";
import ElapsedTime from "../components/EllapsedTime";
import Hub from "../models/Hub";
import { useSite } from "./SitePage";
import TitleToolbar from "../components/TitleToolbar";
import Confirm from "../components/Confirm";
import NameDialog from "../dialogs/NameDialog";
import useSiteHubStatus from "../hooks/useSiteHubStatus";
import { HubStatusResponse, HubStatusCamera } from "../models/HubStatusResponse";
import StatusLED from '../components/StatusLED';
import ReactGA from "react-ga4";
import ActionMenu from "../components/ActionMenu";

interface HubTableRowProps {
  hub: Hub,
  hubStatus: HubStatusResponse | null | undefined;
}

const HubTableRow: React.FC<HubTableRowProps> = ({ hub, hubStatus }) => {

  const site = useSite();
  const siteNavigator = site.siteNavigator;

  const [renameHubDialogOpen, setRenameHubDialogOpen] = useState(false);
  const [unpairHubDialogOpen, setUnpairHubDialogOpen] = useState(false);

  const [busy, setBusy] = useState(false);
  const siteBionicsApplication = useSiteBionicsApplication();

  
  const healthColor = hubStatus === undefined ? "grey" : hubStatus === null ? "red" : hubStatus.getHubStatusHealth();

  const event = (operation: string, action?: string, additionalParams?: Record<string, any>) => {
    const suffix = action ? "_" + action : "";
    ReactGA.event("hub_" + operation + suffix, {
      accountId: site.account.accountId,
      siteId: site.siteId,
      hubId: hub.hubId,
      ...additionalParams
    });
  };

  const handleUnpairClick = () => {
    setUnpairHubDialogOpen(true);
  };

  const handleUnpair = () => {
    setBusy(true);
    hub.deleteAsync().then(() => {
      setUnpairHubDialogOpen(false);
      setBusy(false);
    });
  }

  const handleRenameClick = () => {
    event("rename", "click");

    setRenameHubDialogOpen(true);
  }

  const handleRenameSave = (name: string) => {
    event("rename", "confirm");

    setBusy(true);
    siteBionicsApplication.service.patchHubAsync(hub, name).then((status: number) => {
      if (status === 200) {
        hub.setName(name);
      } else {
        siteBionicsApplication.setLastError("An error occured", "error");
      }
      setRenameHubDialogOpen(false);
      setBusy(false);
    });
  }

  const handleRenameCancel = () => {
    event("rename", "cancel");

    setRenameHubDialogOpen(false);
  };

  const handleRestartClick = () => {
    event("restart");
    setBusy(true);
    siteBionicsApplication.service.restartHubAsync(hub).then(() => {
      setBusy(false);
    })
  };

  const handleStartRecordingClick = () => {
    event("recording", "start", { tracking: true });

    setBusy(true);
    siteBionicsApplication.service.putHubRecordingRequestAsync(hub, new HubRecordingRequest(true, true)).then(() => {
      setBusy(false);
    });
  };

  const handleStartRecordingNoTrackingClick = () => {
    event("recording", "start", { tracking: false });

    setBusy(true);
    siteBionicsApplication.service.putHubRecordingRequestAsync(hub, new HubRecordingRequest(true, false)).then(() => {
      setBusy(false);
    })
  };

  const handleStopRecordingClick = () => {
    event("recording", "stop");

    setBusy(true);
    siteBionicsApplication.service.putHubRecordingRequestAsync(hub, new HubRecordingRequest(false, false)).then(() => {
      setBusy(false);
    });
  };

  
  return (
    <TableRow key={hub.hubId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>      
      <TableCell scope="row" sx={{ width: '15%' }}><Tooltip title={hub.hubId}><span>{hub.name} </span></Tooltip></TableCell>    
      <TableCell scope="row" sx={{ width: '5%' }}> 
        <ActionMenu>
          <MenuItem onClick={() => {handleRenameClick();}}>Rename</MenuItem>
          <MenuItem onClick={() => { handleUnpairClick();}}>Unpair...</MenuItem>     
          <MenuItem onClick={() => { handleRestartClick(); }}>Restart</MenuItem>
          <Divider/>
          <MenuItem onClick={() => { handleStartRecordingClick();}}>Start Recording</MenuItem>
          <MenuItem onClick={() => { handleStartRecordingNoTrackingClick();}}>Start Recording (no tracking)</MenuItem>
          <MenuItem onClick={() => { handleStopRecordingClick();}}>Stop Recording</MenuItem>
        </ActionMenu>
      </TableCell>
      <TableCell scope="row" sx={{ width: '5%' }}> <StatusLED color={healthColor} /> </TableCell>
      <TableCell scope="row"> {hubStatus ? hubStatus.getRecordingState() : "N/A"} </TableCell>
      <TableCell scope="row"> {hubStatus ? hubStatus.status.cameras.length : ""} </TableCell>
      <TableCell scope="row"> {hubStatus?.getHubStatusAverageVideoFPS()?.toFixed(1) ?? "N/A"} </TableCell>
      <TableCell scope="row"> {hubStatus?.getHubStatusAverageInferenceFPS()?.toFixed(1) ?? "N/A"} </TableCell>
      <TableCell scope="row"> {hubStatus ? <ElapsedTime time={new Date(hubStatus.time)}/> : ""} </TableCell>
      <TableCell scope="row"> 
          <>
          {(hubStatus && hubStatus.status.runningSince) ? <ElapsedTime time={new Date(hubStatus.status.runningSince)}/> : "Offline"}
          </>
        </TableCell>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop> 

      {renameHubDialogOpen &&
        <NameDialog title="Rename Hub" name={hub.name} onSave={handleRenameSave} saveLabel="Rename" nameLabel="Hub Name" onClose={handleRenameCancel} />
      }

      {unpairHubDialogOpen &&
        <Confirm
          confirmationText={"Are you sure you want to unpair " + hub.name + "?"}
          confirmButtonLabel="Unpair"
          onClose={() => setUnpairHubDialogOpen(false)}
          onConfirm={handleUnpair} />
      }      
    </TableRow>    
  );
};

interface HubsProps {
  hubStatuses: Record<string, HubStatusResponse | null> | null;
  
}
const Hubs : React.FC<HubsProps> = observer(( {hubStatuses} ) => {
    const site = useSite();
    const siteNavigator = site.siteNavigator;
    const siteBionicsApplication = useSiteBionicsApplication();
    

    const [busy, setBusy] = useState(false);
    
    const [pairHubDialogOpen, setPairHubDialogOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

      if (!site.hubs) {
        setLoading(true);
        site.loadHubsAsync().then(() => {
          setLoading(false);
        });
      }
      
      if (!siteNavigator.currentHub && site.hubs && site.hubs.length > 0) {
        siteNavigator.setCurrentHub(site.hubs[0]);
      }
    }, [site.hubs]);
  
    
          
    const handlePair = (hubId: string, pairingCode: string) => {
      setBusy(true);
      siteBionicsApplication.service.pairHubAsync(site, hubId, pairingCode).then(() => {
        setBusy(false);
        setPairHubDialogOpen(false);
      });
    };

    const handlePairHubClose = () => {
      setPairHubDialogOpen(false);
    };    
    
    return (
      <div style={{minHeight: "200px"}}>

        <TitleToolbar title="Hubs" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
          <ActionMenu>
            <MenuItem onClick={() => { setPairHubDialogOpen(true); }}>Pair Hub...</MenuItem>
          </ActionMenu>
        </TitleToolbar>
        
        <Box component="div" sx={{height: "10pt"}}/>

        {loading && <Typography>Loading...</Typography>}

        {!loading && site.hubs && site.hubs.length === 0 &&
          <Typography>No hubs available. Please Pair a hub.</Typography>
        }

        {!loading && site.hubs && site.hubs.length > 0 &&
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ width: '15%' }}>Hub Name</TableCell>   
                  <TableCell align="left" sx={{ width: '5%' }}></TableCell>
                  <TableCell align="left" sx={{ width: '5%' }}>Health</TableCell>
                  <TableCell align="left">Recording</TableCell>
                  <TableCell align="left"># Cameras</TableCell>
                  <TableCell align="left">Video FPS</TableCell>
                  <TableCell align="left">Inference FPS</TableCell>
                  <TableCell align="left">Last Update</TableCell>
                  <TableCell align="left">Running Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {siteNavigator.site.hubs?.map((hub) => (
                  <HubTableRow key={hub.hubId} hub={hub} hubStatus={hubStatuses === null ? undefined : hubStatuses[hub.hubId]}/>              
                ))}              
              </TableBody>
            </Table>
          </TableContainer>        
        }

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>              

        {pairHubDialogOpen &&
          <PairHubDialog onClose={handlePairHubClose} siteName={siteNavigator.site.siteName} onPair={handlePair}/>
        }
      </div>
    )
})

export default Hubs;